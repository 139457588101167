import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
} from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import FileDisplay from "components/custom/FileDisplay"
import ListPageHeader from "../../components/custom/ListPageHeader"
import {  StatusCodes } from "../../constants/defaultValues"
import { AlliedDepartmentValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import Table from "react-bootstrap/Table"
import {
  createAlliedDepartmentMutation,
  updateAlliedDepartmentMutation,
  deleteAlliedDepartmentMutation,
  getAllAlliedDepartmentsQuery,
  fileuploadQuery,
  UpdateManyMutation,
} from "./Queries"
import axios from "axios"
import MetaTags from "react-meta-tags"

class AlliedDepartment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      alliedDepartmentList: [],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 10,
      },
      alliedDepartment: {
        isDeleted: "",
        departmentImage: "",
        name:"",
        link: "",
        isActive: "",
      },
    }
  }

  componentDidMount() {
    this.getAllAlliedDepartments()
  }
  getAllAlliedDepartments = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllAlliedDepartmentsQuery, values)
    console.log(response)
    var alliedDepartmentsValue = response?.data?.getAllAlliedDepartments?.items
    if (alliedDepartmentsValue) {
      this.setState({
        isLoading: false,
        alliedDepartmentList: alliedDepartmentsValue,
        totalPage:
          response?.data?.getAllAlliedDepartments?.pagination?.totalCount /
          response?.data?.getAllAlliedDepartments?.pagination?.pageLimit,
        totalCount:
          response?.data?.getAllAlliedDepartments?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllAlliedDepartments()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllAlliedDepartments()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllAlliedDepartments()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllAlliedDepartments()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        alliedDepartment: row,
        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })

    if (typeof values.departmentImage === "object") {
      let fileupload = {
        type: "photo",
        fileName: values.departmentImage?.name,
      }
      const fileUploadresponse = await QueryRequest(fileuploadQuery, fileupload)
      let data = fileUploadresponse?.data?.getUploadUrl
      console.log(data)
      if (data.status === "Success") {
        axios
          .put(data.url, values.departmentImage, {
            headers: { "Content-Type": values.departmentImage?.type },
          })
          .then(async res => {
            if (res.status.toString() === StatusCodes.Success) {
              var AddData = {
                departmentImage: data.url.split("?")[0],
                link: values.link,
                isActive: values.isActive,
                name:values.name,
                sortId: 1,
              }
              var EditVal = {
                id: values.id,
                data: {
                  departmentImage: data.url.split("?")[0],
                  link: values.link,
                  isActive: values.isActive,
                  name:values.name,
                  sortId: 1,
                },
              }
              const response = values.id
                ? await MutateRequest(updateAlliedDepartmentMutation, EditVal)
                    .then(
                      r =>
                        this.setState({
                          manageEditModal: !this.state.manageEditModal,
                          buttonAction: false,
                        }),
                      SuccessMessage(Message("AlliedDepartment.update"))
                    )
                    .catch(e => ErrorMessage(e))
                : await MutateRequest(createAlliedDepartmentMutation, {
                    data: AddData,
                  })
                    .then(r => {
                      this.setState({
                        manageEditModal: !this.state.manageEditModal,
                        buttonAction: false,
                      })
                      SuccessMessage(Message("AlliedDepartment.add"))
                    })
                    .catch(e => ErrorMessage(e))
              var alliedDepartmentValue = response?.data
              if (alliedDepartmentValue && values.id) {
                SuccessMessage(Message("AlliedDepartment.update")),
                  this.setState({
                    manageEditModal: !this.state.manageEditModal,
                  })
                this.getAllAlliedDepartments()
              } else {
                // SuccessMessage(Message("alliedDepartment.add")),
                  this.setState({
                    manageModal: !this.state.manageModal,
                  })
                this.getAllAlliedDepartments()
              }
            } else {
              ErrorMessage(Message("fieldUpload.error"))
            }
          })
          .catch(err => alert("Error"))
      } else {
      }
    } else {
      var AddData = {
        departmentImage: values.departmentImage,
        link: values.link,
        isActive: values.isActive,
        name:values.name,
        sortId: 1,
      }
      var EditVal = {
        id: values.id,
        data: {
          departmentImage: values.departmentImage,
          link: values.link,
          isActive: values.isActive,
          name:values.name,
          sortId: 1,
        },
      }
      const response = values.id
        ? await MutateRequest(updateAlliedDepartmentMutation, EditVal)
            .then(
              r =>
                this.setState({
                  // manageEditModal: !this.state.manageEditModal,
                  buttonAction: false,
                }),
              SuccessMessage(Message("AlliedDepartment.update"))
            )
            .catch(e => ErrorMessage(e))
        : await MutateRequest(createAlliedDepartmentMutation, { data: AddData })
            .then(r => {
              this.setState({
                // manageEditModal: !this.state.manageEditModal,
              })
              SuccessMessage(Message("AlliedDepartment.add"))
            })
            .catch(e => ErrorMessage(e))
      var alliedDepartmentValue = response?.data
      if (alliedDepartmentValue && values.id) {
        // SuccessMessage(Message("alliedDepartment.update")),
        this.setState({
          manageModal: !this.state.manageModal,
        })
        this.getAllAlliedDepartments()
      } else {
        // SuccessMessage(Message("alliedDepartment.add")),
        this.setState({
          manageModal: !this.state.manageModal,
        })
        this.getAllAlliedDepartments()
      }
    }
  }

  addBtnClick = () => {
    this.setState({
      alliedDepartment: {
        departmentImage: "",
        link: "",
        isActive: false,
        name:"",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      alliedDepartment: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      alliedDepartment: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      alliedDepartment: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteAlliedDepartment = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deleteAlliedDepartmentMutation, values)
    var alliedDepartmentdeleteValue = response?.data
    if (
      alliedDepartmentdeleteValue.deleteOneAlliedDepartment.status === "Success"
    ) {
      SuccessMessage(Message("AlliedDepartment.delete"))
      this.getAllAlliedDepartments()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageAlliedDepartmentResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.alliedDepartment.id
        ? SuccessMessage(Message("AlliedDepartment.update"))
        : SuccessMessage(Message("AlliedDepartment.add"))

      this.getAllAlliedDepartments()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      alliedDepartmentDetail: url ? url : "",
    })
  }

  handleOnDragEnd = async result => {
    if (!result.destination) return

    this.setState({ isLoading: true })
    const items = Array.from(this.state.alliedDepartmentList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    this.setState({ data: items })
    const res = []
    items.map((u, i) => {
      res.push({
        id: u.id,
        sortId: i + 1,
        link: u.link,
        departmentImage: u.departmentImage,
        name:u.name,
        isActive: u.isActive,
      })
    })
    console.log({ data: res })
    const response = await MutateRequest(UpdateManyMutation, {
      data: res,
    })
    console.log(response)
    if (response?.data?.updateManyAlliedDepartments.status === "Success") {
      SuccessMessage("Updated Successfully")
      this.getAllAlliedDepartments()
    } else {
      ErrorMessage("Error")
    }
  }
  render() {
    const initialValues = this.state.alliedDepartment
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Allied Department</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Allied Departments"}`}
                    buttonText={Message("add.AlliedDepartment")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                {this.state.isLoading ? (
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="spinner-border text-primary m-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </Row>
                ) : (
                  <div className="PoliceView">
                    <Table stripped hover size="sm">
                      <thead>
                        <tr
                        >
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Name
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Link
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Image
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Is Active
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              paddingBottom: "15px",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <DragDropContext
                        onDragEnd={result => this.handleOnDragEnd(result)}
                      >
                        <Droppable droppableId="characters">
                          {provided => (
                            <tbody
                              className="characters"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {this.state.alliedDepartmentList.map((row, i) => {
                                return (
                                  <Draggable
                                    key={row.id}
                                    draggableId={row.id.toString()}
                                    index={i}
                                  >
                                    {provided => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.name}
                                        </td>{" "}
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.link}
                                        </td>{" "}
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.departmentImage ? (
                                            <FileDisplay
                                              Value={row.departmentImage}
                                            />
                                          ) : (
                                            "-"
                                          )}
                                        </td>{" "}
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.isActive
                                            ? "Active"
                                            : "Not Active"}
                                        </td>
                                        <td
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Row>
                                            <ButtonGroup
                                              className="mb-2"
                                              style={{ top: "4px" }}
                                            >
                                              <Button
                                                outline
                                                color="primary"
                                                className="mobileViewFont ml-2"
                                                onClick={() =>
                                                  this.handleEdit(row)
                                                }
                                              >
                                                Edit
                                              </Button>

                                              <Button
                                                outline
                                                color="danger"
                                                className="mobileViewFonts pl-1 pr-1 ml-2"
                                                onClick={() =>
                                                  this.toggleDeleteModal(row)
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </ButtonGroup>
                                          </Row>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    
                    </Table>
                  </div>
                )}
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.alliedDepartment")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        AlliedDepartment " {""}
                        {this.state.alliedDepartment
                          ? this.state.alliedDepartment?.name
                          : ""}{" "}
                        " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() =>
                      this.deleteAlliedDepartment(
                        this.state.alliedDepartment.id
                      )
                    }
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="md"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.AlliedDepartment")
                  : Message("add.AlliedDepartment")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={AlliedDepartmentValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">{Message("name")}</Label>
                              <Field
                                className="form-control"
                                name="name"
                                type="text"
                              />
                              {errors.name && (
                                <div className="invalid-feedback d-block">
                                  {errors.name}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">{Message("link")}</Label>
                              <Field
                                className="form-control"
                                name="link"
                                type="text"
                              />
                              {errors.link && (
                                <div className="invalid-feedback d-block">
                                  {errors.link}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">
                                {Message("DepartmentImage")}
                              </Label>
                              <FormGroup>
                                <Input
                                  style={{ display: "none" }}
                                  id="Image"
                                  type="file"
                                  name="departmentImage"
                                  onChange={e => {
                                    this.setState({
                                      photo: e.target.files[0],
                                    })
                                    setFieldValue(
                                      "departmentImage",
                                      e.target.files[0]
                                    )
                                  }}
                                />
                                <label for="Image" class="btn btn-secondary">
                                  <i class="far fa-file"></i>
                                </label>
                                <br />
                                {values.departmentImage ? (
                                  <Label>{values.departmentImage?.name}</Label>
                                ) : (
                                  ""
                                )}
                                {errors.departmentImage && (
                                  <div className="invalid-feedback d-block">
                                    {errors.departmentImage}
                                  </div>
                                )}
                                {values.departmentImage &&
                                this.state.Label == "Edit" ? (
                                  <FileDisplay Value={values.departmentImage} />
                                ) : (
                                  ""
                                )}
                              </FormGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("active")}</Label>
                              <FormGroup className="custom-control custom-switch mt-1 ml-2">
                                <Input
                                  autoComplete="off"
                                  type="checkbox"
                                  className="custom-control-input ml-2 "
                                  id="IsActive"
                                  defaultChecked={values.isActive}
                                  onChange={e => {
                                    setFieldValue(`isActive`, e.target.checked)
                                  }}
                                  onClick={e => {
                                    setFieldValue("isActive", !values.isActive)
                                  }}
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="IsActive"
                                ></Label>
                              </FormGroup>
                              {errors.isActive && (
                                <div className="invalid-feedback d-block">
                                  {errors.isActive}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction ? "show-spinner " : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(AlliedDepartment)
