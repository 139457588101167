import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
} from "reactstrap"
import ListPageHeader from "../../components/custom/ListPageHeader"
import JoditEditor from "jodit-react"
import { StatusCodes } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { CMSContentValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import {
  createCMSContentMutation,
  updateCMSContentMutation,
  deleteCMSContentMutation,
  getAllCMSContentsQuery,
  fileuploadQuery,
} from "./Queries"
import MetaTags from "react-meta-tags"
import axios from "axios"
import FileDisplay from "components/custom/FileDisplay"
class CMSContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      isPreview: false,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      cmsContentList: [{ id: "1" }, { id: "2" }],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 10,
      },
      cmsContent: {
        content: "",
        contentImage: "",
        contentDescription: "",
        contentTitle: "",
        name: "",
        isActive: false,
      },
      columns: [

        {
          name: "Name",
          selector: "name",
          sortable: true,
          cell: row => <span>{row.name ? row.name : ""}</span>,
        },

        // {
        //   name: "Content Title",
        //   selector: "contentTitle",
        //   sortable: true,
        //   cell: row => <span>{row.contentTitle ? row.contentTitle : ""}</span>,
        // },
        {
          name: "Description",
          selector: "contentDescription",
          sortable: true,
          cell: row => (
            <span>{row.contentDescription ? row.contentDescription : ""}</span>
          ),
        },

        // {
        //   name: "Content",
        //   selector: "content",
        //   sortable: true,
        //   cell: row => <span>{row.content ? row.content : ""}</span>,
        // },
        {
          name: "Image",
          selector: "contentImage",
          sortable: true,
          cell: row => <span>{row.contentImage ? <FileDisplay Value={row.contentImage} /> : ""}</span>,
        },
        // {
        //   name: "Is Active",
        //   selector: "isActive",
        //   sortable: true,
        //   cell: row => <span>{row.isActive ? row.isActive : ""}</span>,
        // },
        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: row => (
            <Row>
              <ButtonGroup className="mb-2" style={{ top: "4px" }}>
                <Button
                  outline
                  color="primary"
                  className="mobileViewFont ml-2"
                  onClick={() => this.handleEdit(row)}
                >
                  Edit
                </Button>

                <Button
                  outline
                  color="danger"
                  className="mobileViewFonts pl-1 pr-1 ml-2"
                  onClick={() => this.toggleDeleteModal(row)}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Row>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    this.getAllCMSContents()
  }
  getAllCMSContents = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllCMSContentsQuery, values)
    var cmsContentsValue = response?.data?.getAllCMSContents?.items
    if (cmsContentsValue) {
      this.setState({
        isLoading: false,
        cmsContentList: cmsContentsValue,
        totalPage:
          response?.data?.getAllCMSContents?.pagination?.totalCount /
          response?.data?.getAllCMSContents?.pagination?.pageLimit,
        totalCount: response?.data?.getAllCMSContents?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllCMSContents()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllCMSContents()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllCMSContents()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllCMSContents()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        cmsContent: row,
        manageModal: !this.state.manageModal,
        IsEdited: true,
        text: row.content,
        isPreview: false,
        Label: "Edit",
      })
    }
  }

  //
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })

    if (typeof values.contentImage === "object") {
      let fileupload = {
        type: "photo",
        fileName: values.contentImage?.name,
      }
      const fileUploadresponse = await QueryRequest(fileuploadQuery, fileupload)
      let data = fileUploadresponse?.data?.getUploadUrl
      // console.log(values.image)
      if (data.status === "Success") {
        axios
          .put(data.url, values.contentImage, {
            headers: { "Content-Type": values.contentImage?.type },
          })
          .then(async res => {
            if (res.status.toString() === StatusCodes.Success) {
              var AddData = {
                contentImage: data.url.split("?")[0],
                contentDescription: values.contentDescription,
                contentTitle: values.contentTitle,
                name: values.name,
                isActive: values.isActive,
                content: values.content,
              }
              var EditVal = {
                id: values.id,
                data: {
                  contentImage: data.url.split("?")[0],
                  contentDescription: values.contentDescription,
                  contentTitle: values.contentTitle,
                  name: values.name,
                  isActive: values.isActive,
                  content: values.content,
                },
              }
              const response = values.id
                ? await MutateRequest(updateCMSContentMutation, EditVal)
                  .then(
                    r =>
                      this.setState({
                        // manageEditModal: !this.state.manageEditModal,
                        buttonAction: false,
                      }),
                    SuccessMessage(Message("CMSContent.update"))
                  )
                  .catch(e => ErrorMessage(e))
                : await MutateRequest(createCMSContentMutation, {
                  data: AddData,
                })
                  .then(r => {
                    this.setState({
                      // manageEditModal: !this.state.manageEditModal,
                      buttonAction: false,
                    })
                    SuccessMessage(Message("CMSContent.add"))
                  })
                  .catch(e => ErrorMessage(e))
              var cmsContentValue = response?.data
              if (cmsContentValue && values.id) {
                // SuccessMessage(Message("galleryPhoto.update")),
                this.setState({
                  manageModal: !this.state.manageModal,
                })
                this.getAllCMSContents()
              } else {
                // SuccessMessage(Message("galleryPhoto.add")),
                this.setState({
                  manageModal: !this.state.manageModal,
                })
                this.getAllCMSContents()
              }
            } else {
              ErrorMessage(Message("fieldUpload.error"))
            }
          })
          .catch(err => alert("Error"))
      } else {
      }
    } else {
      var AddData = {
        contentImage: values.contentImage,
        contentDescription: values.contentDescription,
        contentTitle: values.contentTitle,
        name: values.name,
        isActive: values.isActive,
        content: values.content,
      }
      var EditVal = {
        id: values.id,
        data: {
          contentImage: values.contentImage,
          contentDescription: values.contentDescription,
          contentTitle: values.contentTitle,
          name: values.name,
          isActive: values.isActive,
          content: values.content,
        },
      }
      const response = values.id
        ? await MutateRequest(updateCMSContentMutation, EditVal)
          .then(
            r =>
              this.setState({
                manageEditModal: !this.state.manageEditModal,
                buttonAction: false,
              }),
            SuccessMessage(Message("CMSContent.update"))
          )
          .catch(e => ErrorMessage(e))
        : await MutateRequest(createCMSContentMutation, { data: AddData })
          .then(r => {
            this.setState({
              manageEditModal: !this.state.manageEditModal,
            })
            SuccessMessage(Message("CMSContent.add"))
          })
          .catch(e => ErrorMessage(e))
      var cmsContentValue = response?.data
      if (cmsContentValue && values.id) {
        SuccessMessage(Message("cmsContent.update")),
          this.setState({
            manageEditModal: !this.state.manageEditModal,
          })
        this.getAllCMSContents()
      } else {
        SuccessMessage(Message("cmsContent.add")),
          this.setState({
            manageModal: !this.state.manageModal,
          })
        this.getAllCMSContents()
      }
    }
  }
  //


  addBtnClick = () => {
    this.setState({
      cmsContent: {
        contentImage: "",
        contentDescription: "",
        contentTitle: "",
        name: "",
        isActive: false,
        isPreview: false,
        text: "",
        content: "",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      cmsContent: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      cmsContent: {},
      manageModal: !this.state.manageModal,
      text: "",
      isPreview: false,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      cmsContent: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteCMSContent = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deleteCMSContentMutation, values)
    var cmsContentdeleteValue = response?.data
    if (cmsContentdeleteValue.deleteOneCMSContent.status === "Success") {
      SuccessMessage(Message("cmsContent.delete"))
      this.getAllCMSContents()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageCMSContentResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.cmsContent.id
        ? SuccessMessage(Message("CMSContent.update"))
        : SuccessMessage(Message("CMSContent.add"))

      this.getAllCMSContents()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      cmsContentDetail: url ? url : "",
    })
  }
  showPreview = () => {
    var templateResult = this.state.text
    templateResult = templateResult.replace(/&lt;/g, "<")
    templateResult = templateResult.replace(/&gt;/g, ">")
    this.setState({
      defaultTemplateResult: templateResult,
    })
    this.setState({
      isPreview: !this.state.isPreview,
    })
  }

  render() {
    const initialValues = this.state.cmsContent

    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>CMS Content</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"CMS Contents"}`}
                    buttonText={Message("add.CMSContent")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                <Row></Row>
                <div className="PoliceView">
                  <ListPage
                    conditionalRowStyles={conditionalRowStyles}
                    columns={this.state.columns}
                    data={this.state.cmsContentList}
                    keyField={this.state.keyField}
                    totalCount={this.state.totalCount}
                    rowClicked={this.HandleRowClicked}
                    onSort={this.handleSort}
                    rowsPerPageOnChange={this.handlePerRowsChange}
                    pageChange={this.handlePageChange}
                    isDataLoading={this.state.isLoading}
                    overFlowXRemoval={true}
                  />
                </div>
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.cmsContent")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        CMSContent " {""}
                        {this.state.cmsContent
                          ? this.state.cmsContent.name
                          : ""}{" "}
                        " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() =>
                      this.deleteCMSContent(this.state.cmsContent.id)
                    }
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="lg"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.CMSContent")
                  : Message("add.CMSContent")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={CMSContentValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          {/* <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">{Message("title")}</Label>
                              <Field
                                className="form-control"
                                name="contentTitle"
                                type="text"
                              />
                              {errors.contentTitle && (
                                <div className="invalid-feedback d-block">
                                  {errors.contentTitle}
                                </div>
                              )}
                            </FormGroup>
                          </Col> */}
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">{Message("name")}</Label>
                              <Field
                                className="form-control"
                                name="name"
                                type="text"
                              />
                              {errors.name && (
                                <div className="invalid-feedback d-block">
                                  {errors.name}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">
                                {Message("description")}
                              </Label>
                              <Field
                                className="form-control"
                                name="contentDescription"
                                type="text"
                              />
                              {errors.description && (
                                <div className="invalid-feedback d-block">
                                  {errors.contentDescription}
                                </div>
                              )}
                            </FormGroup>
                          </Col>


                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("image")}</Label>
                              <FormGroup>
                                <Input
                                  style={{ display: "none" }}
                                  id="ContentImage"
                                  type="file"
                                  name="contentImage"
                                  onChange={e => {
                                    this.setState({
                                      photo: e.target.files[0],
                                    })
                                    setFieldValue(
                                      "contentImage",
                                      e.target.files[0]
                                    )
                                  }}
                                />
                                <label for="ContentImage" class="btn btn-secondary">
                                  <i class="far fa-file"></i>
                                </label>
                                <br />
                                {values.contentImage ? (
                                  <Label>{values.contentImage?.name}</Label>
                                ) : (
                                  ""
                                )}
                                {errors.contentImage && (
                                  <div className="invalid-feedback d-block">
                                    {errors.contentImage}
                                  </div>
                                )}
                                {values.contentImage &&
                                  this.state.Label == "Edit" ? (
                                  <FileDisplay Value={values.contentImage} />
                                ) : (
                                  ""
                                )}
                              </FormGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("active")}</Label>
                              <FormGroup className="custom-control custom-switch mt-1 ml-2">
                                <Input
                                  autoComplete="off"
                                  type="checkbox"
                                  className="custom-control-input ml-2 "
                                  id="IsActive"
                                  defaultChecked={values.isActive}
                                  onChange={e => {
                                    setFieldValue(`isActive`, e.target.checked)
                                  }}
                                  onClick={e => {
                                    setFieldValue("isActive", !values.isActive)
                                  }}
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="IsActive"
                                ></Label>
                              </FormGroup>
                              {errors.isActive && (
                                <div className="invalid-feedback d-block">
                                  {errors.isActive}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                        </Row>
                        {this.state.text && (
                          <Row className="mb-10 mt-2 ml-2">
                            <Col xxs="12">
                              {" "}
                              <Button
                                type="button"
                                color="primary"
                                onClick={this.showPreview}
                              >
                                {this.state.isPreview
                                  ? Message("edit")
                                  : Message("preview")}
                              </Button>
                            </Col>
                          </Row>
                        )}
                        <Row className="mb-10 mt-2 ml-2 mr-2">
                          {this.state.isPreview ? (
                            <div
                              className="ml-3"
                              dangerouslySetInnerHTML={{
                                __html: this.state.defaultTemplateResult,
                              }}
                            ></div>
                          ) : (
                            <Col lg="12">
                              <Label className="mt-1 requiredField">
                                {Message("content")}
                              </Label>

                              <JoditEditor
                                ref={this.editorRef}
                                name="content"
                                value={this.state.text}
                                onChange={newContent => {
                                  this.setState({ text: newContent })
                                  setFieldValue("content", newContent)
                                }}
                              />

                              {errors.content && (
                                <div className="invalid-feedback d-block">
                                  {errors.content}
                                </div>
                              )}
                            </Col>
                          )}
                        </Row>

                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                  }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${this.state.buttonAction ? "show-spinner " : ""
                                  }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(CMSContent)
