import { gql } from "@apollo/client"

export const getAllDistrictsQuery = gql`
  query getAllDistricts(
    $stateId:Int
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllDistricts(
      stateId:$stateId
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        name
        state {
          id
          name
        }
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteDistrictMutation = gql`
  mutation deleteDistrict($id: Int!) {
    deleteOneDistrict(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneDistrictQuery = gql`
  query getDistrict($id: Int!) {
    getOneDistrict(id: $id) {
      id
      name
      state {
        id
        name
      }
    }
  }
`
export const createDistrictMutation = gql`
  mutation createDistrict($data: districtInput) {
    createOneDistrict(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateDistrictMutation = gql`
  mutation updateDistrict($id: Int!, $data: districtInput) {
    updateOneDistrict(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`

export const getAllStatesQuery = gql`
  query getAllStates {
    getAllStates {
      items {
        id
        name
      }
    }
  }
`
