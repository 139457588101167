/**START OF GENERATED CODE**/
import React, { Component, Fragment } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Container,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { changePasswordValidation } from "../../helpers/validations"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import logoDark from "../../assets/images/logo_vertace.png"
import {  MutateRequest } from "../../helpers/service_helper"
import { changePasswordMutation } from "./Queries"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Formik, Form, Field } from "formik"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"

class ChangePassword extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {}
  handleSubmit = values => {
    if (values) {
      if (values.NewPassword === values.ConfirmPassword) {
        if (values.NewPassword === values.Password) {
          toastr.error("New Password and Current Password is same")
        } else {
          this.handleChangePassword(values)
        }
      } else {
        toastr.error("New Password and Confirm Password must be same")
      }
    }
  }

  handleChangePassword = async values => {
    if (values) {
      const data = {
        password: values.Password,
        newPassword: values.NewPassword,
      }
      await MutateRequest(changePasswordMutation, data)
        .then(res =>
          res.data?.forcePasswordChange?.status === "Success"
            ? (SuccessMessage("Your Password has Changed Successfully"),
              this.props.history.push("/login"))
            : ""
        )
        .catch(err => ErrorMessage(err))
    } else {
      this.props.history.push("/login")
    }
  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Change Password</title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages  pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden mt-5">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-12">
                        <div className="text-primary p-4 m-2">
                          <h5 className="text-primary">
                            {this.props.t("RUSA")}
                            {/*<p>Sign in to continue to Skote.</p>*/}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      {/* <Link to="/"> */}
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoDark}
                            alt=""
                            className="rounded-circle"
                            height="60"
                            width="60"
                            // style={{ backgroundColor: "white" }}
                            // style={{
                            //   backgroundColor: `url(
                            //     "https://image.slidesharecdn.com/vertacecorporateprofile-141028013550-conversion-gate01/95/vertace-corporate-profile-9-638.jpg?cb=1414460419"
                            //   )`,
                            // }}
                          />
                        </span>
                      </div>
                      {/* </Link> */}
                    </div>
                    <div className="p-2">
                      {" "}
                      <h5 className="mb-2">
                        {this.props.t("Change Password")}
                      </h5>
                      <Formik
                        initialValues={{}}
                        onSubmit={this.handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={changePasswordValidation}
                      >
                        {({ setFieldValue, values, errors }) => (
                          <Form className="av-tooltip tooltip-label-bottom">
                            <Row className="pt-4">
                              <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField">
                                    {this.props.t("Current Password")}
                                  </Label>
                                  <Field
                                    className="form-control"
                                    type="password"
                                    name="Password"
                                  />
                                  {errors.Password && (
                                    <div className="invalid-feedback d-block">
                                      {errors.Password}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField">
                                    {this.props.t("New Password")}
                                  </Label>
                                  <Field
                                    className="form-control box-border"
                                    type="password"
                                    name="NewPassword"
                                  />
                                  {errors.NewPassword && (
                                    <div className="invalid-feedback d-block">
                                      {errors.NewPassword}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              {/* {console.log(errors)} */}
                              <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField">
                                    {this.props.t("Confirm Password")}
                                  </Label>
                                  <Field
                                    className="form-control box-border"
                                    type="password"
                                    name="ConfirmPassword"
                                  />
                                  {errors.ConfirmPassword && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ConfirmPassword}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Col sm={12}>
                              <FormGroup className="float-sm-right">
                                <Button type="submit" outline color="primary">
                                  {this.props.t("Change Password")}
                                </Button>
                                <Button
                                  className="ml-2"
                                  type="button"
                                  outline
                                  onClick={() => {
                                    this.props.history.push("/")
                                  }}
                                  color="danger"
                                >
                                  {this.props.t("Back")}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    )
  }
}
export default withTranslation()(ChangePassword)
