import { gql } from "@apollo/client"

export const getAllSlidersQuery = gql`
  query getAllSliders(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllSliders(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        image
        title
        description
        shortDescription
        isActive
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteSliderMutation = gql`
  mutation deleteSlider($id: Int!) {
    deleteOneSlider(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneSliderQuery = gql`
  query getSlider($id: Int!) {
    getOneSlider(id: $id) {
      id
      image
      title
      description
    }
  }
`
export const createSliderMutation = gql`
  mutation createSlider($data: sliderInput) {
    createOneSlider(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateSliderMutation = gql`
  mutation updateSlider($id: Int!, $data: sliderInput) {
    updateOneSlider(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`

export const fileuploadQuery = gql`
  query getUploadUrl($type: String!, $fileName: String!) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`
export const UpdateManyMutation = gql`
  mutation updateManySliders($data: [sliderInput]) {
    updateManySliders(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
