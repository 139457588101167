import { gql } from "@apollo/client"

export const getAllGalleryAlbumsQuery = gql`
  query getAllAdminGalleryAlbums(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
    $parentAlbumId: Int
  ) {
    getAllAdminGalleryAlbums(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
      parentAlbumId: $parentAlbumId
    ) {
      items {
        id
        description
        createdDateTime
        name
        isActive
        parentAlbumId
        galleryAlbums {
          id
          description
          createdDateTime
          name
          isActive
          parentAlbumId
          galleryPhotos {
            id
            title
            description
            image
          }
          galleryVideos {
            id
            title
            description
            video
          }
        }
        galleryPhotos {
          id
          title
          description
          image
        }
        galleryVideos {
          id
          title
          description
          video
        }
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteGalleryAlbumMutation = gql`
  mutation deleteGalleryAlbum($id: Int!) {
    deleteOneGalleryAlbum(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneGalleryAlbumQuery = gql`
  query getGalleryAlbum($id: Int!) {
    getOneGalleryAlbum(id: $id) {
      id
      description
      createdDateTime
      parentAlbumId
      name
      isActive
    }
  }
`
export const createGalleryAlbumMutation = gql`
  mutation createGalleryAlbum($data: galleryAlbumInput) {
    createOneGalleryAlbum(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateGalleryAlbumMutation = gql`
  mutation updateGalleryAlbum($id: Int!, $data: galleryAlbumInput) {
    updateOneGalleryAlbum(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const UpdateManyMutation = gql`
  mutation updateManyGalleryAlbums($data: [galleryAlbumInput]) {
    updateManyGalleryAlbums(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
