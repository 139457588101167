import { gql } from "@apollo/client"

export const getAllStatesQuery = gql`
  query getAllStates(
     $searchString : String
     $pageLimit : Float
     $pageNumber : Float
     
  ) {
    getAllStates(
     searchString : $searchString
     pageLimit : $pageLimit
     pageNumber : $pageNumber
     
    ) {
      items {
        id
        name
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteStateMutation = gql`
  mutation deleteState($id: Int!) {
    deleteOneState(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneStateQuery = gql`
  query getState($id: Int!) {
    getOneState(id: $id) {
      id
      name
    }
  }
`
export const createStateMutation = gql`
  mutation createState($data: stateInput) {
    createOneState(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateStateMutation = gql`
  mutation updateState($id: Int!,$data: stateInput) {
    updateOneState(id: $id,data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`


