import { gql } from "@apollo/client"

export const getAllEducationalInstitutionsQuery = gql`
  query getAllEducationalInstitutes(
    $searchString: String
    $instituteType: String
    $districtId: Int
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllEducationalInstitutes(
      searchString: $searchString
      instituteType:$instituteType
      districtId:$districtId
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        name
        address
        instituteType
        district {
          id
          name
        } 
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteEducationalInstitutionMutation = gql`
  mutation deleteEducationalInstitute($id: Int!) {
    deleteOneEducationalInstitute(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneEducationalInstitutionQuery = gql`
  query getEducationalInstitution($id: Int!) {
    getOneEducationalInstitution(id: $id) {
      id
      name
      instituteType
      address
      district {
        id
      }
    }
  }
`
export const createEducationalInstitutionMutation = gql`
  mutation createEducationalInstitute($data: educationalInstituteInput) {
    createOneEducationalInstitute(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateEducationalInstitutionMutation = gql`
  mutation updateEducationalInstitute(
    $id: Int!
    $data: educationalInstituteInput
  ) {
    updateOneEducationalInstitute(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`

export const getAllDistrictsQuery = gql`
  query getAllDistricts {
    getAllDistricts {
      items {
        id
        name
      }
    }
  }
`
