import { gql } from "@apollo/client"

export const getAllActivityTypesQuery = gql`
  query getAllActivityTypes(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllActivityTypes(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        name
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteActivityTypeMutation = gql`
  mutation deleteActivityType($id: Int!) {
    deleteOneActivityType(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneActivityTypeQuery = gql`
  query getActivityType($id: Int!) {
    getOneActivityType(id: $id) {
      id
      name
    }
  }
`
export const createActivityTypeMutation = gql`
  mutation createOneActivityType($data: activityTypeInput) {
    createOneActivityType(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updateActivityTypeMutation = gql`
  mutation updateActivityType($id: Int!, $data: activityTypeInput) {
    updateOneActivityType(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
