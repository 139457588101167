import { gql } from "@apollo/client"

export const getAllTeamsQuery = gql`
  query getAllTeams(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllTeams(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        name
        personImage
        designation
        isActive
        rank
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteTeamMutation = gql`
  mutation deleteTeam($id: Int!) {
    deleteOneTeam(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneTeamQuery = gql`
  query getTeam($id: Int!) {
    getOneTeam(id: $id) {
      id
      name
      personImage
      designation
      isActive
      rank
    }
  }
`
export const createTeamMutation = gql`
  mutation createTeam($data: teamInput) {
    createOneTeam(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateTeamMutation = gql`
  mutation updateOneTeam($id: Int!, $data: teamInput) {
    updateOneTeam(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

export const fileuploadQuery = gql`
  query getUploadUrl($type: String!, $fileName: String!) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`
export const UpdateManyMutation = gql`
  mutation updateManyTeams($data: [teamInput]) {
    updateManyTeams(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
