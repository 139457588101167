import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n"

class NonAuthLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.capitalizeFirstLetter.bind(this)
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  componentDidMount() {
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

    document.title = currentage + ""
  }
  render() {
    return (
      <React.Fragment>
        {" "}
        <I18nextProvider i18n={i18n}>
          {this.props.children}
        </I18nextProvider>{" "}
      </React.Fragment>
    )
  }
}

NonAuthLayout.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(NonAuthLayout)
