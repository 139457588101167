import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
} from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ListPageHeader from "../../components/custom/ListPageHeader"
import { StatusCodes, PeriodListFilter } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { ReviewMeetingValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import DropDown from "../../components/custom/DropDown"
import CustomSelectInput from "../../components/Common/CustomSelectInput"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import {
  createOneInspectionMutation,
  updateOneInspectionMutation,
  deleteOneInspectionMutation,
  getAllInspectionsQuery,
} from "./Queries"
import MetaTags from "react-meta-tags"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { unixdateconversion } from "helpers/utils"
class Inspection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      InspectionList: [],
      PeriodList: [],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 10,
      },
      Inspection: {
        component: "",
        description: "",
        institution: "",
        date: "",
        PeriodListFilter: "",
      },
      columns: [
        {
          name: "Component",
          selector: "component",
          sortable: true,
          cell: row => <span>{row.component ? row.component : ""}</span>,
        },
        {
          name: "Institutions",
          selector: "institution",
          sortable: true,
          maxWidth: "250",
          cell: row => <span>{row.institution ? row.institution : ""}</span>,
        },

        {
          name: "Date",
          selector: "date",
          sortable: true,
          cell: row => (
            <div>
              {row.date
                ? unixdateconversion(row.date, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "-"}
            </div>
          ),
        },
        {
          name: "Period",
          selector: "period",
          sortable: true,
          cell: row => <span>{row.period ? row.period : ""}</span>,
        },
        {
          name: "Description",
          selector: "description",
          sortable: true,
          cell: row => <span>{row.description ? row?.description : ""}</span>,
        },

        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: row => (
            <Row>
              <ButtonGroup className="mb-2" style={{ top: "4px" }}>
                <Button
                  outline
                  color="primary"
                  className="mobileViewFont ml-2"
                  onClick={() => this.handleEdit(row)}
                >
                  Edit
                </Button>

                <Button
                  outline
                  color="danger"
                  className="mobileViewFonts pl-1 pr-1 ml-2"
                  onClick={() => this.toggleDeleteModal(row)}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Row>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    this.getAllInspections()
  }

  getAllInspections = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllInspectionsQuery, values)
    var InspectionValue = response?.data?.getAllInspections?.items

    if (InspectionValue) {
      this.setState({
        isLoading: false,
        InspectionList: InspectionValue,
        totalPage:
          response?.data?.getAllInspections?.pagination?.totalCount /
          response?.data?.getAllInspections?.pagination?.pageLimit,
        totalCount: response?.data?.getAllInspections?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  getPeriods = async function (value) {
    this.setState({
      isLoading: true,
    })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllInspections()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllInspections()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllInspections()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllInspections()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        Inspection: row,
        period: row.period,
        date: row.date ? new Date(parseInt(row.date)) : "",
        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    console.log("Submit Function Start", values)
    this.setState({
      buttonAction: true,
    })
    var AddData = {
      component: values.component,
      institution: values.institution,
      date: values.date,
      period: values.period,
      description: values.description,
    }
    var EditVal = {
      id: values.id,
      data: {
        component: values.component,
        institution: values.institution,
        date: values.date,
        period: values.period,
        description: values.description,
      },
    }
    const response = values.id
      ? await MutateRequest(updateOneInspectionMutation, EditVal)
          .then(
            r =>
              this.setState({
                // manageEditModal: !this.state.manageEditModal,
                buttonAction: false,
              }),
            SuccessMessage(Message("Inspection.update"))
          )
          .catch(e => ErrorMessage(e))
      : await MutateRequest(createOneInspectionMutation, {
          data: AddData,
        })
          .then(r => {
            this.setState({
              buttonAction: false,
              // manageEditModal: !this.state.manageEditModal,
            })
            SuccessMessage(Message("Inspection.add"))
          })
          .catch(e => ErrorMessage(e))
    var InspectionValue = response?.data
    if (InspectionValue && values.id) {
      SuccessMessage(Message("Inspection.update")),
        this.setState({
          manageModal: !this.state.manageModal,
        })
      this.getAllInspections()
    } else {
      // SuccessMessage(Message("PABRejected.add")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllInspections()
    }
  }

  addBtnClick = () => {
    this.setState({
      Inspection: {
        component: "",
        description: "",
        institution: "",
        date: "",
        period: "",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      Inspection: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      Inspection: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      Inspection: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteInspection = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deleteOneInspectionMutation, values)
    var InspectiondeleteValue = response?.data
    if (InspectiondeleteValue.deleteOneInspection.status === "Success") {
      SuccessMessage(Message("Inspection.delete"))
      this.getAllInspections()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageInspectionResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.Inspection.id
        ? SuccessMessage(Message("Inspection.update"))
        : SuccessMessage(Message("Inspection.add"))

      this.getAllInspections()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      InspectionDetail: url ? url : "",
    })
  }
  render() {
    const initialValues = this.state.Inspection

    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Inspections</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Inspection"}`}
                    buttonText={Message("add.Inspection")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                <Row>
                  <Col sm="12" md="6" lg="3">
                    {" "}
                    <DropDown
                      label={Message("Period")}
                      MobcolSplit={12}
                      isClearable={true}
                      isSearchable
                      options={PeriodListFilter}
                      ClearAction={() => {
                        this.setState(
                          {
                            listRequestModel: {
                              ...this.state.listRequestModel,
                              period: null,
                            },
                          },
                          () => this.getAllInspections()
                        )
                      }}
                      Action={entity => {
                        if (entity) {
                          this.setState(
                            {
                              listRequestModel: {
                                ...this.state.listRequestModel,
                                period: entity.value,
                              },
                            },
                            () => this.getAllInspections()
                          )
                        } else {
                          this.setState(
                            {
                              listRequestModel: {
                                ...this.state.listRequestModel,
                                period: null,
                              },
                            },
                            () => this.getAllInspections()
                          )
                        }
                      }}
                    />
                  </Col>
                </Row>
                <div className="PoliceView">
                  <ListPage
                    conditionalRowStyles={conditionalRowStyles}
                    columns={this.state.columns}
                    data={this.state.InspectionList}
                    keyField={this.state.keyField}
                    totalCount={this.state.totalCount}
                    rowClicked={this.HandleRowClicked}
                    onSort={this.handleSort}
                    rowsPerPageOnChange={this.handlePerRowsChange}
                    pageChange={this.handlePageChange}
                    isDataLoading={this.state.isLoading}
                    overFlowXRemoval={true}
                  />
                </div>
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.Inspection")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        Inspection ?
                        {/* " {""}
                        {this.state.PABRejected
                          ? this.state.PABRejected.name
                          : ""}{" "}
                        " ? */}
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() =>
                      this.deleteInspection(this.state.Inspection.id)
                    }
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="lg"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.Inspection")
                  : Message("add.Inspection")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                // validationSchema={ReviewMeetingValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{"Component"}</Label>
                              <Field
                                className="form-control"
                                name="component"
                                type="text"
                              />
                              {errors?.component && (
                                <div className="invalid-feedback d-block">
                                  {errors?.component}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{"Institution"}</Label>
                              <Field
                                className="form-control"
                                name="institution"
                                type="text"
                              />
                              {errors?.institution && (
                                <div className="invalid-feedback d-block">
                                  {errors?.institution}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <DropDown
                                label={"Period"}
                                MobcolSplit={12}
                                isClearable={true}
                                labelClassName="requiredField"
                                isSearchable
                                options={PeriodListFilter}
                                value={
                                  values &&
                                  values.PeriodListFilter &&
                                  (typeof values.PeriodListFilter === "string"
                                    ? PeriodListFilter.find(
                                        r => r.label === values.PeriodListFilter
                                      )
                                    : PeriodListFilter.find(
                                        r => r.label === values.PeriodListFilter
                                      ))
                                }
                                ClearAction={() => {
                                  setFieldValue(`period`, "")
                                }}
                                Action={entity => {
                                  if (entity) {
                                    setFieldValue("period", entity.value)
                                  } else {
                                    setFieldValue("period", "")
                                  }
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{"Date"}</Label>
                              <DatePicker
                                className="form-control box-border"
                                selected={this.state.date}
                                onChange={date => {
                                  this.setState({
                                    date: date,
                                  })
                                  if (date)
                                    setFieldValue(
                                      `date`,
                                      new Date(date).valueOf().toString()
                                    )
                                  else setFieldValue(`date`, "")
                                }}
                                dateFormat="dd/MM/yyyy"
                              />
                              {errors.date && (
                                <div className="invalid-feedback d-block">
                                  {errors?.date}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">
                                {Message("description")}
                              </Label>
                              <Field
                                className="form-control"
                                name="description"
                                type="text"
                              />
                              {errors.description && (
                                <div className="invalid-feedback d-block">
                                  {errors?.description}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction ? "show-spinner " : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(Inspection)
