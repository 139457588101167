import { gql } from "@apollo/client"

export const getAllPABRejectedsQuery = gql`
  query getAllPABRejecteds(
    $componentId:Int
    $educationalInstituteId:Int
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllPABRejecteds(
      componentId: $componentId
      educationalInstituteId: $educationalInstituteId
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
    pabApprovedId 
    pabCancelledId
    educationalInstituteId
    approvedAmount
    componentId
    component{
      id
      name
    }
    pabApproved{
      id
      name
    }
    pabCancelled{
      id
      name
    }
    educationalInstitute{
      id
      name
      instituteType
      address
      districtId
      district{
        name
        state{
          id
          name
        }
      }
    }
    }
    pagination{
      pageNumber
      pageLimit
      totalCount
    }
  }
}
`
export const deletePABRejectedMutation = gql`
  mutation deletePABRejected($id: Int!) {
    deleteOnePABRejected(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOnePABRejectedQuery = gql`
  query getPABRejected($id: Int!) {
    getOnePABRejected(id: $id) {
      id
    pabApprovedId 
    pabCancelledId
    educationalInstituteId
    approvedAmount
    componentId
    pabApproved{
      id
      name
    }
    pabCancelled{
      id
      name
    }
    educationalInstitute{
      id
      name
      instituteType
      address
      districtId
      district{
        name
        state{
          id
          name
        }
      }
    }
    }
  }
`
export const createPABRejectedMutation = gql`
mutation createOnePABRejected($data:pabRejectedInput){
  createOnePABRejected(data:$data){
    id
    status
    error{
       statusCode
      message
    }
  }
}
`
export const updatePABRejectedMutation = gql`
  mutation updatePABRejected(
    $id: Int!
    $data: pabRejectedInput
  ) {
    updateOnePABRejected(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`

export const getAllPhaseComponentsQuery = gql`
  query getAllPhaseComponents {
    getAllPhaseComponents {
      items {
        id
        name
        phaseId
        componentId
        phase{
          id
          name
        }
        component{
          id
          name
        }
      }
    }
  }
`

export const getAllPABsQuery = gql`
  query getAllPABs {
    getAllPABs {
      items {
        id
        name
      }
    }
  }
`

export const getAllEducationalInstitutesQuery = gql`
  query getAllEducationalInstitutes {
    getAllEducationalInstitutes {
      items {
        id
        name
      }
    }
  }
`