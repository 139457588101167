import React, { Component, Fragment } from "react"
// /drg
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
  Media,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import DropDown from "../../components/custom/DropDown"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ListPageHeader from "../../components/custom/ListPageHeader"
import { MethodType, StatusCodes } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { GalleryAlbumValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CustomSelectInput from "../../components/Common/CustomSelectInput"
import { getDateTimeString, unixdateconversion } from "../../helpers/utils"
import { Tabs, Tab } from "react-bootstrap"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import Table from "react-bootstrap/Table"
import {
  createGalleryAlbumMutation,
  updateGalleryAlbumMutation,
  deleteGalleryAlbumMutation,
  getAllGalleryAlbumsQuery,
  getOneGalleryAlbumQuery,
  UpdateManyMutation,
} from "./Queries"
import moment from "moment"
import MetaTags from "react-meta-tags"

class GalleryAlbum extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: false,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      galleryAlbumList: [],
      items: [],
      galleryAlbumListAdd: [],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 0,
      },
      galleryAlbum: {
        createdDateTime: "",
        name: "",
        isActive: false,
        description: "",
      },
      columns: [
        {
          name: "Name",
          selector: "name",
          sortable: true,
          cell: row => <span> {row.name ? row?.name : ""}</span>,
        },
        {
          name: "Description",
          selector: "descriptiom",
          sortable: true,
          cell: row => (
            <span>
              {row.description ? row.description : ""}
              {/* {row.isBeneficiary == true
                ? "RUSA Beneficiary"
                : row.isBeneficiary == false
                ? "RUSA Non Beneficiary"
                : ""} */}
            </span>
          ),
        },
        {
          name: "Created At",
          selector: "createdAt",
          sortable: true,
          cell: row => (
            <span>
              {row.createdDateTime
                ? unixdateconversion(row.createdDateTime, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : "-"}
            </span>
          ),
        },
        {
          name: "Is Active",
          selector: "isActive",
          sortable: true,
          cell: row => (
            <span> {row.isActive === true ? "Active" : "Not Active"}</span>
          ),
        },

        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: row => (
            <Row>
              <ButtonGroup className="mb-2" style={{ top: "4px" }}>
                <Button
                  outline
                  color="primary"
                  className="mobileViewFont ml-2"
                  onClick={() => this.handleEdit(row)}
                >
                  Edit
                </Button>

                <Button
                  outline
                  color="danger"
                  className="mobileViewFonts pl-1 pr-1 ml-2"
                  onClick={() => this.toggleDeleteModal(row)}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Row>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    this.getAllAdminGalleryAlbums()
    this.getAllAdminGallery()
  }

  getAllAdminGalleryAlbums = async value => {
    // alert("Please select a list of getAllAdminGalleryAlbums")
    console.log("value")
    // this.setState()
    const { listRequestModel } = this.state
    var values = listRequestModel

    console.log("listRequestModelState" + JSON.stringify(listRequestModel))

    const response = await QueryRequest(getAllGalleryAlbumsQuery, values)
    if (response?.data?.getAllAdminGalleryAlbums?.items) {
      this.setState({
        items: response.data.getAllAdminGalleryAlbums.items,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  getAllAdminGallery = async value => {
    console.log("valuevaluevaluevaluevalue")

    const response = await QueryRequest(getAllGalleryAlbumsQuery, "")
    if (response?.data?.getAllAdminGalleryAlbums?.items) {
      this.setState({
        galleryAlbumList: response.data.getAllAdminGalleryAlbums.items.map(
          function (item) {
            return { label: item.name, value: item.id }
          }
        ),
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllAdminGalleryAlbums()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllAdminGalleryAlbums()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllAdminGalleryAlbums()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllAdminGalleryAlbums()
        }
      }
    )
  }

  handleEdit = row => {
    console.log("row", row)
    if (row) {
      this.setState({
        galleryAlbum: row,
        manageModal: !this.state.manageModal,
        // parentAlbumId: row.parentAlbumId,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })
    var AddData = {
      name: values.name,
      isActive: values.isActive,
      description: values.description,
      parentAlbumId: values.parentAlbumId,
      sortId: 1,
    }
    var EditVal = {
      id: values.id,
      data: {
        name: values.name,
        isActive: values.isActive,
        description: values.description,
        sortId: 1,
        parentAlbumId: values.parentAlbumId,
      },
    }
    const response = values.id
      ? await MutateRequest(updateGalleryAlbumMutation, EditVal)
          .then(
            r =>
              this.setState({
                // manageEditModal: !this.state.manageEditModal,
                buttonAction: false,
              }),
            SuccessMessage(Message("GalleryAlbum.update"))
          )
          .catch(e => ErrorMessage(e))
      : await MutateRequest(createGalleryAlbumMutation, { data: AddData })
          .then(r => {
            this.setState({
              // manageEditModal: !this.state.manageEditModal,
              buttonAction: false,
            })
            SuccessMessage(Message("GalleryAlbum.add"))
          })
          .catch(e => ErrorMessage(e))
    var galleryAlbumValue = response?.data
    if (galleryAlbumValue && values.id) {
      // SuccessMessage(Message("galleryAlbum.update")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllAdminGalleryAlbums()
    } else {
      // SuccessMessage(Message("galleryAlbum.add")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllAdminGalleryAlbums()
    }
  }

  addBtnClick = () => {
    // alert("addd")
    this.setState(
      {
        galleryAlbum: {
          createdDateTime: "",
          name: "",
          isActive: false,
          description: "",
          // parentAlbumId: "",
        },
        manageModal: !this.state.manageModal,
        Label: "Add",
      }
      // () => this.getAllAdminGalleryAlbums()
    )
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      galleryAlbum: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      galleryAlbum: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      galleryAlbum: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteGalleryAlbum = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deleteGalleryAlbumMutation, values)
    var galleryAlbumdeleteValue = response?.data
    if (galleryAlbumdeleteValue.deleteOneGalleryAlbum.status === "Success") {
      SuccessMessage(Message("galleryAlbum.delete"))
      this.getAllAdminGalleryAlbums()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageGalleryAlbumResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.galleryAlbum.id
        ? SuccessMessage(Message("GalleryAlbum.update"))
        : SuccessMessage(Message("GalleryAlbum.add"))

      this.getAllAdminGalleryAlbums()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      galleryAlbumDetail: url ? url : "",
    })
  }

  render() {
    const initialValues = this.state.galleryAlbum
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]

    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Gallery Album</title>
          </MetaTags>

          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Gallery Albums"}`}
                    buttonText={Message("add.GalleryAlbum")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                <Row>
                  <Col sm="12" md="6" lg="3">
                    {" "}
                    <DropDown
                      label={Message("Album")}
                      MobcolSplit={12}
                      isClearable={true}
                      isSearchable
                      options={this.state.galleryAlbumList}
                      ClearAction={() => {
                        this.setState(
                          {
                            listRequestModel: {
                              ...this.state.listRequestModel,
                              parentAlbumId: null,
                            },
                          },
                          () => this.getAllAdminGalleryAlbums()
                        )
                      }}
                      Action={entity => {
                        if (entity) {
                          this.setState(
                            {
                              listRequestModel: {
                                ...this.state.listRequestModel,
                                parentAlbumId: entity.value,
                              },
                            },
                            () => this.getAllAdminGalleryAlbums()
                          )
                        } else {
                          this.setState(
                            {
                              listRequestModel: {
                                ...this.state.listRequestModel,
                                parentAlbumId: null,
                              },
                            },
                            () => this.getAllAdminGalleryAlbums()
                          )
                        }
                      }}
                    />
                  </Col>
                </Row>

                <div className="PoliceView">
                  <ListPage
                    conditionalRowStyles={conditionalRowStyles}
                    columns={this.state.columns}
                    data={this.state.items}
                    keyField={this.state.keyField}
                    totalCount={this.state.totalCount}
                    rowClicked={this.HandleRowClicked}
                    onSort={this.handleSort}
                    rowsPerPageOnChange={this.handlePerRowsChange}
                    pageChange={this.handlePageChange}
                    isDataLoading={this.state.isLoading}
                    overFlowXRemoval={true}
                  />
                </div>
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.galleryAlbum")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        GalleryAlbum " {""}
                        {this.state.galleryAlbum
                          ? this.state.galleryAlbum.name
                          : ""}{" "}
                        " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() =>
                      this.deleteGalleryAlbum(this.state.galleryAlbum.id)
                    }
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="md"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.GalleryAlbum")
                  : Message("add.GalleryAlbum")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={GalleryAlbumValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">
                                {Message("name")}
                              </Label>
                              <Field
                                className="form-control"
                                name="name"
                                type="text"
                              />
                              {errors.name && (
                                <div className="invalid-feedback d-block">
                                  {errors.name}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">
                                {Message("description")}
                              </Label>
                              <Field
                                className="form-control"
                                name="description"
                                type="text"
                              />
                              {errors.description && (
                                <div className="invalid-feedback d-block">
                                  {errors.description}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <DropDown
                                label={Message("album")}
                                MobcolSplit={12}
                                isClearable={true}
                                labelClassName="requiredField"
                                isSearchable
                                value={
                                  values &&
                                  values.albumId &&
                                  (typeof values.albumId === "string"
                                    ? this.state.galleryAlbumList.find(
                                        r => r.value === values.parentAlbumId
                                      )
                                    : this.state.galleryAlbumList.find(
                                        r => r.value === values.parentAlbumId
                                      ))
                                }
                                options={this.state.galleryAlbumList}
                                // ClearAction={() => {
                                //   setFieldValue(`parentAlbumId`, "")
                                // }}
                                ClearAction={() => {
                                  setFieldValue(`parentAlbumId`, ""),
                                    this.setState(
                                      {
                                        parentAlbum: null,
                                      },
                                      () => this.getAllAdminGallery()
                                    )
                                }}
                                Action={entity => {
                                  if (entity) {
                                    setFieldValue("parentAlbumId", entity.value)
                                    // setFieldValue(`NextStates`, "")
                                  } else {
                                    setFieldValue(`parentAlbumId`, "")
                                  }
                                }}
                                errors={errors.albumId}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("active")}</Label>
                              <FormGroup className="custom-control custom-switch mt-1 ml-2">
                                <Input
                                  autoComplete="off"
                                  type="checkbox"
                                  className="custom-control-input ml-2 "
                                  id="IsActive"
                                  defaultChecked={values.isActive}
                                  onChange={e => {
                                    setFieldValue(`isActive`, e.target.checked)
                                  }}
                                  onClick={e => {
                                    setFieldValue("isActive", !values.isActive)
                                  }}
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="IsActive"
                                ></Label>
                              </FormGroup>
                              {errors.isActive && (
                                <div className="invalid-feedback d-block">
                                  {errors.isActive}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction ? "show-spinner " : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(GalleryAlbum)
