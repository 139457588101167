import { gql } from "@apollo/client"

export const getAllNAACAccredationFinancialsQuery = gql`
  query getAllNAACAccredationFinancials(
    $educationalInstituteId:Int
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllNAACAccredationFinancials(
      educationalInstituteId: $educationalInstituteId
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        educationalInstituteId
        naacScore
        grade
        yearEstablished
        validity
        grant
        fundReleased
        educationalInstitute{
          id
          name
          instituteType
        }
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteNAACAccredationFinancialMutation = gql`
  mutation deleteNAACAccredationFinancial($id: Int!) {
    deleteOneNAACAccredationFinancial(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneNAACAccredationFinancialQuery = gql`
  query getNAACAccredationFinancial($id: Int!) {
    getOneNAACAccredationFinancial(id: $id) {
      id
      educationalInstituteId
      naacScore
      grade
      yearEstablished
      validity
      grant
      fundReleased
      educationalInstitute{
        id
        name
        instituteType
      }
    }
  }
`
export const createNAACAccredationFinancialMutation = gql`
  mutation createNAACAccredationFinancial($data: naacAccredationFinancialInput) {
    createOneNAACAccredationFinancial(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateNAACAccredationFinancialMutation = gql`
  mutation updateNAACAccredationFinancial(
    $id: Int!
    $data: naacAccredationFinancialInput
  ) {
    updateOneNAACAccredationFinancial(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`

export const getAllEducationalInstitutesQuery = gql`
  query getAllEducationalInstitutes {
    getAllEducationalInstitutes {
      items {
        id
        name
        instituteType
      }
    }
  }
`