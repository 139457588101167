import { gql } from "@apollo/client"

export const getAllNAACAccredationsQuery = gql`
  query getAllNAACAccredations(
    $educationalInstituteId:Int
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllNAACAccredations(
      educationalInstituteId: $educationalInstituteId
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        educationalInstituteId
        isBeneficiary
        naacScore
        grade
        yearEstablished
        validity
        educationalInstitute{
          id
          name
          instituteType
        }
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteNAACAccredationMutation = gql`
  mutation deleteNAACAccredation($id: Int!) {
    deleteOneNAACAccredation(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneNAACAccredationQuery = gql`
  query getNAACAccredation($id: Int!) {
    getOneNAACAccredation(id: $id) {
      id
      educationalInstituteId
      isBeneficiary
      naacScore
      grade
      yearEstablished
      validity
      educationalInstitute{
        id
        name
        instituteType
      }
    }
  }
`
export const createNAACAccredationMutation = gql`
  mutation createNAACAccredation($data: naacAccredationInput) {
    createOneNAACAccredation(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateNAACAccredationMutation = gql`
  mutation updateNAACAccredation(
    $id: Int!
    $data: naacAccredationInput
  ) {
    updateOneNAACAccredation(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`

export const getAllEducationalInstitutesQuery = gql`
  query getAllEducationalInstitutes {
    getAllEducationalInstitutes {
      items {
        id
        name
        instituteType
      }
    }
  }
`