import { gql } from "@apollo/client"

export const getAllReviewmeetinsQuery = gql`
  query getAllReviewMeeting(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllReviewMeetings(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        heldDate
        conductedBy
        isDeleted
        description
        error {
          statusCode
          message
        }
      }
    }
  }
`
export const deleteReviewMeetingMutation = gql`
  mutation deleteOneReviewMeeting($id: Int!) {
    deleteOneReviewMeeting(id: $id) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

export const createReviewMeetingMutation = gql`
  mutation createOneReviewMeeting($data: reviewMeetingInput) {
    createOneReviewMeeting(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updateReviewMeetingMutation = gql`
  mutation updateOneReviewMeeting($id: Int!, $data: reviewMeetingInput) {
    updateOneReviewMeeting(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

