import { gql } from "@apollo/client"

export const getAllPabsQuery = gql`
  query getAllPAB(
    $searchString: String
    $pageNumber: Float
    $pageLimit: Float
  ) {
    getAllPABs(
      searchString: $searchString
      pageNumber: $pageNumber
      pageLimit: $pageLimit
    ) {
      items {
        id
        name
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deletePabMutation = gql`
  mutation deleteOnePAB($id: Int!) {
    deleteOnePAB(id: $id) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const getOnePabQuery = gql`
  query getPab($id: Int!) {
    getOnePab(id: $id) {
      id
      name
    }
  }
`
export const createPabMutation = gql`
  mutation createOnePAB($data: pabInput) {
    createOnePAB(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updatePabMutation = gql`
  mutation updateOnePAB($id: Int!, $data: pabInput) {
    updateOnePAB(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
