import { gql } from "@apollo/client"

export const getAllGalleryPhotosQuery = gql`
  query getAllGalleryPhoto(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float){
    getAllGalleryPhotos(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        isDeleted
        sortId
        createdDateTime
        image
        title
        galleryPhotoContent
        albumId
        galleryAlbum {
          id
          name
          createdDateTime
        }
        description
        error {
          statusCode
          message
        }
      }
    }
  }
`
export const deleteGalleryPhotoMutation = gql`
  mutation deleteGalleryPhoto($id: Int!) {
    deleteOneGalleryPhoto(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneGalleryPhotoQuery = gql`
  query getGalleryPhoto($id: Int!) {
    getOneGalleryPhoto(id: $id) {
      id
      image
      description
      address
      album {
        name
      }
    }
  }
`
export const createGalleryPhotoMutation = gql`
  mutation createOneGalleryPhoto($data: galleryPhotoInput) {
    createOneGalleryPhoto(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updateGalleryPhotoMutation = gql`
  mutation updateOneGalleryPhoto($id: Int!, $data: galleryPhotoInput) {
    updateOneGalleryPhoto(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

export const getAllGalleryAlbumsQuery = gql`
 query getAllAdminGalleryAlbums{
  getAllAdminGalleryAlbums{
    items{
      id
      name
    }
  }
 }
`
export const fileuploadQuery = gql`
  query getUploadUrl($type: String!, $fileName: String!) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`
export const UpdateManyMutation = gql`
  mutation updateManyGalleryPhotos($data: [galleryPhotoInput]) {
    updateManyGalleryPhotos(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
