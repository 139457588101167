import { gql } from "@apollo/client"

export const getAllGalleryVideosQuery = gql`
  query getAllGalleryVideo(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllGalleryVideos(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        video
        description
        albumId
        isActive
        title
        createdDateTime
        galleryAlbum {
          name
        }
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteGalleryVideoMutation = gql`
  mutation deleteGalleryVideo($id: Int!) {
    deleteOneGalleryVideo(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneGalleryVideoQuery = gql`
  query getGalleryVideo($id: Int!) {
    getOneGalleryVideo(id: $id) {
      id
      video
      description
      address
      album {
        name
      }
    }
  }
`
export const createGalleryVideoMutation = gql`
  mutation createGalleryVideo($data: galleryVideoInput) {
    createOneGalleryVideo(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateGalleryVideoMutation = gql`
  mutation updateGalleryVideo($id: Int!, $data: galleryVideoInput) {
    updateOneGalleryVideo(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`

export const getAllGalleryAlbumsQuery = gql`
  query getAllGalleryAlbums {
    getAllGalleryAlbums {
      items {
        id
        name
      }
    }
  }
`

export const fileuploadQuery = gql`
  query getUploadUrl($type: String!, $fileName: String!) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`
export const UpdateManyMutation = gql`
  mutation updateManyGalleryVideos($data: [galleryVideoInput]) {
    updateManyGalleryVideos(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
