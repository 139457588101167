import React, { Component, Fragment } from "react"
// dr
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
  Media,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ListPageHeader from "../../components/custom/ListPageHeader"
import { MethodType, StatusCodes } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { FaqValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import DropDown from "../../components/custom/DropDown"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CustomSelectInput from "../../components/Common/CustomSelectInput"
import { getDateTimeString, unixdateconversion } from "../../helpers/utils"
import { Tabs, Tab } from "react-bootstrap"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import Table from "react-bootstrap/Table"
import {
  createFaqMutation,
  updateFaqMutation,
  deleteFaqMutation,
  getAllFaqsQuery,
  getOneFaqQuery,
  UpdateManyMutation,
} from "./Queries"
import moment from "moment"
import MetaTags from "react-meta-tags"

class Faq extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      faqList: [],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 0,
      },
      faq: {
        answer: "",
        question: "",
        isActive: false,
        faqContent: "",
      },
    }
  }

  componentDidMount() {
    this.getAllFaqs()
  }
  getAllFaqs = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllFaqsQuery, values)
    var faqsValue = response?.data?.getAllFAQs?.items
    if (faqsValue) {
      this.setState({
        isLoading: false,
        faqList: faqsValue,
        totalPage:
          response?.data?.getAllFAQs?.pagination?.totalCount /
          response?.data?.getAllFAQs?.pagination?.pageLimit,
        totalCount: response?.data?.getAllFAQs?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllFaqs()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllFaqs()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllFaqs()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllFaqs()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        faq: row,
        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })
    var AddData = {
      answer: values.answer,
      question: values.question,
      isActive: values.isActive,
      faqContent: values.faqContent,
    }
    var EditVal = {
      id: values.id,
      data: {
        answer: values.answer,
        question: values.question,
        isActive: values.isActive,
        faqContent: values.faqContent,
      },
    }
    const response = values.id
      ? await MutateRequest(updateFaqMutation, EditVal)
          .then(
            r =>
              this.setState({
                buttonAction: false,
              }),
            SuccessMessage(Message("Faq.update"))
          )
          .catch(e => ErrorMessage(e))
      : await MutateRequest(createFaqMutation, { data: AddData })
          .then(r => {
            this.setState({
              buttonAction: false,
            })
            SuccessMessage(Message("Faq.add"))
          })
          .catch(e => ErrorMessage(e))
    var faqValue = response?.data
    if (faqValue && values.id) {
      // SuccessMessage(Message("faq.update")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllFaqs()
    } else {
      // SuccessMessage(Message("faq.add")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllFaqs()
    }
  }

  addBtnClick = () => {
    this.setState({
      faq: {
        answer: "",
        question: "",
        isActive: false,
        faqContent: "",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      faq: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      faq: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      faq: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteFaq = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deleteFaqMutation, values)
    var faqdeleteValue = response?.data
    if (faqdeleteValue.deleteOneFAQ.status === "Success") {
      SuccessMessage(Message("faq.delete"))
      this.getAllFaqs()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageFaqResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.faq.id
        ? SuccessMessage(Message("Faq.update"))
        : SuccessMessage(Message("Faq.add"))

      this.getAllFaqs()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      faqDetail: url ? url : "",
    })
  }

  handleOnDragEnd = async result => {
    if (!result.destination) return

    this.setState({ isLoading: true })
    const items = Array.from(this.state.faqList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    this.setState({ data: items })
    const res = []
    items.map((u, i) => {
      res.push({
        id: u.id,
        sortId: i + 1,
        answer: u.answer,
        question: u.question,
        faqContent: u.faqContent,
        isActive: u.isActive,
      })
    })
    console.log({ data: res })
    const response = await MutateRequest(UpdateManyMutation, {
      data: res,
    })
    console.log(response)
    if (response?.data?.updateManyFAQs.status === "Success") {
      SuccessMessage("Updated Successfully")
      this.getAllFaqs()
    } else {
      ErrorMessage("Error")
    }
  }
  render() {
    const initialValues = this.state.faq
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>FAQ</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Faqs"}`}
                    buttonText={Message("add.Faq")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                {this.state.isLoading ? (
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="spinner-border text-primary m-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </Row>
                ) : (
                  <div className="PoliceView">
                    <Table stripped bordered hover size="sm">
                      <thead>
                        <tr
                        >
                          
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Question
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Answer
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            FAQ Content
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Is Active
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              paddingBottom: "15px",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <DragDropContext
                        onDragEnd={result => this.handleOnDragEnd(result)}
                      >
                        <Droppable droppableId="characters">
                          {provided => (
                            <tbody
                              className="characters"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {this.state.faqList.map((row, i) => {
                                return (
                                  <Draggable
                                    key={row.id}
                                    draggableId={row.id.toString()}
                                    index={i}
                                  >
                                    {provided => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.question}
                                        </td>{" "}
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.answer}
                                        </td>{" "}
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.faqContent}
                                        </td>{" "}
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.isActive
                                            ? "Active"
                                            : "Not Active"}
                                        </td>
                                        <td
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Row>
                                            <ButtonGroup
                                              className="mb-2"
                                              style={{ top: "4px" }}
                                            >
                                              <Button
                                                outline
                                                color="primary"
                                                className="mobileViewFont ml-2"
                                                onClick={() =>
                                                  this.handleEdit(row)
                                                }
                                              >
                                                Edit
                                              </Button>

                                              <Button
                                                outline
                                                color="danger"
                                                className="mobileViewFonts pl-1 pr-1 ml-2"
                                                onClick={() =>
                                                  this.toggleDeleteModal(row)
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </ButtonGroup>
                                          </Row>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Table>
                  </div>
                )}
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.faq")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        Faq " {""}
                        {this.state.faq ? this.state.faq.question : ""} " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() => this.deleteFaq(this.state.faq.id)}
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="md"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.Faq")
                  : Message("add.Faq")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={FaqValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                        <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">{Message("Question")}</Label>
                              <Field
                                className="form-control"
                                name="question"
                                type="text"
                              />
                              {errors.question && (
                                <div className="invalid-feedback d-block">
                                  {errors.question}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">{Message("Answer")}</Label>
                              <Field
                                className="form-control"
                                name="answer"
                                type="text"
                              />
                              {errors.answer && (
                                <div className="invalid-feedback d-block">
                                  {errors.answer}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("content")}</Label>
                              <Field
                                className="form-control"
                                name="faqContent"
                                type="text"
                              />
                              {errors.faqContent && (
                                <div className="invalid-feedback d-block">
                                  {errors.faqContent}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label ml-2 pl-1 slider-padding">
                              <Label className="mt-1 ">
                                {Message("active")}
                              </Label>
                              <FormGroup className="custom-control custom-switch mt-1 ml-2">
                                <Input
                                  autoComplete="off"
                                  type="checkbox"
                                  className="custom-control-input ml-2 "
                                  id="IsActive"
                                  defaultChecked={values.isActive}
                                  onChange={e => {
                                    setFieldValue(`isActive`, e.target.checked)
                                  }}
                                  onClick={e => {
                                    setFieldValue("isActive", !values.isActive)
                                  }}
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="IsActive"
                                ></Label>
                              </FormGroup>
                              {errors.isActive && (
                                <div className="invalid-feedback d-block">
                                  {errors.isActive}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction ? "show-spinner " : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(Faq)
