import { gql } from "@apollo/client"

export const deleteSanctionOrderMutation = gql`
  mutation deleteOneSanctionOrder($id: Int!) {
    deleteOneSanctionOrder(id: $id) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const getOneSanctionOrderQuery = gql`
  query getSanctionOrder($id: Int!) {
    getOneSanctionOrder(id: $id) {
      id
      isDeleted
      sortId
      fileURL
      orderTypeId
      isActive
      title
      orderType {
        id
        name
      }
    }
  }
`
export const createSanctionOrderMutation = gql`
  mutation createOneSanctionOrder($data: sanctionOrderInput) {
    createOneSanctionOrder(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updateSanctionOrderMutation = gql`
  mutation updateOneSanctionOrder($id: Int!, $data: sanctionOrderInput) {
    updateOneSanctionOrder(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const fileuploadQuery = gql`
  query getUploadUrl($type: String!, $fileName: String!) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`
export const getAllorderQuery = gql`
  query getAllOrderType {
    getAllOrderTypes {
      items {
        id
        name
      }
    }
  }
`
export const getAllSanctionOrderQuery = gql`
  query getAllSanctionOrder(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
    $orderTypeId: Int
  ) {
    getAllSanctionOrders(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
      orderTypeId: $orderTypeId
    ) {
      items {
        id
        title
        sanctionContent
        fileURL
        isActive
        orderTypeId
        orderType {
          id
          name
        }
        error {
          statusCode
          message
        }
      }
    }
  }
`
export const UpdateManyMutation = gql`
  mutation updateManySanctionOrders($data: [sanctionOrderInput]) {
    updateManySanctionOrders(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
