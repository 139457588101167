import { gql } from "@apollo/client"
export const LoginMutation = gql`
query login($userName: String!, $password: String!) {

  login(userName: $userName, password: $password) {

    userId

    token
 
    userName

    name

    address

    userProfiles {

      id

      name

      mobileNumber

      address

    }

    pageAccess{

      id

      name

    }

    pageComponentAccess{

      id

      name

      isItemSpecific

      uiModuleId

    }

    roles {

      id

      name

    }

    error {

      statusCode

      message

    }

  }

}

`

export const forceChangePasswordMutation = gql`
  mutation forcePasswordChange(
    $mobileNumber: String!
    $password: String!
    $newPassword: String!
  ) {
    forcePasswordChange(
      userName: $userName
      newPassword: $newPassword
      password: $password
    ) {
      id
      status
      error {
        message
      }
    }
  }
`
export const changePasswordMutation = gql`
  mutation passwordChange($password: String!, $newPassword: String!) {
    passwordChange(password: $password, newPassword: $newPassword) {
      id
      status
      error {
        message
      }
    }
  }
`
