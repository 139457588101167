import { gql } from "@apollo/client"

export const getAllComponentEducationPABsQuery = gql`
  query getAllComponentEducationPABs(
    $pabId:Int
    $componentId:Int
    $educationalInstituteId:Int
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllComponentEducationPABs(
      pabId: $pabId
      componentId: $componentId
      educationalInstituteId: $educationalInstituteId
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        pabId
        educationalInstituteId
        componentId
        pabApprovedAmount
        centralShare
        stateShare
        totalShare
        releasedCentralShare
        releasedStateShare
        releasedTotalShare
        pab {
          id
          name
        } 
        educationalInstitute{
          id
          name
          address
          district{
            id
            name
          }
        }
        component{
          id
          name
        }
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteComponentEducationPABMutation = gql`
  mutation deleteComponentEducationPAB($id: Int!) {
    deleteOneComponentEducationPAB(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneComponentEducationPABQuery = gql`
  query getComponentEducationPAB($id: Int!) {
    getOneComponentEducationPAB(id: $id) {
      id
        pabId
        educationalInstituteId
        componentId
        pabApprovedAmount
        centralShare
        stateShare
        totalShare
        releasedCentralShare
        releasedStateShare
        releasedTotalShare
        pab {
          id
          name
        } 
        educationalInstitute{
          id
          name
        }
        component{
          id
          name
        }
    }
  }
`
export const createComponentEducationPABMutation = gql`
  mutation createComponentEducationPAB($data: componentEducationPABInput) {
    createOneComponentEducationPAB(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateComponentEducationPABMutation = gql`
  mutation updateComponentEducationPAB(
    $id: Int!
    $data: componentEducationPABInput
  ) {
    updateOneComponentEducationPAB(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`

export const getAllPhaseComponentsQuery = gql`
  query getAllPhaseComponents {
    getAllPhaseComponents {
      items {
        id
        name
        phaseId
        componentId
        phase{
          id
          name
        }
        component{
          id
          name
        }
      }
    }
  }
`

export const getAllPABsQuery = gql`
  query getAllPABs {
    getAllPABs {
      items {
        id
        name
      }
    }
  }
`

export const getAllEducationalInstitutesQuery = gql`
  query getAllEducationalInstitutes {
    getAllEducationalInstitutes {
      items {
        id
        name
      }
    }
  }
`