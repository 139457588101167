import { format } from "date-fns"
import moment from "moment"
import SecureLS from "secure-ls"
import { allMenu } from "../constants/menu"
export const getItemFromLocalStorage = key => {
  return new SecureLS({ encodingType: "aes" }).get(key)
}

export const setItemOnLocalStorage = (key, value) => {
  new SecureLS({ encodingType: "aes" }).set(key, value)
}

export const removeItemOnLocalStorage = key => {
  new SecureLS({ encodingType: "aes" }).remove(key)
}

export const clearValuesOnLogout = () => {
  new SecureLS({ encodingType: "aes" }).removeAll()
}

export const formatDate = (date, formatStr) => {
  return format(date, formatStr)
}

export const timestampToDate = date => {
  var a = new Date(parseInt(date) * 1000)
  var year = a.getFullYear()
  var month = a.getMonth() + 1
  var new_date = a.getDate()
  var time = new_date + "/" + month + "/" + year
  return time
}

export const AddOrRemoveClassToElementsByClassName = (
  className,
  classNameToBeAdded,
  classNameToRemoved
) => {
  var elements = document.getElementsByClassName(className)

  for (var e = 0; e < elements.length; e++) {
    if (elements[e] != null) {
      // For each element
      var element = elements[e]
      for (var i = 0; i < classNameToBeAdded.length; i++) {
        element.classList.add(classNameToBeAdded[i])
      }
      for (var j = 0; j < classNameToRemoved.length; j++) {
        element.classList.remove(classNameToRemoved[j])
      }
    }
  }
}

export const ValidateComponent = componentId => {
  let showComponent = false
  let accessibleComponents = JSON.parse(
    getItemFromLocalStorage("PageComponentAccess")
  )
  // let userDetails = JSON.parse(getItemFromLocalStorage("user_detail"))
  // console.log("PageComponentAccess" + JSON.stringify(accessibleComponents))
  let allowedComponents =
    accessibleComponents && accessibleComponents.length > 0
      ? accessibleComponents.map(x => x.id).flat(Infinity)
      : []

  if (allowedComponents.find(x => x === componentId)) {
    showComponent = true
  }
  return showComponent // Compnent validation added
  // return true
}

export const AddOrRemoveClassToElementsById = (
  id,
  classNameToBeAdded,
  classNameToRemoved
) => {
  var element = document.getElementById(id)
  if (element != null) {
    for (var i = 0; i < classNameToBeAdded.length; i++) {
      element.classList.add(classNameToBeAdded[i])
    }
    for (var j = 0; j < classNameToRemoved.length; j++) {
      element.classList.remove(classNameToRemoved[j])
    }
  }
}

export const CalculatePercentage = (total, value) => {
  if (!isNaN(total) && !isNaN(value) && total !== 0 && value !== 0) {
    return ((value / total) * 100).toFixed(0)
  } else {
    return parseFloat("0").toFixed(0)
  }
}

// export const GetGender = val => {
//   if (!val) {
//     return ""
//   } else {
//     if (val.toLowerCase().indexOf("female") > -1) {
//       val = "F"
//     }
//     if (val.toLowerCase().indexOf("male") > -1) {
//       val = "M"
//     }
//     if (val.toLowerCase().indexOf("transgender") > -1) {
//       val = "T"
//     }
//     return Genders.find(x => x.value === val).label
//   }
// }

export const PadZerosLeft = (num, size) => {
  var s = num + ""
  while (s.length < size) s = "0" + s
  return s
}

export const INVALID_CHARS = [
  `'`,
  `"`,
  `;`,
  `>`,
  `<`,
  `=`,
  `(`,
  `)`,
  `&`,
  `/`,
  `\\`,
]

export const DateFormat = date => {
  if (date) {
    let regex = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/
    if (!date.match(regex)) {
      return ""
    } else {
      var dateParts = date.split("/")
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
      // return (dateObject.getTime() / 1000)
      return dateObject.toISOString()
    }
  }
}

export const getDateTimeString = (d, time) => {
  //  var dateObj = new Date(parseInt(d * 1000));
  //var date = moment(d).unix()
  var date = parseInt(d)
  var obj = date * 1000
  var dateObj = new Date(parseInt(obj))
  var month = padValue(dateObj.getMonth() + 1)
  var day = padValue(dateObj.getDate())
  var year = padValue(dateObj.getFullYear())
  var hours = padValue(dateObj.getHours())
  var minutes = padValue(dateObj.getMinutes())
  var seconds = padValue(dateObj.getSeconds())
  var AMPM = "AM"

  var iHourCheck = parseInt(hours)

  if (iHourCheck > 12) {
    AMPM = "PM"
    hours = padValue(iHourCheck - 12)
  } else if (iHourCheck === 0) {
    hours = "00"
  }

  var newdate = ""
  if (time)
    newdate =
      day +
      "/" +
      month +
      "/" +
      year +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      " " +
      AMPM
  else newdate = day + "/" + month + "/" + year
  return newdate
}

export const unixdateconversion = (data, format) => {
  let currentTimestamp = Date.now()
  // console.log(currentTimestamp) // get current timestamp
  let date = new Intl.DateTimeFormat("en-IN", format).format(data)
  return date
}

function padValue(value) {
  return value < 10 ? "0" + value : value
}

export const GetPageRoute = pageId => {
  var convertString = pageId.toString()
  return allMenu.find(x => x.id === convertString)
    ? allMenu.find(x => x.id === convertString).to
    : ""
}

export const checkIfImage = url => {
  if (url) {
    if (typeof url === "string") {
      var value = url.split(".")
      var length = value.length
      if (
        value[length - 1].toLowerCase().includes("png") ||
        value[length - 1].toLowerCase().includes("jpg") ||
        value[length - 1].toLowerCase().includes("jpeg")
      )
        return true
      else return false
    } else return false
  } else return false
}

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult
  if (removedIndex === null && addedIndex === null) return arr

  const result = [...arr]
  let itemToAdd = payload

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0]
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd)
  }

  return result
}

export const generateItems = (count, creator) => {
  const result = []
  for (let i = 0; i < count; i++) {
    result.push(creator(i))
  }
  return result
}

export function getContrast(hexcolor) {
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1)
  }
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      .map(function (hex) {
        return hex + hex
      })
      .join("")
  }
  let r = parseInt(hexcolor.substr(0, 2), 16)
  let g = parseInt(hexcolor.substr(2, 2), 16)
  let b = parseInt(hexcolor.substr(4, 2), 16)
  let yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? "black" : "white"
}
