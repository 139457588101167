export const menu = [

  {
    id: "Master",
    icon: "bx bx-map-alt",
    label: "Master Settings",
    to: "/",
    subs: [
      {
        id: "cms-content",
        icon: "bx bx-bar-chart",
        label: "CMS Content",
        to: "/cms-content",
        badge: "no",
      },
      {
        id: "ordertype",
        icon: "bx bx-bar-chart",
        label: "Order Type",
        to: "/ordertype",
        badge: "no",
      },
      {
        id: "activitytype",
        icon: "bx bx-bar-chart",
        label: "Activity Type",
        to: "/activitytype",
        badge: "no",
      },
      {
        id: "state",
        icon: "bx bx-bar-chart",
        label: "State",
        to: "/state",
        badge: "no",
      },
      {
        id: "district",
        icon: "bx bx-bar-chart",
        label: "District",
        to: "/district",
        badge: "no",
      },
    ],
  },

  
  
  {
    id: "Master",
    icon: "bx bx-map-alt",
    label: "RUSA",
    to: "/",
    subs: [
      {
        id: "phase",
        icon: "bx bx-bar-chart",
        label: "Phase",
        to: "/phase",
        badge: "no",
      },
      {
        id: "component",
        icon: "bx bx-bar-chart",
        label: "Component",
        to: "/component",
        badge: "no",
      },
      {
        id: "component",
        icon: "bx bx-bar-chart",
        label: "Component Mapping",
        to: "/componentmapped",
        badge: "no",
      },
      {
        id: "pab",
        icon: "bx bx-bar-chart",
        label: "PAB",
        to: "/pab",
        badge: "no",
      },
      {
        id: "component-pab-education",
        icon: "bx bx-bar-chart",
        label: "Component PAB Education",
        to: "/component-pab-education",
        badge: "no",
      },
      {
        id: "pab-cancelled",
        icon: "bx bx-bar-chart",
        label: "PAB Cancelled",
        to: "/pab-cancelled",
        badge: "no",
      },
      {
        id: "team",
        icon: "bx bx-bar-chart",
        label: "Team",
        to: "/team",
        badge: "no",
      },

      {
        id: "educational-institution",
        icon: "bx bx-bar-chart",
        label: "Educational Institution",
        to: "/educational-institution",
        badge: "no",
      },
    ],
  },
  {
    id: "Master",
    icon: "bx bx-map-alt",
    label: "Home Page",
    to: "/",
    subs: [
      {
        id: "Achievement",
        icon: "bx bx-bar-chart",
        label: "Achievement",
        to: "/achievement",
        badge: "no",
      },
      {
        id: "allied-department",
        icon: "bx bx-bar-chart",
        label: "Allied Department",
        to: "/allied-department",
        badge: "no",
      },
      {
        id: "slider",
        icon: "bx bx-bar-chart",
        label: "Slider",
        to: "/slider",
        badge: "no",
      },
      {
        id: "testimonials",
        icon: "bx bx-bar-chart",
        label: "Testimonial",
        to: "/testimonial",
        badge: "no",
      },
    ],
  },
  {
    id: "Master",
    icon: "bx bx-map-alt",
    label: "Activity",
    to: "/",
    subs: [
      
      {
        id: "activity",
        icon: "bx bx-bar-chart",
        label: "Activity",
        to: "/activity",
        badge: "no",
      },
    ],
  },
  
  {
    id: "Master",
    icon: "bx bx-map-alt",
    label: "Others",
    to: "/",
    subs: [
      {
        id: "faq",
        icon: "bx bx-bar-chart",
        label: "Faq",
        to: "/faq",
        badge: "no",
      },
      {
        id: "sanction-order",
        icon: "bx bx-bar-chart",
        label: "Sanction Order",
        to: "/sanction-order",
        badge: "no",
      },
    ],
  },
  {
    id: "Master",
    icon: "bx bx-map-alt",
    label: "NAAC Accreditation",
    to: "/",
    subs: [
      {
        id: "naac-accredation",
        icon: "bx bx-bar-chart",
        label: "NAAC Accreditation Institutions",
        to: "/naac-accredation",
        badge: "no",
      },
      {
        id: "naac-accredation-financial",
        icon: "bx bx-bar-chart",
        label: "Financial Assistance Colleges",
        to: "/naac-accredation-financial",
        badge: "no",
      },
    ],
  },
  {
    id: "Master",
    icon: "bx bx-map-alt",
    label: "Gallery",
    to: "/",
    subs: [
      {
        id: "gallery-album",
        icon: "bx bx-bar-chart",
        label: "Album",
        to: "/gallery-album",
        badge: "no",
      },
      {
        id: "gallery-photo",
        icon: "bx bx-bar-chart",
        label: "Photo",
        to: "/gallery-photo",
        badge: "no",
      },

      {
        id: "gallery-video",
        icon: "bx bx-bar-chart",
        label: "Video",
        to: "/gallery-video",
        badge: "no",
      },
    ],
  },
]

export const allMenu = []
