import { gql } from "@apollo/client"

export const getAllOrderTypesQuery = gql`
  query getAllOrderTypes(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllOrderTypes(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        name
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteOrderTypeMutation = gql`
  mutation deleteOrderType($id: Int!) {
    deleteOneOrderType(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneOrderTypeQuery = gql`
  query getOrderType($id: Int!) {
    getOneOrderType(id: $id) {
      id
      name
    }
  }
`
export const createOrderTypeMutation = gql`
  mutation createOneOrderType($data: orderTypeInput) {
    createOneOrderType(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updateOrderTypeMutation = gql`
  mutation updateOrderType($id: Int!, $data: orderTypeInput) {
    updateOneOrderType(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
