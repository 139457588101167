import { ApolloClient, InMemoryCache, gql } from "@apollo/client"
import { createHttpLink } from "apollo-link-http"
import { setContext } from "apollo-link-context"
import { ErrorMessage } from "./notifications"
import { clearValuesOnLogout, getItemFromLocalStorage } from "../helpers/utils"

export const BASE_URL = "https://tnrusa.in/api";

const httpLink = createHttpLink({
  uri: BASE_URL,
})
const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      token: getItemFromLocalStorage("user_token")
        ? getItemFromLocalStorage("user_token")
        : "",
    },
  }
})
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
}
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
})
export const QueryRequest = async (query, variables) => {
  const response = await client.query({
    query,
    variables,
  })
  if (
    response.errors &&
    response.errors.length > 0 &&
    response.errors[0].message === "Not authorized"
  ) {
    ErrorMessage("", "Your session expired. Please login again")
    clearValuesOnLogout()
    window.location.reload()
  }
  return response
}
export const MutateRequest = async (mutation, variables) => {
  const response = await client.mutate({
    mutation,
    variables,
  })
  if (
    response.errors &&
    response.errors.length > 0 &&
    response.errors[0].message === "Not authorized"
  ) {
    ErrorMessage("", "Your session expired. Please login again")
    clearValuesOnLogout()
    window.location.reload()
  }
  return response
}
