import React, { Component } from "react"
import PropTypes from "prop-types"
import { Col, Container, Row, FormGroup, Label, Button } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import LoginCarousel from "../../components/Common/LoginCarousel"

// import images
import logo from "../../assets/images/logo.png"
import { Formik, Form, Field } from "formik"

import { loginValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import { GetPageRoute } from "../../helpers/utils"
import footerlogo from "../../assets/images/logo_vertace.png"

import { setItemOnLocalStorage } from "../../helpers/utils"
import { QueryRequest } from "../../helpers/service_helper"
import { LoginMutation } from "./Queries"
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonAction: false,
      setPasswordShown: false,
      LoginRequestModel: {
        userName: "",
        password: "",
      },
    }
  }

  componentDidMount() {}

  AuthenticateLogin = async values => {
    setItemOnLocalStorage("user_id", "1222")
    setItemOnLocalStorage("user_name", values.mobileNumber)
    // this.props.history.push("/achievement")

    this.setState({
      buttonAction: true,
    })

    if (values) {
      const response = await QueryRequest(LoginMutation, values)
      var feed = [
        {
          id: 7,
          Name: "Feedback Section",
        },
      ]
      this.setState({
        buttonAction: false,
      })
      debugger
      if (response.data) {
        setItemOnLocalStorage("user_id", response.data.login.userId)
        setItemOnLocalStorage("user_token", response.data.login.token)
        setItemOnLocalStorage("user_name", response.data.login.userName)
        // this.props.history.push("/achievement")
        if (response.data.forcePasswordChange) {
          this.props.history.push("/forcepasswordchange")
        } else {
          if (response.data.login.pageAccess.length === 0) {
            setItemOnLocalStorage("PageAccess", JSON.stringify(feed))

            if (feed && feed.length > 0) {
              this.props.history.push(GetPageRoute(feed[0].id))
              window.location.reload()
            }
          } else {
            setItemOnLocalStorage(
              "PageAccess",
              JSON.stringify(response.data.login.pageAccess)
            )

            setItemOnLocalStorage(
              "PageComponentAccess",
              JSON.stringify(response.data.login.pageComponentAccess)
            )
          }
          if (
            response.data.login.pageAccess &&
            response.data.login.pageAccess.length > 0
          ) {
            this.props.history.push("/achievement")
            window.location.reload()
          }
        }
      }
    } else {
      this.props.history.push("/login")
    }
  }
  togglePasswordVisiblity = () => {
    this.setState({ setPasswordShown: !this.state.setPasswordShown })
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <LoginCarousel />
              <Col xl={4}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-md-5">
                        <Link to="#" className="d-block auth-logo">
                          <img
                            src={logo}
                            alt=""
                            height="100"
                            className="auth-logo-dark m-auto"
                          />
                        </Link>
                        <h4 className="text-center">RUSA</h4>
                      </div>
                      <div className="my-auto mb-5">
                        <div className="mt-0">
                          <Formik
                            initialValues={this.state.LoginRequestModel}
                            validationSchema={loginValidation}
                            onSubmit={this.AuthenticateLogin}
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize={true}
                          >
                            {({ errors, values }) => (
                              <Form>
                                <FormGroup>
                                  <h4>Login</h4>
                                  <Label for="userName" className="mt-1">
                                    Username
                                  </Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="userName"
                                    placeholder={Message(
                                      "mobileNumber.placeholder"
                                    )}
                                  />
                                  {errors.userName && (
                                    <div className="invalid-feedback d-block">
                                      {errors.userName}
                                    </div>
                                  )}
                                </FormGroup>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="userpassword">Password</Label>
                                      <Field
                                        type={
                                          this.state.setPasswordShown
                                            ? "text"
                                            : "password"
                                        }
                                        name="password"
                                        className="form-control"
                                        placeholder={Message(
                                          "password.placeholder"
                                        )}
                                      />
                                      {errors.Password && (
                                        <div className="invalid-feedback d-block">
                                          {errors.Password}
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <div className="mt-3">
                                  <Button
                                    color="primary"
                                    type="submit"
                                    block
                                    className={`btn-shadow btn-multiple-state ${
                                      this.state.buttonAction
                                        ? "show-spinner "
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    <span className="spinner d-inline-block">
                                      <span className="bounce1" />
                                      <span className="bounce2" />
                                      <span className="bounce3" />
                                    </span>
                                    <span className="label ">Login</span>
                                  </Button>
                                </div>
                                <Row className="mt-1 ml-1">
                                  <Link to="/forgot-password">
                                    Forgot Password ?
                                  </Link>
                                </Row>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                    <div className="text-sm-center ml-4 pl-5">
                      Powered by{" "}
                      <a
                        style={{
                          cursor: "pointer",
                          color: "#007bff",
                          textDecoration: "underline",
                        }}
                        href="https://vertace.com/"
                        target="blank"
                      >
                        <img
                          alt="vertace"
                          src={footerlogo}
                          style={{ width: "25px", objectFit: "contain" }}
                        />
                        Vertace
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  t: PropTypes.any,
}

export default withRouter(Login)
