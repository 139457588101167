import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
  Media,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ListPageHeader from "../../components/custom/ListPageHeader"
import { MethodType, StatusCodes } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { OrderTypeValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import DropDown from "../../components/custom/DropDown"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CustomSelectInput from "../../components/Common/CustomSelectInput"
import { getDateTimeString, unixdateconversion } from "../../helpers/utils"
import { Tabs, Tab } from "react-bootstrap"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import Table from "react-bootstrap/Table"
import {
  createOrderTypeMutation,
  updateOrderTypeMutation,
  deleteOrderTypeMutation,
  getAllOrderTypesQuery,
  getOneOrderTypeQuery,
} from "./Queries"
import moment from "moment"
import MetaTags from "react-meta-tags"

class OrderType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      orderTypeList: [],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 10,
      },
      orderType: {
        isDeleted: "",
        name: "",
      },
      columns: [
        {
          name: "Name",
          selector: "name",
          sortable: true,
          cell: row => <span>{row.name ? row.name : ""}</span>,
        },
        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: row => (
            <Row>
              <ButtonGroup className="mb-2" style={{ top: "4px" }}>
                <Button
                  outline
                  color="primary"
                  className="mobileViewFont ml-2"
                  onClick={() => this.handleEdit(row)}
                >
                  Edit
                </Button>

                <Button
                  outline
                  color="danger"
                  className="mobileViewFonts pl-1 pr-1 ml-2"
                  onClick={() => this.toggleDeleteModal(row)}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Row>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    this.getAllOrderTypes()
  }
  getAllOrderTypes = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllOrderTypesQuery, values)
    var orderTypesValue = response?.data?.getAllOrderTypes?.items
    if (orderTypesValue) {
      this.setState({
        isLoading: false,
        orderTypeList: orderTypesValue,
        totalPage:
          response?.data?.getAllOrderTypes?.pagination?.totalCount /
          response?.data?.getAllOrderTypes?.pagination?.pageLimit,
        totalCount: response?.data?.getAllOrderTypes?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllOrderTypes()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllOrderTypes()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllOrderTypes()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllOrderTypes()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        orderType: row,
        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })
    var AddData = {
      name: values.name,
    }
    var EditVal = {
      id: values.id,
      data: {
        name: values.name,
      },
    }
    const response = values.id
      ? await MutateRequest(updateOrderTypeMutation, EditVal)
          .then(
            r =>
              this.setState({
                // manageEditModal: !this.state.manageEditModal,
                buttonAction: false,
              }),
            SuccessMessage(Message("OrderType.update"))
          )
          .catch(e => ErrorMessage(e))
      : await MutateRequest(createOrderTypeMutation, { data: AddData })
          .then(r => {
            this.setState({
              // manageEditModal: !this.state.manageEditModal,
              buttonAction: false,
            })
            SuccessMessage(Message("OrderType.add"))
          })
          .catch(e => ErrorMessage(e))
    var orderTypeValue = response?.data
    if (orderTypeValue && values.id) {
      // SuccessMessage(Message("orderType.update")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllOrderTypes()
    } else {
      // SuccessMessage(Message("orderType.add")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllOrderTypes()
    }
  }

  addBtnClick = () => {
    this.setState({
      orderType: {
        isDeleted: "",
        name: "",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      orderType: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      orderType: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      orderType: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteOrderType = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deleteOrderTypeMutation, values)
    var orderTypedeleteValue = response?.data
    if (orderTypedeleteValue.deleteOneOrderType.status === "Success") {
      SuccessMessage(Message("orderType.delete"))
      this.getAllOrderTypes()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageOrderTypeResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.orderType.id
        ? SuccessMessage(Message("OrderType.update"))
        : SuccessMessage(Message("OrderType.add"))

      this.getAllOrderTypes()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      orderTypeDetail: url ? url : "",
    })
  }

  handleOnDragEnd = result => {
    if (!result.destination) return

    const items = Array.from(this.state.orderTypeList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    this.setState({ data: items })
    const res = []
    items.map(u => {
      res.push(u.id)
    })
    console.log(res)
  }
  render() {
    const initialValues = this.state.orderType
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Order Types"}`}
                    buttonText={Message("add.OrderType")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                <Row></Row>
                <div className="PoliceView">
                  <ListPage
                    conditionalRowStyles={conditionalRowStyles}
                    columns={this.state.columns}
                    data={this.state.orderTypeList}
                    keyField={this.state.keyField}
                    totalCount={this.state.totalCount}
                    rowClicked={this.HandleRowClicked}
                    onSort={this.handleSort}
                    rowsPerPageOnChange={this.handlePerRowsChange}
                    pageChange={this.handlePageChange}
                    isDataLoading={this.state.isLoading}
                    overFlowXRemoval={true}
                  />
                </div>
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.orderType")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        OrderType " {""}
                        {this.state.orderType
                          ? this.state.orderType.name
                          : ""}{" "}
                        " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() =>
                      this.deleteOrderType(this.state.orderType.id)
                    }
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="md"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.OrderType")
                  : Message("add.OrderType")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={OrderTypeValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">{Message("name")}</Label>
                              <Field
                                className="form-control"
                                name="name"
                                type="text"
                              />
                              {errors.name && (
                                <div className="invalid-feedback d-block">
                                  {errors.name}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction ? "show-spinner " : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(OrderType)
