import { gql } from "@apollo/client"

export const getAllCMSContentsQuery = gql`
  query getAllCMSContents(
     $searchString : String
     $pageLimit : Float
     $pageNumber : Float
     
  ) {
    getAllCMSContents(
     searchString : $searchString
     pageLimit : $pageLimit
     pageNumber : $pageNumber
     
    ) {
      items {
        id
        name
        contentImage
        contentDescription
        contentTitle
        isActive
        content
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteCMSContentMutation = gql`
  mutation deleteCMSContent($id: Int!) {
    deleteOneCMSContent(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneCMSContentQuery = gql`
  query getCMSContent($id: Int!) {
    getOneCMSContent(id: $id) {
      id
      name
 contentImage
 contentDescription
 contentTitle
 isActive
    }
  }
`
export const createCMSContentMutation = gql`
  mutation createCMSContent($data: cmsContentInput) {
    createOneCMSContent(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`

export const fileuploadQuery = gql`
  query getUploadUrl($type: String!, $fileName: String!) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`

export const updateCMSContentMutation = gql`
  mutation updateCMSContent($id: Int!,$data: cmsContentInput) {
    updateOneCMSContent(id: $id,data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`


