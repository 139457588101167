import { gql } from "@apollo/client"

export const getAllAlliedDepartmentsQuery = gql`
  query getAllAlliedDepartments(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllAlliedDepartments(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        link
        departmentImage
        name
        isActive
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteAlliedDepartmentMutation = gql`
  mutation deleteAlliedDepartment($id: Int!) {
    deleteOneAlliedDepartment(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneAlliedDepartmentQuery = gql`
  query getAlliedDepartment($id: Int!) {
    getOneAlliedDepartment(id: $id) {
      id
      link
      departmentImage
      isActive
    }
  }
`
export const createAlliedDepartmentMutation = gql`
  mutation createAlliedDepartment($data: alliedDepartmentInput) {
    createOneAlliedDepartment(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateAlliedDepartmentMutation = gql`
  mutation updateAlliedDepartment($id: Int!, $data: alliedDepartmentInput) {
    updateOneAlliedDepartment(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const fileuploadQuery = gql`
  query getUploadUrl($type: String!, $fileName: String!) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`
export const UpdateManyMutation = gql`
  mutation updateManyAlliedDepartments($data: [alliedDepartmentInput]) {
    updateManyAlliedDepartments(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
