import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
  Media,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ListPageHeader from "../../components/custom/ListPageHeader"
import {
  MethodType,
  StatusCodes,
  instituteType,
} from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { EducationalInstitutionValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import DropDown from "../../components/custom/DropDown"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CustomSelectInput from "../../components/Common/CustomSelectInput"
import { getDateTimeString, unixdateconversion } from "../../helpers/utils"
import { Tabs, Tab } from "react-bootstrap"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import Table from "react-bootstrap/Table"
import {
  createEducationalInstitutionMutation,
  updateEducationalInstitutionMutation,
  deleteEducationalInstitutionMutation,
  getAllEducationalInstitutionsQuery,
  getOneEducationalInstitutionQuery,
  getAllDistrictsQuery,
} from "./Queries"
import moment from "moment"
import MetaTags from "react-meta-tags"

class EducationalInstitution extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      educationalInstitutionList: [],
      districtList: [],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 10,
      },
      educationalInstitution: {
        isDeleted: "",
        name: "",
        instituteType: "",
        address: "",
        districtId: "",
      },
      columns: [
        {
          name: "Name",
          selector: "name",
          sortable: true,
          cell: row => <span>{row.name ? row.name : ""}</span>,
        },
        {
          name: "InstitutionType",
          selector: "instituteType",
          sortable: true,
          cell: row => (
            <span>{row.instituteType ? row.instituteType : ""}</span>
          ),
        },
        {
          name: "Address",
          selector: "address",
          sortable: true,
          cell: row => <span>{row.address ? row.address : ""}</span>,
        },
        {
          name: "District",
          selector: "district",
          sortable: true,
          cell: row => (
            <span>{row.district?.name ? row.district?.name : ""}</span>
          ),
        },
        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: row => (
            <Row>
              <ButtonGroup className="mb-2" style={{ top: "4px" }}>
                <Button
                  outline
                  color="primary"
                  className="mobileViewFont ml-2"
                  onClick={() => this.handleEdit(row)}
                >
                  Edit
                </Button>

                <Button
                  outline
                  color="danger"
                  className="mobileViewFonts pl-1 pr-1 ml-2"
                  onClick={() => this.toggleDeleteModal(row)}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Row>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    this.getAllEducationalInstitutions()
    this.getAllDistricts()
  }
  getAllEducationalInstitutions = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(
      getAllEducationalInstitutionsQuery,
      values
    )
    console.log(response)
    var educationalInstitutionsValue =
      response?.data?.getAllEducationalInstitutes?.items

    if (educationalInstitutionsValue) {
      this.setState({
        isLoading: false,
        educationalInstitutionList: educationalInstitutionsValue,
        totalPage:
          response?.data?.getAllEducationalInstitutes?.pagination?.totalCount /
          response?.data?.getAllEducationalInstitutes?.pagination?.pageLimit,
        totalCount:
          response?.data?.getAllEducationalInstitutes?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  getAllDistricts = async value => {
    const response = await QueryRequest(getAllDistrictsQuery, "")
    if (response?.data?.getAllDistricts?.items) {
      this.setState({
        districtList: response.data.getAllDistricts.items.map(function (a) {
          return { label: a.name, value: a.id }
        }),
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllEducationalInstitutions()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllEducationalInstitutions()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllEducationalInstitutions()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllEducationalInstitutions()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        educationalInstitution: row,
        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })
    var AddData = {
      name: values.name,
      instituteType: values.instituteType,
      address: values.address,
      districtId: values.districtId,
    }
    var EditVal = {
      id: values.id,
      data: {
        name: values.name,
        instituteType: values.instituteType,
        address: values.address,
      },
    }
    const response = values.id
      ? await MutateRequest(updateEducationalInstitutionMutation, EditVal)
          .then(
            r =>
              this.setState({
                // manageEditModal: !this.state.manageEditModal,
                buttonAction: false,
              }),
            SuccessMessage(Message("EducationalInstitution.update"))
          )
          .catch(e => ErrorMessage(e))
      : await MutateRequest(createEducationalInstitutionMutation, {
          data: AddData,
        })
          .then(r => {
            this.setState({
              buttonAction: false,
              // manageEditModal: !this.state.manageEditModal,
            })
            SuccessMessage(Message("EducationalInstitution.add"))
          })
          .catch(e => ErrorMessage(e))
    var educationalInstitutionValue = response?.data
    if (educationalInstitutionValue && values.id) {
      // SuccessMessage(Message("educationalInstitution.update")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllEducationalInstitutions()
    } else {
      // SuccessMessage(Message("educationalInstitution.add")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllEducationalInstitutions()
    }
  }

  addBtnClick = () => {
    this.setState({
      educationalInstitution: {
        isDeleted: "",
        name: "",
        instituteType: "",
        address: "",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      educationalInstitution: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      educationalInstitution: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      educationalInstitution: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteEducationalInstitution = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(
      deleteEducationalInstitutionMutation,
      values
    )
    var educationalInstitutiondeleteValue = response?.data
    if (
      educationalInstitutiondeleteValue.deleteOneEducationalInstitute.status ===
      "Success"
    ) {
      SuccessMessage(Message("EducationalInstitution.delete"))
      this.getAllEducationalInstitutions()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageEducationalInstitutionResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.educationalInstitution.id
        ? SuccessMessage(Message("EducationalInstitution.update"))
        : SuccessMessage(Message("EducationalInstitution.add"))

      this.getAllEducationalInstitutions()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      educationalInstitutionDetail: url ? url : "",
    })
  }

  handleOnDragEnd = result => {
    if (!result.destination) return

    const items = Array.from(this.state.educationalInstitutionList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    this.setState({ data: items })
    const res = []
    items.map(u => {
      res.push(u.id)
    })
    console.log(res)
  }
  render() {
    const initialValues = this.state.educationalInstitution
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Educational Institution</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Educational Institutions"}`}
                    buttonText={Message("add.EducationalInstitution")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                <Row>
                  <Col sm="12" md="6" lg="3">
                    {" "}
                    <DropDown
                      label={Message("District")}
                      MobcolSplit={12}
                      isClearable={true}
                      isSearchable
                      options={this.state.districtList}
                      ClearAction={() => {
                        this.setState(
                          {
                            listRequestModel: {
                              ...this.state.listRequestModel,
                              statusId: null,
                            },
                          },
                          () => this.getAllEducationalInstitutions()
                        )
                      }}
                      Action={entity => {
                        if (entity) {
                          this.setState(
                            {
                              listRequestModel: {
                                ...this.state.listRequestModel,
                                districtId: entity.value,
                              },
                            },
                            () => this.getAllEducationalInstitutions()
                          )
                        } else {
                          this.setState(
                            {
                              listRequestModel: {
                                ...this.state.listRequestModel,
                                districtId: null,
                              },
                            },
                            () => this.getAllEducationalInstitutions()
                          )
                        }
                      }}
                    />
                  </Col>
                </Row>
                <div className="PoliceView">
                  <ListPage
                    conditionalRowStyles={conditionalRowStyles}
                    columns={this.state.columns}
                    data={this.state.educationalInstitutionList}
                    keyField={this.state.keyField}
                    totalCount={this.state.totalCount}
                    rowClicked={this.HandleRowClicked}
                    onSort={this.handleSort}
                    rowsPerPageOnChange={this.handlePerRowsChange}
                    pageChange={this.handlePageChange}
                    isDataLoading={this.state.isLoading}
                    overFlowXRemoval={true}
                  />
                </div>
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.educationalInstitution")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        EducationalInstitution " {""}
                        {this.state.educationalInstitution
                          ? this.state.educationalInstitution.name
                          : ""}{" "}
                        " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() =>
                      this.deleteEducationalInstitution(
                        this.state.educationalInstitution.id
                      )
                    }
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="lg"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.EducationalInstitution")
                  : Message("add.EducationalInstitution")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={EducationalInstitutionValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          <Col lg="6" sm="12" md="12" lg="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">
                                {Message("name")}
                              </Label>
                              <Field
                                className="form-control"
                                name="name"
                                type="text"
                              />
                              {errors.name && (
                                <div className="invalid-feedback d-block">
                                  {errors.name}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <DropDown
                                label={Message("InstituteType")}
                                MobcolSplit={12}
                                isClearable={true}
                                labelClassName="requiredField"
                                isSearchable
                                options={instituteType}
                                isDisabled={this.state.Label == "Edit"}
                                value={
                                  values &&
                                  values.instituteType &&
                                  (typeof values.instituteType === "string"
                                    ? instituteType.find(
                                        r => r.label === values.instituteType
                                      )
                                    : instituteType.find(
                                        r => r.label === values.instituteType
                                      ))
                                }
                                ClearAction={() => {
                                  setFieldValue(`instituteType`, "")
                                }}
                                Action={entity => {
                                  if (entity) {
                                    setFieldValue("instituteType", entity.value)
                                  } else {
                                    setFieldValue("instituteType", "")
                                  }
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="12" md="12" lg="6">
                            <FormGroup className="form-group has-float-label">
                              <DropDown
                                label={Message("district")}
                                components={{
                                  Input: CustomSelectInput,
                                }}
                                labelClassName="requiredField"
                                className="react-select"
                                classNamePrefix="react-select"
                                isSearchable
                                isClearable={true}
                                value={
                                  values.districtId &&
                                  (typeof values.districtId === "string"
                                    ? this.state.districtList.find(
                                        r => r.value === values.districtId
                                      )
                                    : this.state.districtList.find(
                                        r => r.value === values.districtId
                                      ))
                                }
                                options={this.state.districtList}
                                ClearAction={() => {
                                  setFieldValue(`districtId`, ""),
                                    this.setState(
                                      {
                                        district: null,
                                      },
                                      () => this.getAllDistricts()
                                    )
                                }}
                                Action={entity => {
                                  if (entity) {
                                    this.setState(
                                      {
                                        district: entity ? entity.value : "",
                                      },
                                      () => this.getAllDistricts()
                                    )
                                    setFieldValue("districtId", entity.value)
                                  } else {
                                    this.setState(
                                      {
                                        district: "",
                                      },
                                      () => this.getAllDistricts()
                                    )
                                    setFieldValue("districtId", "")
                                  }
                                }}
                                errors={errors?.districtId}
                              />
                            </FormGroup>{" "}
                          </Col>
                          <Col lg="6" sm="12" md="12" lg="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("address")}</Label>
                              <Field
                                className="form-control"
                                name="address"
                                type="text"
                              />
                              {errors.address && (
                                <div className="invalid-feedback d-block">
                                  {errors.address}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction ? "show-spinner " : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(EducationalInstitution)
