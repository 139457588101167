import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
import logo1 from "../../../src/assets/images/RUSA.jpg"
import logo2 from "../../../src/assets/images/chennai_iit.jpeg"

export default class LoginCarousel extends Component {
  render() {
    return (
      <React.Fragment>
        <Col xl={8}>
          <div className="auth-full-bg pt-lg-5 p-4">
            <div className="w-100">
              <div className="bg-overlay"></div>
              <div className="d-flex h-100 flex-column">
                <div className="p-4 mt-auto">
                  <div className="row justify-content-center">
                    <div>
                      <Carousel showThumbs={false} className="slider_css">
                        <div >
                          <img src={logo1} alt="" className="CarouselImg" />
                        </div>
                        <div >
                          <img src={logo2} className="CarouselImg" alt="" />
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    )
  }
}
