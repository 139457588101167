import React from "react"
import { Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © RUSA</Col>
            <Col sm={6}>
              {/* <div className="text-sm-right d-none d-sm-block">
                Powered by{" "}
                <a
                  style={{
                    cursor: "pointer",
                    color: "#007bff",
                    textDecoration: "underline",
                  }}
                  href="https://vertace.com/"
                  target="blank"
                >
                  <img
                    alt="vertace"
                    src={footerlogo}
                    style={{ width: "25px", objectFit: "contain" }}
                  />
                  Vertace
                </a>
              </div> */}
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
