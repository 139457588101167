import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
  Media,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import FileDisplay from "components/custom/FileDisplay"
import axios from "axios"
import ListPageHeader from "../../components/custom/ListPageHeader"
import { MethodType, StatusCodes } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { TeamValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import DropDown from "../../components/custom/DropDown"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CustomSelectInput from "../../components/Common/CustomSelectInput"
import { getDateTimeString, unixdateconversion } from "../../helpers/utils"
import { Tabs, Tab } from "react-bootstrap"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import Table from "react-bootstrap/Table"
import {
  createTeamMutation,
  updateTeamMutation,
  deleteTeamMutation,
  getAllTeamsQuery,
  getOneTeamQuery,
  fileuploadQuery,
  UpdateManyMutation,
} from "./Queries"
import moment from "moment"
import MetaTags from "react-meta-tags"

class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      teamList: [],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 0,
      },
      team: {
        isDeleted: false,
        rank: "",
        designation: "",
        name: "",
        isActive: false,
        personImage: "",
      },
    }
  }

  componentDidMount() {
    this.getAllTeams()
  }
  getAllTeams = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllTeamsQuery, values)
    var teamsValue = response?.data?.getAllTeams?.items
    if (teamsValue) {
      this.setState({
        isLoading: false,
        teamList: teamsValue,
        totalPage:
          response?.data?.getAllTeams?.pagination?.totalCount /
          response?.data?.getAllTeams?.pagination?.pageLimit,
        totalCount: response?.data?.getAllTeams?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllTeams()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllTeams()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllTeams()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllTeams()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        team: row,
        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })

    if (typeof values.personImage === "object") {
      let fileupload = {
        type: "photo",
        fileName: values.personImage?.name,
      }
      const fileUploadresponse = await QueryRequest(fileuploadQuery, fileupload)
      let data = fileUploadresponse?.data?.getUploadUrl
      console.log(data)
      if (data.status === "Success") {
        axios
          .put(data.url, values.personImage, {
            headers: { "Content-Type": values.personImage?.type },
          })
          .then(async res => {
            if (res.status.toString() === StatusCodes.Success) {
              var AddData = {
                rank: values.rank,
                designation: values.designation,
                name: values.name,
                isActive: values.isActive,
                personImage: data.url.split("?")[0],
                sortId: 1,
              }
              var EditVal = {
                id: values.id,
                data: {
                  rank: values.rank,
                  designation: values.designation,
                  name: values.name,
                  isActive: values.isActive,
                  personImage: data.url.split("?")[0],
                  sortId: 1,
                },
              }
              const response = values.id
                ? await MutateRequest(updateTeamMutation, EditVal)
                    .then(
                      r =>
                        this.setState({
                          // manageEditModal: !this.state.manageEditModal,
                          buttonAction: false,
                        }),
                      SuccessMessage(Message("Team.update"))
                    )
                    .catch(e => ErrorMessage(e))
                : await MutateRequest(createTeamMutation, { data: AddData })
                    .then(r => {
                      this.setState({
                        // manageEditModal: !this.state.manageEditModal,
                        buttonAction: false,
                      })
                      SuccessMessage(Message("Team.add"))
                    })
                    .catch(e => ErrorMessage(e))
              var teamValue = response?.data
              if (teamValue && values.id) {
                // SuccessMessage(Message("team.update")),
                this.setState({
                  manageModal: !this.state.manageModal,
                })
                this.getAllTeams()
              } else {
                // SuccessMessage(Message("team.add")),
                this.setState({
                  manageModal: !this.state.manageModal,
                })
                this.getAllTeams()
              }
            } else {
              ErrorMessage(Message("fieldUpload.error"))
            }
          })
          .catch(err => alert("Error"))
      } else {
      }
    } else {
      var AddData = {
        rank: values.rank,
        designation: values.designation,
        name: values.name,
        isActive: values.isActive,
        personImage: values.personImage,
        sortId: 1,
      }
      var EditVal = {
        id: values.id,
        data: {
          rank: values.rank,
          designation: values.designation,
          name: values.name,
          isActive: values.isActive,
          personImage: values.personImage,
          sortId: 1,
        },
      }
      const response = values.id
        ? await MutateRequest(updateTeamMutation, EditVal)
            .then(
              r =>
                this.setState({
                  // manageEditModal: !this.state.manageEditModal,
                  buttonAction: false,
                }),
              SuccessMessage(Message("Team.update"))
            )
            .catch(e => ErrorMessage(e))
        : await MutateRequest(createTeamMutation, { data: AddData })
            .then(r => {
              this.setState({
                // manageEditModal: !this.state.manageEditModal,
                buttonAction: false,
              })
              SuccessMessage(Message("Team.add"))
            })
            .catch(e => ErrorMessage(e))
      var teamValue = response?.data
      if (teamValue && values.id) {
        // SuccessMessage(Message("team.update")),
        this.setState({
          manageModal: !this.state.manageModal,
        })
        this.getAllTeams()
      } else {
        // SuccessMessage(Message("team.add")),
        this.setState({
          manageModal: !this.state.manageModal,
        })
        this.getAllTeams()
      }
    }
  }

  addBtnClick = () => {
    this.setState({
      team: {
        isDeleted: false,
        rank: "",
        designation: "",
        name: "",
        isActive: false,
        personImage: "",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      team: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      team: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      team: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteTeam = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deleteTeamMutation, values)
    var teamdeleteValue = response?.data
    if (teamdeleteValue.deleteOneTeam.status === "Success") {
      SuccessMessage(Message("team.delete"))
      this.getAllTeams()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageTeamResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.team.id
        ? SuccessMessage(Message("Team.update"))
        : SuccessMessage(Message("Team.add"))

      this.getAllTeams()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      teamDetail: url ? url : "",
    })
  }

  handleOnDragEnd = async result => {
    if (!result.destination) return

    this.setState({ isLoading: true })
    const items = Array.from(this.state.teamList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    this.setState({ data: items })
    const res = []
    items.map((u, i) => {
      res.push({
        id: u.id,
        sortId: i + 1,
        rank: u.rank,
        designation: u.designation,
        name: u.name,
        personImage: u.personImage,
        isActive: u.isActive,
      })
    })
    console.log({ data: res })
    const response = await MutateRequest(UpdateManyMutation, {
      data: res,
    })
    console.log(response)
    if (response?.data?.updateManyTeams.status === "Success") {
      SuccessMessage("Updated Successfully")
      this.getAllTeams()
    } else {
      ErrorMessage("Error")
    }
  }
  render() {
    const initialValues = this.state.team
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Achievements</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Teams"}`}
                    buttonText={Message("add.Team")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                {this.state.isLoading ? (
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="spinner-border text-primary m-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </Row>
                ) : (
                  <div className="PoliceView">
                    <Table stripped hover size="sm">
                      <thead>
                        <tr
                        >
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Name
                          </th>
                          
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Designation
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Rank
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Image
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Is Active
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              paddingBottom: "15px",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <DragDropContext
                        onDragEnd={result => this.handleOnDragEnd(result)}
                      >
                        <Droppable droppableId="characters">
                          {provided => (
                            <tbody
                              className="characters"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {this.state.teamList.map((row, i) => {
                                return (
                                  <Draggable
                                    key={row.id}
                                    draggableId={row.id.toString()}
                                    index={i}
                                  >
                                    {provided => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.name}
                                        </td>{" "}
                                        
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.designation}
                                        </td>{" "}
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.rank}
                                        </td>
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.personImage ? (
                                            <FileDisplay
                                              Value={row.personImage}
                                            />
                                          ) : (
                                            "-"
                                          )}
                                        </td>{" "}
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.isActive
                                            ? "Active"
                                            : "Not Active"}
                                        </td>{" "}
                                       
                                        <td
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Row>
                                            <ButtonGroup
                                              className="mb-2"
                                              style={{ top: "4px" }}
                                            >
                                              <Button
                                                outline
                                                color="primary"
                                                className="mobileViewFont ml-2"
                                                onClick={() =>
                                                  this.handleEdit(row)
                                                }
                                              >
                                                Edit
                                              </Button>

                                              <Button
                                                outline
                                                color="danger"
                                                className="mobileViewFonts pl-1 pr-1 ml-2"
                                                onClick={() =>
                                                  this.toggleDeleteModal(row)
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </ButtonGroup>
                                          </Row>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Table>
                  </div>
                )}
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.team")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        Team " {""}
                        {this.state.team ? this.state.team.name : ""} " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() => this.deleteTeam(this.state.team.id)}
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="lg"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.Team")
                  : Message("add.Team")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={TeamValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">{Message("name")}</Label>
                              <Field
                                className="form-control"
                                name="name"
                                type="text"
                              />
                              {errors.name && (
                                <div className="invalid-feedback d-block">
                                  {errors.name}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">
                                {Message("designation")}
                              </Label>
                              <Field
                                className="form-control"
                                name="designation"
                                type="text"
                              />
                              {errors.designation && (
                                <div className="invalid-feedback d-block">
                                  {errors.designation}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("Rank")}</Label>
                              <Field
                                className="form-control"
                                name="rank"
                                type="number"
                              />
                              {errors.rank && (
                                <div className="invalid-feedback d-block">
                                  {errors.rank}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          

                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("active")}</Label>
                              <FormGroup className="custom-control custom-switch mt-1 ml-2">
                                <Input
                                  autoComplete="off"
                                  type="checkbox"
                                  className="custom-control-input ml-2 "
                                  id="IsActive"
                                  defaultChecked={values.isActive}
                                  onChange={e => {
                                    setFieldValue(`isActive`, e.target.checked)
                                  }}
                                  onClick={e => {
                                    setFieldValue("isActive", !values.isActive)
                                  }}
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="IsActive"
                                ></Label>
                              </FormGroup>
                              {errors.isActive && (
                                <div className="invalid-feedback d-block">
                                  {errors.isActive}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("image")}</Label>
                              <FormGroup>
                                <Input
                                  style={{ display: "none" }}
                                  id="Image"
                                  type="file"
                                  name="personImage"
                                  onChange={e => {
                                    this.setState({
                                      photo: e.target.files[0],
                                    })
                                    setFieldValue(
                                      "personImage",
                                      e.target.files[0]
                                    )
                                  }}
                                />
                                <label for="Image" class="btn btn-secondary">
                                  <i class="far fa-file"></i>
                                </label>
                                <br />
                                {values.personImage ? (
                                  <Label>{values.personImage?.name}</Label>
                                ) : (
                                  ""
                                )}
                                {errors.personImage && (
                                  <div className="invalid-feedback d-block">
                                    {errors.personImage}
                                  </div>
                                )}
                                {values.personImage &&
                                this.state.Label == "Edit" ? (
                                  <FileDisplay Value={values.personImage} />
                                ) : (
                                  ""
                                )}
                              </FormGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction ? "show-spinner " : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(Team)
