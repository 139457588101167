import { gql } from "@apollo/client"

export const getAllFaqsQuery = gql`
  query getAllFAQ(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float) {
    getAllFAQs(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        answer
        question
        isActive
        faqContent
        error {
          statusCode
          message
        }
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteFaqMutation = gql`
  mutation deleteOneFAQ($id: Int!) {
    deleteOneFAQ(id: $id) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const getOneFaqQuery = gql`
  query getFaq($id: Int!) {
    getOneFaq(id: $id) {
      id
      answer
      question
      faqContent
      isActive
    }
  }
`
export const createFaqMutation = gql`
  mutation createOneFAQ($data: faqInput) {
    createOneFAQ(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updateFaqMutation = gql`
  mutation updateOneFAQ($id: Int!, $data: faqInput) {
    updateOneFAQ(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const UpdateManyMutation = gql`
  mutation updateManyFAQs($data: [faqInput]) {
    updateManyFAQs(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
