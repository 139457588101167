import { gql } from "@apollo/client"

export const getAllComponentsQuery = gql`
  query getAllComponents(
    $searchString: String
    $pageNumber: Float
    $pageLimit: Float
  ) {
    getAllComponents(
      searchString: $searchString
      pageNumber: $pageNumber
      pageLimit: $pageLimit
    ) {
      items {
        id
        name
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteComponentMutation = gql`
  mutation deleteComponent($id: Int!) {
    deleteOneComponent(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneComponentQuery = gql`
  query getComponent($id: Int!) {
    getOneComponent(id: $id) {
      id
      name
      isActive
    }
  }
`
export const createComponentMutation = gql`
  mutation createOneComponent($data: componentInput) {
    createOneComponent(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updateComponentMutation = gql`
  mutation updateOneComponent($id: Int!, $data: componentInput) {
    updateOneComponent(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

export const UpdateManyMutation = gql`
  mutation updateManyComponents($data: [componentInput]) {
    updateManyComponents(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
