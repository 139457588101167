export default {
  "username.placeholder": "Please provide Username",
  "username.required": "Username is required",
  "mobileNumber.placeholder": "Please provide Username or MobileNumber",
  "password.placeholder": "Please provide Password",
  "password.required": "Password is required",
  "password.invalid": "Password is invalid",
  Logout: "Logout",
  changePassword: "Change Password",
  district: "District",
  InstituteType: "InstituteType",
  District: "District",
  State: "State",
  "add.CMSContent": " Add CMS Content",
  State: "State",
  OrderType: "Order Type",
  "Name.required": "Name is required ",
  "name.required": "Name is required",
  "password.lengthinvalid": "Password must contain 8 characters",
  "delete.confirmation": "Are you sure you want to delete the",
  "Mobile.invalid": "Mobile number is invalid",
  "modal.delete": "Delete",
  "modal.edit": "Edit",
  "modal.add": "Add",
  "modal.update": "Update",
  "modal.cancel": "Cancel",
  "modal.yes": "Yes",
  "modal.no": "No",
  yes: "Yes",
  no: "No",
  name: "Name",
  description: "Description",
  meetingConductedBy: "Meeting Conducted By",
  meetingHeldOn: "Meeting Held On",
  GalleryPhotoContent: "Gallery Photo Content",
  color: "Colour",
  mandatoryFields: "Mandatory Fields",
  update: "Update",
  order: "Order",
  activity: "Activity",
  file: "File",
  add: "Add",
  content: "Content",
  designation: "Designation",
  view: "View",
  edit: "Edit",
  delete: "Delete",
  cancel: "Cancel",
  default: "Default",
  active: "Active",
  image: "Image",
  video: "Video",
  album: "Album",
  parentAlbumId: "Parent Album",
  activityType: "ActivityType",
  "Approved PAB": "Approved PAB",
  "Cancelled PAB": "Cancelled PAB",
  approvedAmount: "Approved Amount",
  "link.required": "Link is required",
  "educationalInstituteId.required": "Educational Institute is required",
  "componentId.required": "Component is required",
  "pabId.required": "PAB is required",
  "departmentImage.required": "Department Image is required",
  "albumId.required": "Album is required",
  "answer.required": "Answer is required",
  "question.required": "Question is required",
  "title.required": "Title is required",
  "orderType.required": "Order Type is required",
  "activityTypeId.required": "Activity Type is required",
  "fileURL.required": "File is required",
  "video.required": "Video is required",
  PhaseComponent: "Phase Component",
  PAB: "PAB",
  EducationalInstitute: "Educational Institute",
  naacScore: "NAAC Score",
  grade: "Grade",
  beneficiary: "Beneficiary",
  yearEstablished: "Year Established",
  grant: "Grant",
  "ComponentEducationPAB.add": "ComponentEducationPAB Added Successfully",
  "ComponentEducationPAB.update": "ComponentEducationPAB Updated Successfully",
  "ComponentEducationPAB.delete": "ComponentEducationPAB Deleted Successfully",

  "NAACAccredation.add": "NAAC Accreditation Added Successfully",
  "NAACAccredation.update": "NAAC Accreditation Updated Successfully",
  "NAACAccredation.delete": "NAAC Accreditation Deleted Successfully",

  "NAACAccredationFinancial.add": "NAAC Accreditation Added Successfully",
  "NAACAccredationFinancial.update": "NAAC Accreditation Updated Successfully",
  "NAACAccredationFinancial.delete": "NAAC Accreditation Deleted Successfully",

  "add.NAACAccredation": "Add NAAC Accreditation Institution",
  "update.NAACAccredation": "Update NAAC Accreditation Institution",
  "delete.NAACAccredation": "Delete NAAC Accreditation Institution",

  "add.NAACAccredationFinancial": "Add NAAC Accreditation Financial",
  "update.NAACAccredationFinancial": "Update NAAC Accreditation Financial",
  "delete.NAACAccredationFinancial": "Delete NAAC Accreditation Financial",

  "EducationalInstitution.delete":
    "Educational Institution Deleted Successfully",
  "EducationalInstitution.add": "Educational Institution Added Successfully",
  "EducationalInstitution.update":
    "Educational Institution Updated Successfully",

  "PABRejected.add": "PAB Cancelled Added Successfully",
  "PABRejected.update": "PAB Cancelled Updated Successfully",
  "PABRejected.delete": "PAB Cancelled Deleted Successfully",

  "add.PABRejected": "Add PAB Cancelled",
  "update.PABRejected": "Update PAB Cancelled",
  "delete.PABRejected": "Delete PAB Cancelled",

  "pabApprovedId.required": "PAB Approved is required",
  "componentId.required": "Component is required",
  "add.Reviewmeeting": "Add Review Meeting",
  "update.ReviewMeeting": "Update Review Meeting",
  "delete.ReviewMeeting": "Delete Review Meeting",

  pabApprovedAmount: "PAB Approved Amount",
  centralShare: "Central Share",
  stateShare: "State Share",
  totalShare: "Total Share",
  releasedCentralShare: "Released Central Share",
  releasedTotalShare: "Released Total Share",
  releasedStateShare: "Released State Share",
  "add.homepageimage": "Add HomePage Image",
  "delete.homepageimage": "Delete HomePage Image",
  "update.homepageimage": "Update HomePage Image",
  "add.ComponentEducationPAB": "Add Component PAB Education",
  "delete.ComponentEducationPAB": "Delete Component PAB Education",
  "update.ComponentEducationPAB": "Update Component PAB Education",
  "add.homepageimage.success": "HomePage Image Successfully Added",
  "delete.homepageimage.success": "HomePage Image Successfully Deleted",
  "update.homepageimage.success": "HomePage Image Successfully Updated",
  logo: "Logo",
  address: "Address",
  InstituteType: "Institute Type",
  CreatedDateTime: "Created Date Time",
  Question: "Question",
  Answer: "Answer",
  "user.mobileNumber": "Mobile Number",
  "button.add": "Add",
  "button.update": "Update",
  "button.remove": "Delete",
  "button.cancel": "Cancel",
  "mandatory.fields": "Mandatory Fields",
  "Question.required": "Question is required",
  "description.required": "Description is required",
  "image.required": "Image is required",
  "activityType.required": "ActivityType is required",
  "title.required": "Title is required",
  "mobileNumber.required": "Mobile Number is required",
  "description.required": "Description is required",
  "address.required": "Address is required",
  "logoURL.required": "Logo URL is required",
  "fieldUpload.error":
    "Uploading of photo failed. Kindly refresh and try again",
  title: "Title",
  Rank: "Rank",
  AchievementContent: "Description",
  content: "Content",
  "home.cmsContent": "Home.CMS Content",
  shortDescription: "Content",
  cmsContent: "CMS Content",
  DepartmentImage: "Department Image",
  link: "Link",
  action: "Action",
  preview: "Preview",
  company: "Company",
  "personCompany.required": "Company is required",
  "personDesignation.required": "Designation is required",
  "designation.required": "Designation is required",
  "rank.required": "Rank is required",
  "instituteType.required": "Institute Type is required",
  "district.required": "District is required",
  "state.required": "State is required",

  "personName.required": "Name is required",
  "testimonialContent.required": "Content is required",
  "title.required": "Title is required",
  "content.required": "Content is required",

  "cmsContent.add": "CMS Content Successfully Added",
  "cmsContent.update": "CMS Content Successfully Updated",
  "cmsContent.delete": "CMS Content Successfully Deleted",

  "user.add": "User Successfully Added",
  "user.update": "User Successfully Updated",
  "user.delete": "User Successfully Deleted",

  //add,update,delete button text

  "add.cmsContent": "Add CMS Content",
  "update.cmsContent": "Update CMS Content",
  "delete.cmsContent": "Delete CMS Content",

  "add.user": "Add User",
  "view.user": "View User",
  "delete.user": "Delete User",
  "update.user": "Update User",

  //titles

  "delete.achievement": "Delete Achievements",
  "update.Achievement": "Edit Achievements",
  "add.Achievement": "Add Achievements",

  "delete.alliedDepartment": "Delete Allied Department",
  "update.AlliedDepartment": "Edit Allied Department",
  "add.AlliedDepartment": "Add Allied Department",

  "delete.galleryAlbum": "Delete Gallery Album",
  "update.GalleryAlbum": "Edit Gallery Album",
  "add.GalleryAlbum": "Add Gallery Album",

  "delete.activity": "Delete Activity",
  "update.Activity": "Edit Activity",
  "add.Activity": "Add Activity",

  "delete.component": "Delete Component",
  "update.Component": "Edit Component",
  "add.Component": "Add Component",

  "delete.sanctionOrder": "Delete Sanction Order",
  "update.SanctionOrder": "Edit Sanction Order",
  "add.SanctionOrder": "Add Sanction Order",

  "delete.galleryPhoto": "Delete Gallery Photo",
  "update.GalleryPhoto": "Edit Gallery Photo",
  "add.GalleryPhoto": "Add Gallery Photo",

  "delete.slider": "Delete Slider",
  "update.Slider": "Edit Slider",
  "add.Slider": "Add Slider",

  "delete.cmsContent": "Delete CMS Content",
  "update.CmsContent": "Edit CMS Content",
  "add.CmsContent": "Add CMS Content",

  "delete.team": "Delete Team",
  "update.Team": "Edit Team",
  "add.Team": "Add Team",

  "delete.phase": "Delete Phase",
  "update.Phase": "Edit Phase",
  "add.Phase": "Add Phase",

  "delete.district": "Delete District",
  "update.District": "Edit District",
  "add.District": "Add District",

  "delete.pab": "Delete PAB",
  "update.Pab": "Edit PAB",
  "add.Pab": "Add PAB",

  "delete.faq": "Delete Faq",
  "update.Faq": "Edit Faq",
  "add.Faq": "Add Faq",

  "delete.state": "Delete State",
  "update.State": "Edit State",
  "add.State": "Add State",

  "delete.orderType": "Delete Order Type",
  "update.OrderType": "Edit Order Type",
  "add.OrderType": "Add Order Type",

  "delete.activityType": "Delete Activity Type",
  "update.ActivityType": "Edit Activity Type",
  "add.ActivityType": "Add Activity Type",

  "delete.testimonials": "Delete Testimonial",
  "update.Testimonials": "Edit Testimonial",
  "add.Testimonials": "Add Testimonial",

  "delete.educationalInstitution": "Delete Educational Institution",
  "update.EducationalInstitution": "Edit Educational Institution",
  "add.EducationalInstitution": "Add Educational Institution",

  "delete.galleryVideo": "Delete Gallery Video",
  "update.GalleryVideo": "Edit Gallery Video",
  "add.GalleryVideo": "Add Gallery Video",

  "AlliedDepartment.add": "Allied Department Added Successfully",
  "AlliedDepartment.update": "Allied Department Updated Successfully",
  "AlliedDepartment.delete": "Allied Department deleted successfully",

  "Achievement.add": "Achievement Added Successfully",
  "Achievement.update": "Achievement Updated Successfully",
  "Achievement.delete": "Achievement deleted successfully",

  "Faq.add": "Faq Added Successfully",
  "Faq.update": "Faq Updated Successfully",
  "faq.delete": "Faq deleted successfully",

  "SanctionOrder.add": "Sanction Order Added Successfully",
  "SanctionOrder.update": "Sanction Order Updated Successfully",
  "sanctionOrder.delete": "Sanction Order deleted successfully",

  "GalleryPhoto.add": "Gallery Photo Added Successfully",
  "GalleryPhoto.update": "Gallery Photo Updated Successfully",
  "galleryPhoto.delete": "Gallery Photo deleted successfully",

  "Slider.add": "Slider Added Successfully",
  "Slider.update": "Slider Updated Successfully",
  "slider.delete": "Slider deleted successfully",

  "OrderType.add": "Order Type Added Successfully",
  "OrderType.update": "Order Type Updated Successfully",
  "orderType.delete": "Order Type deleted successfully",

  "Team.add": "Team Added Successfully",
  "Team.update": "Team Updated Successfully",
  "team.delete": "Team deleted successfully",

  "GalleryVideo.add": "Gallery Video Added Successfully",
  "GalleryVideo.update": "Gallery Video Updated Successfully",
  "galleryVideo.delete": "Gallery Video deleted successfully",

  "Component.add": "Component Added Successfully",
  "Component.update": "Component Updated Successfully",
  "component.delete": "Component deleted successfully",

  "Phase.add": "Phase Added Successfully",
  "Phase.update": "Phase Updated Successfully",
  "phase.delete": "Phase deleted successfully",

  "Pab.add": "PAB Added Successfully",
  "Pab.update": "PAB Updated Successfully",
  "pab.delete": "PAB deleted successfully",

  "Testimonials.add": "Testimonials Added Successfully",
  "Testimonials.update": "Testimonials Updated Successfully",
  "testimonials.delete": "Testimonials deleted successfully",

  "GalleryAlbum.add": "Gallery Album Added Successfully",
  "GalleryAlbum.update": "Gallery Album Updated Successfully",
  "galleryAlbum.delete": "Gallery Album deleted successfully",

  "ActivityType.add": "Activity Type Successfully Added",
  "ActivityType.update": "Activity Type Successfully Updated",
  "ActivityType.delete": "Activity Type Successfully Deleted",

  "State.add": "State Successfully Added",
  "State.update": "State Successfully Updated",
  "State.delete": "State  Successfully Deleted",

  "District.add": "District Successfully Added",
  "District.update": "District Successfully Updated",
  "District.delete": "District  Successfully Deleted",

  "Activity.add": "Activity Successfully Added",
  "Activity.update": "Activity Successfully Updated",
  "Activity.delete": "Activity  Successfully Deleted",
}
