import { gql } from "@apollo/client"

export const getAllAchievementsQuery = gql`
  query getAllAchievements(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllAchievements(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        image
        title
        achievementContent
        isActive
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteAchievementMutation = gql`
  mutation deleteAchievement($id: Int!) {
    deleteOneAchievement(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneAchievementQuery = gql`
  query getAchievement($id: Int!) {
    getOneAchievement(id: $id) {
      id
      image
      title
      achievementContent
      isActive
    }
  }
`
export const createAchievementMutation = gql`
  mutation createAchievement($data: achievementInput) {
    createOneAchievement(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const updateAchievementMutation = gql`
  mutation updateAchievement($id: Int!, $data: achievementInput) {
    updateOneAchievement(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
export const fileuploadQuery = gql`
  query getUploadUrl($type: String!, $fileName: String!) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`
export const updateManyAchievementMutation = gql`
  mutation updateManyAchievements($data: [achievementInput]) {
    updateManyAchievements(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
