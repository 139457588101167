import { gql } from "@apollo/client"

export const getAllInspectionsQuery = gql`
  query getAllInspections(
    $period: String
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllInspections(
      period: $period
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        institution
        date
        component
        period
        description
        error {
          statusCode
          message
        }
      }
    }
  }
`
export const createOneInspectionMutation = gql`
  mutation createOneInspection($data: inspectionInput) {
    createOneInspection(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

export const updateOneInspectionMutation = gql`
  mutation updateOneInspection($id: Int!, $data: inspectionInput) {
    updateOneInspection(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

export const deleteOneInspectionMutation = gql`
  mutation deleteoneInspection($id: Int!) {
    deleteOneInspection(id: $id) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
