import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
  Media,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ListPageHeader from "../../components/custom/ListPageHeader"
import { MethodType, StatusCodes } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { SanctionOrderValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import DropDown from "../../components/custom/DropDown"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CustomSelectInput from "../../components/Common/CustomSelectInput"
import { getDateTimeString, unixdateconversion } from "../../helpers/utils"
import { Tabs, Tab } from "react-bootstrap"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import Table from "react-bootstrap/Table"
import {
  createSanctionOrderMutation,
  updateSanctionOrderMutation,
  deleteSanctionOrderMutation,
  getOneSanctionOrderQuery,
  getAllGalleryAlbumsQuery,
  fileuploadQuery,
  getAllorderQuery,
  getAllSanctionOrderQuery,
  UpdateManyMutation,
} from "./Queries"
import moment from "moment"
import MetaTags from "react-meta-tags"
import axios from "axios"
import FileDisplay from "components/custom/FileDisplay"

class SanctionOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      orderList: [],
      sanctionOrderList: [],
      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 0,
      },
      sanctionOrder: {
        fileURL: "",
        title: "",
        sanctionContent: "",
        isActive: false,
        orderTypeId: "",
        sanctionDateTime: "",
      },
      columns: [
        {
          name: "Title",
          selector: "title",
          sortable: true,
          cell: row => <span>{row.title ? row.title : ""}</span>,
        },
        {
          name: "Order",
          selector: "order",
          sortable: true,
          cell: row => (
            <span>{row.orderType?.name ? row.orderType?.name : "-"}</span>
          ),
        },
        {
          name: "Content",
          selector: "content",
          sortable: true,
          cell: row => (
            <span>{row.sanctionContent ? row.sanctionContent : ""}</span>
          ),
        },
        {
          name: "File",
          selector: "file",
          sortable: true,
          cell: row => (
            <span>
              {" "}
              {row.fileURL ? <FileDisplay Value={row.fileURL} /> : "-"}
            </span>
          ),
        },
        {
          name: "Is Active",
          selector: "isActive",
          sortable: true,
          cell: row => <span> {row.isActive ? "Active" : "Not Active"}</span>,
        },

        // {
        //   name: "Validity",
        //   selector: "validity",
        //   sortable: true,

        //   cell: row => (
        //     <div>
        //       <span>
        //         {row.validity
        //           ? unixdateconversion(row.validity, {
        //               year: "numeric",
        //               month: "2-digit",
        //               day: "2-digit",
        //             })
        //           : "-"}
        //       </span>
        //     </div>
        //   ),
        // },
        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: row => (
            <Row>
              <ButtonGroup className="mb-2" style={{ top: "4px" }}>
                <Button
                  outline
                  color="primary"
                  className="mobileViewFont ml-2"
                  onClick={() => this.handleEdit(row)}
                >
                  Edit
                </Button>

                <Button
                  outline
                  color="danger"
                  className="mobileViewFonts pl-1 pr-1 ml-2"
                  onClick={() => this.toggleDeleteModal(row)}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Row>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    this.getAllSanctionOrders()
    this.getAllOrderTypes()
  }
  getAllOrderTypes = async value => {
    const response = await QueryRequest(getAllorderQuery, "")
    if (response?.data?.getAllOrderTypes?.items) {
      this.setState({
        orderList: response.data.getAllOrderTypes.items.map(function (a) {
          return { label: a.name, value: a.id }
        }),
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  getAllSanctionOrders = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllSanctionOrderQuery, values)
    var sanctionOrderValue = response?.data?.getAllSanctionOrders?.items
    {
      console.log(sanctionOrderValue)
    }
    if (sanctionOrderValue) {
      this.setState({
        isLoading: false,
        sanctionOrderList: sanctionOrderValue,
        totalPage:
          response?.data?.getAllSanctionOrders?.pagination?.totalCount /
          response?.data?.getAllSanctionOrders?.pagination?.pageLimit,
        totalCount:
          response?.data?.getAllSanctionOrders?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllSanctionOrders()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllSanctionOrders()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllSanctionOrders()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllSanctionOrders()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        sanctionOrder: row,
        sanctionDateTime: row.sanctionDateTime
          ? new Date(parseInt(row.sanctionDateTime))
          : "",

        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })

    if (typeof values.fileURL === "object") {
      let fileupload = {
        type: "photo",
        fileName: values.fileURL?.name,
      }
      const fileUploadresponse = await QueryRequest(fileuploadQuery, fileupload)
      let data = fileUploadresponse?.data?.getUploadUrl
      // console.log(values.fileURL)
      if (data.status === "Success") {
        axios
          .put(data.url, values.fileURL, {
            headers: { "Content-Type": values.fileURL?.type },
          })
          .then(async res => {
            if (res.status.toString() === StatusCodes.Success) {
              var AddData = {
                fileURL: data.url.split("?")[0],
                title: values.title,
                sanctionContent: values.sanctionContent,
                sortId: 1,
                isActive: values.isActive,
                orderTypeId: values.orderTypeId,
                sanctionDateTime: values.sanctionDateTime,
              }
              var EditVal = {
                id: values.id,
                data: {
                  fileURL: data.url.split("?")[0],
                  title: values.title,
                  sanctionContent: values.sanctionContent,
                  sortId: 1,
                  isActive: values.isActive,
                  orderTypeId: values.orderTypeId,
                  sanctionDateTime: values.sanctionDateTime,
                },
              }
              const response = values.id
                ? await MutateRequest(updateSanctionOrderMutation, EditVal)
                    .then(
                      r =>
                        this.setState({
                          // manageEditModal: !this.state.manageEditModal,
                          buttonAction: false,
                        }),
                      SuccessMessage(Message("SanctionOrder.update"))
                    )
                    .catch(e => ErrorMessage(e))
                : await MutateRequest(createSanctionOrderMutation, {
                    data: AddData,
                  })
                    .then(r => {
                      this.setState({
                        // manageEditModal: !this.state.manageEditModal,
                        buttonAction: false,
                      })
                      SuccessMessage(Message("SanctionOrder.add"))
                    })
                    .catch(e => ErrorMessage(e))
              var sanctionOrderValue = response?.data
              if (sanctionOrderValue && values.id) {
                // SuccessMessage(Message("sanctionOrder.update")),
                this.setState({
                  manageModal: !this.state.manageModal,
                })
                this.getAllSanctionOrders()
              } else {
                // SuccessMessage(Message("sanctionOrder.add")),
                this.setState({
                  manageModal: !this.state.manageModal,
                })
                this.getAllSanctionOrders()
              }
            } else {
              ErrorMessage(Message("fieldUpload.error"))
            }
          })
          .catch(err => alert("Error"))
      } else {
      }
    } else {
      var AddData = {
        fileURL: values.fileURL,
        title: values.title,
        sanctionContent: values.sanctionContent,
        sortId: 1,
        isActive: values.isActive,
        orderTypeId: values.orderTypeId,
      }
      var EditVal = {
        id: values.id,
        data: {
          fileURL: values.fileURL,
          title: values.title,
          sanctionContent: values.sanctionContent,
          sortId: 1,
          isActive: values.isActive,
          orderTypeId: values.orderTypeId,
        },
      }
      const response = values.id
        ? await MutateRequest(updateSanctionOrderMutation, EditVal)
            .then(
              r =>
                this.setState({
                  // manageEditModal: !this.state.manageEditModal,
                  buttonAction: false,
                }),
              SuccessMessage(Message("SanctionOrder.update"))
            )
            .catch(e => ErrorMessage(e))
        : await MutateRequest(createSanctionOrderMutation, { data: AddData })
            .then(r => {
              this.setState({
                // manageEditModal: !this.state.manageEditModal,
                buttonAction: false,
              })
              SuccessMessage(Message("SanctionOrder.add"))
            })
            .catch(e => ErrorMessage(e))
      var sanctionOrderValue = response?.data
      if (sanctionOrderValue && values.id) {
        // SuccessMessage(Message("sanctionOrder.update")),
        this.setState({
          manageModal: !this.state.manageModal,
        })
        this.getAllSanctionOrders()
      } else {
        // SuccessMessage(Message("sanctionOrder.add")),
        this.setState({
          manageModal: !this.state.manageModal,
        })
        this.getAllSanctionOrders()
      }
    }
  }

  addBtnClick = () => {
    this.setState({
      sanctionOrder: {
        fileURL: "",
        title: "",
        sanctionContent: "",
        isActive: false,
        orderTypeId: "",
        sanctionDateTime: "",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      sanctionOrder: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      sanctionOrder: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      sanctionOrder: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteSanctionOrder = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deleteSanctionOrderMutation, values)
    var sanctionOrderdeleteValue = response?.data
    if (sanctionOrderdeleteValue.deleteOneSanctionOrder.status === "Success") {
      SuccessMessage(Message("sanctionOrder.delete"))
      this.getAllSanctionOrders()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageSanctionOrderResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.sanctionOrder.id
        ? SuccessMessage(Message("SanctionOrder.update"))
        : SuccessMessage(Message("SanctionOrder.add"))

      this.getAllSanctionOrders()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      galleryPhotoDetail: url ? url : "",
    })
  }

  handleOnDragEnd = async result => {
    if (!result.destination) return

    this.setState({ isLoading: true })
    const items = Array.from(this.state.sanctionOrderList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    this.setState({ data: items })
    const res = []
    items.map((u, i) => {
      res.push({
        id: u.id,
        sortId: i + 1,
        fileURL: u.fileURL,
        title: u.title,
        sanctionContent: u.sanctionContent,
        orderTypeId: u.orderTypeId,
        isActive: u.isActive,
      })
    })
    console.log({ data: res })
    const response = await MutateRequest(UpdateManyMutation, {
      data: res,
    })
    console.log(response)
    if (response?.data?.updateManySanctionOrders.status === "Success") {
      SuccessMessage("Updated Successfully")
      this.getAllSanctionOrders()
    } else {
      ErrorMessage("Error")
    }
  }
  render() {
    const initialValues = this.state.sanctionOrder
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sanction Orders</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Sanction Orders"}`}
                    buttonText={Message("add.SanctionOrder")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                <Row>
                  <Col sm="12" md="6" lg="3">
                    {" "}
                    <DropDown
                      label={Message("Order")}
                      MobcolSplit={12}
                      isClearable={true}
                      isSearchable
                      options={this.state.orderList}
                      ClearAction={() => {
                        this.setState(
                          {
                            listRequestModel: {
                              ...this.state.listRequestModel,
                              orderTypeId: null,
                            },
                          },
                          () => this.getAllSanctionOrders()
                        )
                      }}
                      Action={entity => {
                        if (entity) {
                          this.setState(
                            {
                              listRequestModel: {
                                ...this.state.listRequestModel,
                                orderTypeId: entity.value,
                              },
                            },
                            () => this.getAllSanctionOrders()
                          )
                        } else {
                          this.setState(
                            {
                              listRequestModel: {
                                ...this.state.listRequestModel,
                                orderTypeId: null,
                              },
                            },
                            () => this.getAllSanctionOrders()
                          )
                        }
                      }}
                    />
                  </Col>
                </Row>
                <div className="PoliceView">
                  <ListPage
                    conditionalRowStyles={conditionalRowStyles}
                    columns={this.state.columns}
                    data={this.state.sanctionOrderList}
                    keyField={this.state.keyField}
                    totalCount={this.state.totalCount}
                    rowClicked={this.HandleRowClicked}
                    onSort={this.handleSort}
                    rowsPerPageOnChange={this.handlePerRowsChange}
                    pageChange={this.handlePageChange}
                    isDataLoading={this.state.isLoading}
                    overFlowXRemoval={true}
                  />
                </div>
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.sanctionOrder")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        Sanction Order " {""}
                        {this.state.sanctionOrder
                          ? this.state.sanctionOrder?.title
                          : ""}{" "}
                        " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() =>
                      this.deleteSanctionOrder(this.state.sanctionOrder.id)
                    }
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="lg"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.SanctionOrder")
                  : Message("add.SanctionOrder")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={SanctionOrderValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  console.log("values", values),
                  (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <ModalBody>
                        <Fragment>
                          <Row>
                            <Col lg="6" sm="12" md="12">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {Message("title")}
                                </Label>
                                <Field
                                  className="form-control"
                                  name="title"
                                  type="text"
                                />
                                {errors.title && (
                                  <div className="invalid-feedback d-block">
                                    {errors.title}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6" sm="12" md="12">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  label={Message("OrderType")}
                                  components={{
                                    Input: CustomSelectInput,
                                  }}
                                  labelClassName="requiredField"
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values.orderTypeId &&
                                    (typeof values.orderTypeId === "string"
                                      ? this.state.orderList.find(
                                          r => r.value === values.orderTypeId
                                        )
                                      : this.state.orderList.find(
                                          r => r.value === values.orderTypeId
                                        ))
                                  }
                                  options={this.state.orderList}
                                  ClearAction={() => {
                                    setFieldValue(`orderTypeId`, ""),
                                      this.setState(
                                        {
                                          orderType: null,
                                        },
                                        () => this.getAllOrderTypes()
                                      )
                                  }}
                                  Action={entity => {
                                    if (entity) {
                                      this.setState(
                                        {
                                          orderType: entity ? entity.value : "",
                                        },
                                        () => this.getAllOrderTypes()
                                      )
                                      setFieldValue("orderTypeId", entity.value)
                                    } else {
                                      this.setState(
                                        {
                                          orderType: "",
                                        },
                                        () => this.getAllOrderTypes()
                                      )
                                      setFieldValue("orderTypeId", "")
                                    }
                                  }}
                                  errors={errors?.orderTypeId}
                                />
                              </FormGroup>{" "}
                            </Col>
                            <Col lg="6" sm="12" md="12">
                              <FormGroup className="form-group has-float-label">
                                <Label className="">{Message("content")}</Label>
                                <Field
                                  className="form-control"
                                  name="sanctionContent"
                                  type="text"
                                />
                                {errors.sanctionContent && (
                                  <div className="invalid-feedback d-block">
                                    {errors.sanctionContent}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6" sm="12" md="12">
                              <FormGroup className=" form-group has-float-label">
                                <Label className="requiredField">Date</Label>
                                <DatePicker
                                  className="form-control box-border"
                                  selected={this.state.sanctionDateTime}
                                  onChange={date => {
                                    this.setState({
                                      sanctionDateTime: date,
                                    })
                                    if (date)
                                      setFieldValue(
                                        `sanctionDateTime`,
                                        new Date(date).valueOf().toString()
                                      )
                                    else setFieldValue(`sanctionDateTime`, "")
                                  }}
                                  dateFormat="dd/MM/yyyy"
                                />
                                {errors.sanctionDateTime && (
                                  <div className="invalid-feedback d-block">
                                    {errors.sanctionDateTime}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6" sm="12" md="12">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {Message("file")}
                                </Label>
                                <FormGroup>
                                  <Input
                                    style={{ display: "none" }}
                                    id="Image"
                                    type="file"
                                    name="fileURL"
                                    onChange={e => {
                                      this.setState({
                                        photo: e.target.files[0],
                                      })
                                      setFieldValue(
                                        "fileURL",
                                        e.target.files[0]
                                      )
                                    }}
                                  />
                                  <label for="Image" class="btn btn-secondary">
                                    <i class="far fa-file"></i>
                                  </label>
                                  <br />
                                  {values.fileURL ? (
                                    <Label>{values.fileURL?.name}</Label>
                                  ) : (
                                    ""
                                  )}
                                  {errors.fileURL && (
                                    <div className="invalid-feedback d-block">
                                      {errors.fileURL}
                                    </div>
                                  )}
                                  {values.fileURL &&
                                  this.state.Label == "Edit" ? (
                                    <FileDisplay Value={values.fileURL} />
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>
                              </FormGroup>
                            </Col>
                            <Col lg="6" sm="12" md="12">
                              <FormGroup className="form-group has-float-label ml-2 pl-1 slider-padding">
                                <Label className="mt-1 ">
                                  {Message("active")}
                                </Label>
                                <FormGroup className="custom-control custom-switch mt-1 ml-2">
                                  <Input
                                    autoComplete="off"
                                    type="checkbox"
                                    className="custom-control-input ml-2 "
                                    id="IsActive"
                                    defaultChecked={values.isActive}
                                    onChange={e => {
                                      setFieldValue(
                                        `isActive`,
                                        e.target.checked
                                      )
                                    }}
                                    onClick={e => {
                                      setFieldValue(
                                        "isActive",
                                        !values.isActive
                                      )
                                    }}
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor="IsActive"
                                  ></Label>
                                </FormGroup>
                                {errors.isActive && (
                                  <div className="invalid-feedback d-block">
                                    {errors.isActive}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Fragment>
                      </ModalBody>
                      <ModalFooter>
                        <div className="container-fluid">
                          <Row>
                            <Col lg="">
                              <Label>
                                <span
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  *{" "}
                                </span>
                                {Message("mandatoryFields")}
                              </Label>
                            </Col>

                            <FormGroup className="float-right ">
                              {this.state.Label == "Edit" ? (
                                <Button
                                  type="submit"
                                  color="primary"
                                  className={`btn-shadow btn-multiple-state ${
                                    this.state.buttonAction
                                      ? "show-spinner  disabled"
                                      : ""
                                  }`}
                                >
                                  {" "}
                                  <span className="spinner d-inline-block">
                                    <span className="bounce1" />
                                    <span className="bounce2" />
                                    <span className="bounce3" />
                                  </span>
                                  <span className="label ">
                                    {Message("update")}
                                  </span>
                                </Button>
                              ) : (
                                <Button
                                  type="submit"
                                  color="primary"
                                  className={`btn-shadow btn-multiple-state ${
                                    this.state.buttonAction
                                      ? "show-spinner "
                                      : ""
                                  }`}
                                >
                                  {" "}
                                  <span className="spinner d-inline-block">
                                    <span className="bounce1" />
                                    <span className="bounce2" />
                                    <span className="bounce3" />
                                  </span>
                                  <span className="label ">
                                    {Message("add")}
                                  </span>
                                </Button>
                              )}

                              <Button
                                color="danger"
                                className="ml-2"
                                onClick={() => this.toggleManageModal()}
                              >
                                {Message("cancel")}
                              </Button>
                            </FormGroup>
                          </Row>
                        </div>
                      </ModalFooter>
                    </Form>
                  )
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(SanctionOrder)
