import inFlag from "../assets/images/flags/in.jpg"

const languages = {
  en: {
    label: "English",
    flag: inFlag,
  },
  ta: {
    label: "?????",
    flag: inFlag,
  },
}

export default languages
