import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
  Media,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import "react-dual-listbox/lib/react-dual-listbox.css"

import DualListBox from "react-dual-listbox"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ListPageHeader from "../../components/custom/ListPageHeader"
import { MethodType, StatusCodes } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { PhaseValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import DropDown from "../../components/custom/DropDown"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CustomSelectInput from "../../components/Common/CustomSelectInput"
import { getDateTimeString, unixdateconversion } from "../../helpers/utils"
import { Tabs, Tab } from "react-bootstrap"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import Table from "react-bootstrap/Table"
import {
  createPhaseMutation,
  updatePhaseMutation,
  deletePhaseMutation,
  getAllPhasesQuery,
  getOnePhaseQuery,
  createPhaseComponentMutation,
  getAllComponentsQuery,
  getAllPhaseComponentQuery,
  deletePhaseComponentMutation,
} from "./Queries"
import moment from "moment"
import lodash from "lodash"
import MetaTags from "react-meta-tags"

class Phase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      phaseList: [{ id: "1" }],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 10,
      },
      phase: {
        isDeleted: "",
        name: "",
      },
      //
      phaseComponent: {
        phaseId: "",
        components: [],
      },
      componentSelected: [],
      phaseComponentList: [],
      phaseComponentName: "",
      options: [],
      //
      columns: [
        {
          name: "Name",
          selector: "name",
          sortable: true,
          cell: row => <span>{row.name ? row.name : ""}</span>,
        },
        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: row => (
            <Row>
              <ButtonGroup className="mb-2" style={{ top: "4px" }}>
                <Button
                  outline
                  color="primary"
                  className="mobileViewFont ml-2"
                  onClick={() => this.handleEdit(row)}
                >
                  Edit
                </Button>

                <Button
                  outline
                  color="danger"
                  className="mobileViewFonts pl-1 pr-1 ml-2"
                  onClick={() => this.toggleDeleteModal(row)}
                >
                  Delete
                </Button>
                <Button
                  outline
                  color="success"
                  className="mobileViewFonts pl-1 pr-1 ml-2"
                  onClick={() => this.toggleMappingComponent(row)}
                >
                  Mapping Component
                </Button>
              </ButtonGroup>
            </Row>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    this.getAllPhases()
    this.getAllComponents()
  }
  getAllPhases = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllPhasesQuery, values)
    var phasesValue = response?.data?.getAllPhases?.items
    if (phasesValue) {
      this.setState({
        isLoading: false,
        phaseList: phasesValue,
        totalPage:
          response?.data?.getAllPhases?.pagination?.totalCount /
          response?.data?.getAllPhases?.pagination?.pageLimit,
        totalCount: response?.data?.getAllPhases?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  getAllComponents = async () => {
    await QueryRequest(getAllComponentsQuery).then(res => {
      console.log("onChange res", JSON.stringify(res))
      this.setState({
        options: res.data?.getAllComponents?.items.map(function (a) {
          return { value: a.id, label: a.name }
        }),
      })
    })
    console.log(this.state.options)
  }
  getOneComponent = async phaseId => {
    console.log("getOneComponentPHASEID", phaseId)

    await QueryRequest(getAllPhaseComponentQuery, { phaseId }).then(res => {
      this.setState({
        componentSelected: res.data?.getAllPhaseComponents?.items.map(function (
          a
        ) {
          return a.componentId
        }),
        phaseComponentList: res.data?.getAllPhaseComponents?.items,
      })
    })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllPhases()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllPhases()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllPhases()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllPhases()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        phase: row,
        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })
    var AddData = {
      name: values.name,
    }
    var EditVal = {
      id: values.id,
      data: {
        name: values.name,
      },
    }
    const response = values.id
      ? await MutateRequest(updatePhaseMutation, EditVal)
          .then(
            r =>
              this.setState({
                // manageEditModal: !this.state.manageEditModal,
                buttonAction: false,
              }),
            SuccessMessage(Message("Phase.update"))
          )
          .catch(e => ErrorMessage(e))
      : await MutateRequest(createPhaseMutation, { data: AddData })
          .then(r => {
            this.setState({
              buttonAction: false,
              // manageEditModal: !this.state.manageEditModal,
            })
            SuccessMessage(Message("Phase.add"))
          })
          .catch(e => ErrorMessage(e))
    var phaseValue = response?.data
    if (phaseValue && values.id) {
      // SuccessMessage(Message("phase.update")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllPhases()
    } else {
      // SuccessMessage(Message("phase.add")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllPhases()
    }
  }

  addBtnClick = () => {
    this.setState({
      phase: {
        isDeleted: "",
        name: "",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      phase: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      phase: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      phase: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }
  onChange = (componentSelected, row) => {
    console.log("onChange componentSelected", componentSelected)
    console.log("onChange Row ", row)

    this.setState({
      componentSelected,
      phaseComponent: {
        phaseId: this.state.phaseComponent.phaseId,

        components: componentSelected,
      },
    })
  }
  toggleMappingComponent = row => {
    console.log("reoq", row)
    if (row && row.id) {
      this.getOneComponent(row.id)
      this.setState({
        manageComponentModal: !this.state.manageComponentModal,
        phaseComponent: {
          phaseId: row ? row.id : "",
          phaseName: row ? row.name : "",
        },
      })
    } else {
      this.setState({
        manageComponentModal: !this.state.manageComponentModal,
      })
    }
  }

  //
  saveComponent = async values => {
    this.setState({
      buttonAction: true,
    })
    console.log("onChange", this.state.phaseComponent)

    var pc = this.state.phaseComponent
    var pcl = this.state.phaseComponentList
    var pclist = []

    console.log("onChange PC", pc)

    pc.components?.map(x => {
      pclist?.push({ phaseId: pc.phaseId, componentId: x })
    })

    let result = pclist?.filter(
      o1 => !pcl?.some(o2 => o1.componentId === o2.componentId)
    )
    let deleteResult = []
    pcl?.filter(o1 => {
      if (!pclist?.some(o2 => o1.componentId === o2.componentId)) {
        deleteResult?.push(o1.id)
      }
    })
    console.log("delete", deleteResult)
    const deleteResponse = await MutateRequest(deletePhaseComponentMutation, {
      ids: deleteResult,
    })
      .then(async r => {
        const response = await MutateRequest(createPhaseComponentMutation, {
          data: result,
        })
          .then(r => {
            this.setState({
              buttonAction: false,
              manageComponentModal: !this.state.manageComponentModal,
            })
            SuccessMessage(Message("Phase.add"))
          })
          .catch(e => ErrorMessage(e))
      })
      .catch(e => ErrorMessage(e))
  }
  //
  deletePhase = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deletePhaseMutation, values)
    var phasedeleteValue = response?.data
    if (phasedeleteValue.deleteOnePhase.status === "Success") {
      SuccessMessage(Message("phase.delete"))
      this.getAllPhases()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  managePhaseResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.phase.id
        ? SuccessMessage(Message("Phase.update"))
        : SuccessMessage(Message("Phase.add"))

      this.getAllPhases()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  // manageComponentModal =  {

  // }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      phaseDetail: url ? url : "",
    })
  }

  handleOnDragEnd = result => {
    if (!result.destination) return

    const items = Array.from(this.state.phaseList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    this.setState({ data: items })
    const res = []
    items.map(u => {
      res.push(u.id)
    })
    console.log(res)
  }
  render() {
    const initialValues = this.state.phase
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]

    console.log("componentSelected" + this.state.componentSelected)

    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Phase</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Phases"}`}
                    buttonText={Message("add.Phase")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                <Row></Row>
                <div className="PoliceView">
                  <ListPage
                    conditionalRowStyles={conditionalRowStyles}
                    columns={this.state.columns}
                    data={this.state.phaseList}
                    keyField={this.state.keyField}
                    totalCount={this.state.totalCount}
                    rowClicked={this.HandleRowClicked}
                    onSort={this.handleSort}
                    rowsPerPageOnChange={this.handlePerRowsChange}
                    pageChange={this.handlePageChange}
                    isDataLoading={this.state.isLoading}
                    overFlowXRemoval={true}
                  />
                </div>
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.phase")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        Phase " {""}
                        {this.state.phase ? this.state.phase.name : ""} " ?
                        {console.log(this.state.phase)}
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() => this.deletePhase(this.state.phase.id)}
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>
            <Modal
              size="lg"
              isOpen={this.state.manageComponentModal}
              toggle={this.toggleMappingComponentModal}
            >
              <ModalHeader toggle={this.toggleMappingComponentModal}>
                Mapping Component For
                {this.state.phaseComponent
                  ? this.state.phaseComponent.phaseName
                  : ""}
              </ModalHeader>
              <Formik>
                <Form>
                  <ModalBody>
                    <DualListBox
                      canFilter
                      filterPlaceholder="Search..."
                      options={this.state.options}
                      selected={this.state.componentSelected}
                      onChange={this.onChange}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <FormGroup className="float-sm-right">
                      <Button
                        className="float mt-2"
                        outline
                        color="primary"
                        onClick={this.saveComponent}
                      >
                        Add
                      </Button>
                      <Button
                        color="danger"
                        className="ml-2 mt-2"
                        outline
                        onClick={() => this.toggleMappingComponent()}
                      >
                        Cancel
                      </Button>
                    </FormGroup>
                  </ModalFooter>
                </Form>
              </Formik>
            </Modal>
            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="md"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.Phase")
                  : Message("add.Phase")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={PhaseValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">
                                {Message("name")}
                              </Label>
                              <Field
                                className="form-control"
                                name="name"
                                type="text"
                              />
                              {errors.name && (
                                <div className="invalid-feedback d-block">
                                  {errors.name}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction ? "show-spinner " : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(Phase)
