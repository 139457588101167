import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
  Media,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ListPageHeader from "../../components/custom/ListPageHeader"
import { MethodType, StatusCodes } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { GalleryPhotoValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import DropDown from "../../components/custom/DropDown"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CustomSelectInput from "../../components/Common/CustomSelectInput"
import { getDateTimeString, unixdateconversion } from "../../helpers/utils"
import { Tabs, Tab } from "react-bootstrap"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import Table from "react-bootstrap/Table"
import {
  createGalleryPhotoMutation,
  updateGalleryPhotoMutation,
  deleteGalleryPhotoMutation,
  getAllGalleryPhotosQuery,
  getOneGalleryPhotoQuery,
  getAllGalleryAlbumsQuery,
  fileuploadQuery,
  UpdateManyMutation,
} from "./Queries"
import moment from "moment"
import MetaTags from "react-meta-tags"
import axios from "axios"
import FileDisplay from "components/custom/FileDisplay"

class GalleryPhoto extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      galleryPhotoList: [],
      galleryAlbumList: [],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 0,
      },
      galleryPhoto: {
        isDeleted: "",
        image: "",
        title:"",
        description: "",
        createdDateTime: "",
        galleryPhotoContent: "",
      },
    }
  }

  componentDidMount() {
    this.getAllGalleryPhotos()
    this.getAllGalleryAlbums()
  }
  getAllGalleryPhotos = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllGalleryPhotosQuery, values)
    var galleryPhotosValue = response?.data?.getAllGalleryPhotos?.items
    if (galleryPhotosValue) {
      this.setState({
        isLoading: false,
        galleryPhotoList: galleryPhotosValue,
        totalPage:
          response?.data?.getAllGalleryPhotos?.pagination?.totalCount /
          response?.data?.getAllGalleryPhotos?.pagination?.pageLimit,
        totalCount: response?.data?.getAllGalleryPhotos?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  getAllGalleryAlbums = async value => {
    const response = await QueryRequest(getAllGalleryAlbumsQuery, "")
    console.log("responseresponseresponse",response)
    if (response?.data?.getAllAdminGalleryAlbums?.items) {
      console.log("response?.data?.getAllGalleryAlbums?.items",response?.data?.getAllAdminGalleryAlbums?.items)
      this.setState({
        //isLoading: false,
        //totalPage:
        //  response.data.getAllGalleryAlbums.pageLimit /
        //  response.data.getAllGalleryAlbums.pageLimit,
        galleryAlbumList: response.data.getAllAdminGalleryAlbums.items.map(function (
          a
        ) {
          return { label: a.name, value: a.id }
        }),
        //totalCount: response.data.getAllGalleryAlbums.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllGalleryPhotos()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllGalleryPhotos()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllGalleryPhotos()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllGalleryPhotos()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        galleryPhoto: row,
        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })

    if (typeof values.image === "object") {
      let fileupload = {
        type: "photo",
        fileName: values.image?.name,
      }
      const fileUploadresponse = await QueryRequest(fileuploadQuery, fileupload)
      let data = fileUploadresponse?.data?.getUploadUrl
      // console.log(values.image)
      if (data.status === "Success") {
        axios
          .put(data.url, values.image, {
            headers: { "Content-Type": values.image?.type },
          })
          .then(async res => {
            if (res.status.toString() === StatusCodes.Success) {
              var AddData = {
                image: data.url.split("?")[0],
                description: values.description,
                galleryPhotoContent: values.galleryPhotoContent,
                sortId: 1,
                albumId: values.albumId,
                title:values.title,
              }
              var EditVal = {
                id: values.id,
                data: {
                  image: data.url.split("?")[0],
                  description: values.description,
                  galleryPhotoContent: values.galleryPhotoContent,
                  sortId: 1,
                  albumId: values.albumId,
                  title:values.title,
                },
              }
              const response = values.id
                ? await MutateRequest(updateGalleryPhotoMutation, EditVal)
                    .then(
                      r =>
                        this.setState({
                          // manageEditModal: !this.state.manageEditModal,
                          buttonAction: false,
                        }),
                      SuccessMessage(Message("GalleryPhoto.update"))
                    )
                    .catch(e => ErrorMessage(e))
                : await MutateRequest(createGalleryPhotoMutation, {
                    data: AddData,
                  })
                    .then(r => {
                      this.setState({
                        // manageEditModal: !this.state.manageEditModal,
                        buttonAction: false,
                      })
                      SuccessMessage(Message("GalleryPhoto.add"))
                    })
                    .catch(e => ErrorMessage(e))
              var galleryPhotoValue = response?.data
              if (galleryPhotoValue && values.id) {
                // SuccessMessage(Message("galleryPhoto.update")),
                this.setState({
                  manageModal: !this.state.manageModal,
                })
                this.getAllGalleryPhotos()
              } else {
                // SuccessMessage(Message("galleryPhoto.add")),
                this.setState({
                  manageModal: !this.state.manageModal,
                })
                this.getAllGalleryPhotos()
              }
            } else {
              ErrorMessage(Message("fieldUpload.error"))
            }
          })
          .catch(err => alert("Error"))
      } else {
      }
    } else {
      var AddData = {
        image: values.image,
        description: values.description,
        galleryPhotoContent: values.galleryPhotoContent,
        sortId: 1,
        albumId: values.albumId,
        title: values.title,
      }
      var EditVal = {
        id: values.id,
        data: {
          image: values.image,
          description: values.description,
          galleryPhotoContent: values.galleryPhotoContent,
          sortId: 1,
          albumId: values.albumId,
          title: values.title,
        },
      }
      const response = values.id
        ? await MutateRequest(updateGalleryPhotoMutation, EditVal)
            .then(
              r =>
                this.setState({
                  // manageEditModal: !this.state.manageEditModal,
                  buttonAction: false,
                }),
              SuccessMessage(Message("GalleryPhoto.update"))
            )
            .catch(e => ErrorMessage(e))
        : await MutateRequest(createGalleryPhotoMutation, { data: AddData })
            .then(r => {
              this.setState({
                // manageEditModal: !this.state.manageEditModal,
                buttonAction: false,
              })
              SuccessMessage(Message("GalleryPhoto.add"))
            })
            .catch(e => ErrorMessage(e))
      var galleryPhotoValue = response?.data
      if (galleryPhotoValue && values.id) {
        // SuccessMessage(Message("galleryPhoto.update")),
        this.setState({
          manageModal: !this.state.manageModal,
        })
        this.getAllGalleryPhotos()
      } else {
        // SuccessMessage(Message("galleryPhoto.add")),
        this.setState({
          manageModal: !this.state.manageModal,
        })
        this.getAllGalleryPhotos()
      }
    }
  }

  addBtnClick = () => {
    this.setState({
      galleryPhoto: {
        isDeleted: "",
        image: "",
        description: "",
        title:"",
        createdDateTime: "",
        galleryPhotoContent: "",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      galleryPhoto: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      galleryPhoto: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      galleryPhoto: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteGalleryPhoto = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deleteGalleryPhotoMutation, values)
    var galleryPhotodeleteValue = response?.data
    if (galleryPhotodeleteValue.deleteOneGalleryPhoto.status === "Success") {
      SuccessMessage(Message("galleryPhoto.delete"))
      this.getAllGalleryPhotos()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageGalleryPhotoResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.galleryPhoto.id
        ? SuccessMessage(Message("GalleryPhoto.update"))
        : SuccessMessage(Message("GalleryPhoto.add"))

      this.getAllGalleryPhotos()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      galleryPhotoDetail: url ? url : "",
    })
  }

  handleOnDragEnd = async result => {
    if (!result.destination) return

    this.setState({ isLoading: true })
    const items = Array.from(this.state.galleryPhotoList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    this.setState({ data: items })
    const res = []
    items.map((u, i) => {
      res.push({
        id: u.id,
        sortId: i + 1,
        image: u.image,
        title:u.title,
        description: u.description,
        galleryPhotoContent: u.galleryPhotoContent,
        albumId: u.albumId,
      })
    })
    console.log({ data: res })
    const response = await MutateRequest(UpdateManyMutation, {
      data: res,
    })
    console.log(response)
    if (response?.data?.updateManyGalleryPhotos.status === "Success") {
      SuccessMessage("Updated Successfully")
      this.getAllGalleryPhotos()
    } else {
      ErrorMessage("Error")
    }
  }
  render() {
    const initialValues = this.state.galleryPhoto
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Gallery Photo</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Gallery Photos"}`}
                    buttonText={Message("add.GalleryPhoto")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                {this.state.isLoading ? (
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="spinner-border text-primary m-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </Row>
                ) : (
                  <div className="PoliceView">
                    <Table stripped hover size="sm">
                      <thead>
                        <tr
                        >
                          <th
                        style={{
                          textAlign: "center",
                          height: "50px",
                          paddingBottom: "15px",
                        }}
                      >
                        Title
                      </th>
                      <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Description
                          </th>
                          <th
                        style={{
                          textAlign: "center",
                          height: "50px",
                          paddingBottom: "15px",
                        }}
                      >
                        Album
                      </th>
                          
                          
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Content
                          </th>
                          
                          <th
                            style={{
                              textAlign: "center",
                              height: "50px",
                              paddingBottom: "15px",
                            }}
                          >
                            Photo
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              paddingBottom: "15px",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <DragDropContext
                        onDragEnd={result => this.handleOnDragEnd(result)}
                      >
                        <Droppable droppableId="characters">
                          {provided => (
                            <tbody
                              className="characters"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {this.state.galleryPhotoList.map((row, i) => {
                                return (
                                  <Draggable
                                    key={row.id}
                                    draggableId={row.id.toString()}
                                    index={i}
                                  >
                                    {provided => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.title}
                                        </td>
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.description}
                                        </td>{" "}
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.galleryAlbum?.name}
                                        </td>
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.galleryPhotoContent}
                                        </td>{" "}
                                        
                                        <td
                                          style={{ textAlign: "center" }}
                                          className="pt-4"
                                        >
                                          {row.image ? (
                                            <FileDisplay Value={row.image} />
                                          ) : (
                                            "-"
                                          )}
                                        </td>{" "}
                                        <td
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Row>
                                            <ButtonGroup
                                              className="mb-2"
                                              style={{ top: "4px" }}
                                            >
                                              <Button
                                                outline
                                                color="primary"
                                                className="mobileViewFont ml-2"
                                                onClick={() =>
                                                  this.handleEdit(row)
                                                }
                                              >
                                                Edit
                                              </Button>

                                              <Button
                                                outline
                                                color="danger"
                                                className="mobileViewFonts pl-1 pr-1 ml-2"
                                                onClick={() =>
                                                  this.toggleDeleteModal(row)
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </ButtonGroup>
                                          </Row>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Table>
                  </div>
                )}
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.galleryPhoto")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        GalleryPhoto " {""}
                        {this.state.galleryPhoto
                          ? this.state.galleryPhoto?.title
                          : ""}{" "}
                        " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() =>
                      this.deleteGalleryPhoto(this.state.galleryPhoto.id)
                    }
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="lg"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.GalleryPhoto")
                  : Message("add.GalleryPhoto")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={GalleryPhotoValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">
                                {Message("title")}
                              </Label>
                              <Field
                                className="form-control"
                                name="title"
                                type="text"
                              />
                              {errors.title && (
                                <div className="invalid-feedback d-block">
                                  {errors.title}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">
                                {Message("description")}
                              </Label>
                              <Field
                                className="form-control"
                                name="description"
                                type="text"
                              />
                              {errors.description && (
                                <div className="invalid-feedback d-block">
                                  {errors.description}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <DropDown
                                label={Message("album")}
                                MobcolSplit={12}
                                isClearable={true}
                                labelClassName="requiredField"
                                isSearchable
                                options={this.state.galleryAlbumList}
                                value={
                                  values &&
                                  values.albumId &&
                                  (typeof values.albumId === "string"
                                    ? this.state.galleryAlbumList.find(
                                        r => r.value === values.albumId
                                      )
                                    : this.state.galleryAlbumList.find(
                                        r => r.value === values.albumId
                                      ))
                                }
                                ClearAction={() => {
                                  setFieldValue(`albumId`, "")
                                }}
                                Action={entity => {
                                  if (entity) {
                                    setFieldValue("albumId", entity.value)
                                    // setFieldValue(`NextStates`, "")
                                  } else {
                                    setFieldValue(`albumId`, "")
                                  }
                                }}
                                errors={errors.albumId}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("content")}</Label>
                              <Field
                                className="form-control"
                                name="galleryPhotoContent"
                                type="text"
                              />
                              {errors.galleryPhotoContent && (
                                <div className="invalid-feedback d-block">
                                  {errors.galleryPhotoContent}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">{Message("image")}</Label>
                              <FormGroup>
                                <Input
                                  style={{ display: "none" }}
                                  id="Image"
                                  type="file"
                                  name="image"
                                  onChange={e => {
                                    this.setState({
                                      photo: e.target.files[0],
                                    })
                                    setFieldValue("image", e.target.files[0])
                                  }}
                                />
                                <label for="Image" class="btn btn-secondary">
                                  <i class="far fa-file"></i>
                                </label>
                                <br />
                                {values.image ? (
                                  <Label>{values.image?.name}</Label>
                                ) : (
                                  ""
                                )}
                                {errors.image && (
                                  <div className="invalid-feedback d-block">
                                    {errors.image}
                                  </div>
                                )}
                                {values.image && this.state.Label == "Edit" ? (
                                  <FileDisplay Value={values.image} />
                                ) : (
                                  ""
                                )}
                              </FormGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction ? "show-spinner " : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(GalleryPhoto)
