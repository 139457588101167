import { gql } from "@apollo/client"

export const getAllActivitiesQuery = gql`
  query getAllActivity {
    getAllActivities {
      items {
        id
        isDeleted
        sortId
        image
        title
        activityContent
        isActive
        activityTypeId
        activityType {
          id
          name
        }
        description
        error {
          statusCode
          message
        }
      }
    }
  }
`
export const deleteActivityMutation = gql`
  mutation deleteActivity($id: Int!) {
    deleteOneActivity(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOneActivityQuery = gql`
  query getActivity($id: Int!) {
    getOneActivity(id: $id) {
      id
      image
      description
      address
      album {
        name
      }
    }
  }
`
export const createActivityMutation = gql`
  mutation createOneActivity($data: activityInput) {
    createOneActivity(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updateActivityMutation = gql`
  mutation updateOneActivity($id: Int!, $data: activityInput) {
    updateOneActivity(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

export const getAllActivityTypesQuery = gql`
  query getAllActivityTypes {
    getAllActivityTypes {
      items {
        id
        name
      }
    }
  }
`
export const fileuploadQuery = gql`
  query getUploadUrl($type: String!, $fileName: String!) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`
export const UpdateManyMutation = gql`
  mutation updateManyActivities($data: [activityInput]) {
    updateManyActivities(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
