import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import ChangePassword from "../pages/Authentication/ChangePassword"
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword"

//Auth Protected Pages
import Achievement from "../pages/Achievement/Achievement"
import AlliedDepartment from "../pages/AlliedDepartment/AlliedDepartment"
import GalleryAlbum from "../pages/GalleryAlbum/GalleryAlbum"
import Components from "../pages/Component/Component"
import ComponentsMapped from "../pages/ComponentMapped/Component"

import GalleryVideo from "../pages/GalleryVideo/GalleryVideo"
import GalleryPhoto from "../pages/GalleryPhoto/GalleryPhoto"
import EducationalInstitution from "../pages/EducationalInstitution/EducationalInstitution"
import Slider from "pages/Slider/Slider"
import Team from "pages/Team/Team"
import Testimonials from "pages/Testimonials/Testimonials"
import CMSContent from "pages/CmsContent/CmsContent"
import OrderType from "pages/OrderType/OrderType"
import ActivityType from "pages/ActivityType/ActivityType"
import Activity from "pages/Activity/Activity"
import Pab from "pages/Pab/Pab"
import PABRejected from "pages/PabRejected/PabRejected"
import Phase from "pages/Phase/Phase"
import State from "pages/State/State"
import District from "pages/District/District"
import ComponentPABEducation from "pages/ComponentPABEducation/ComponentPABEducation"
import NAACAccredation from "pages/NaacAccredation/NaacAccredation"
import NAACAccredationFinancial from "pages/NaacAccredationFinancial/NaacAccredationFinancial"
import Faq from "pages/Faq/Faq"
import SanctionOrder from "../pages/SanctionOrder/list"
import Reviewmeeting from "../pages/ReviewMeeting/Reviewmeeting"
import Inspection from "pages/Inspection/Inspection"
const authProtectedRoutes = [
  { path: "/ChangePassword", component: ChangePassword },
  { path: "/component", component: Components },
  { path: "/achievement", component: Achievement },
  { path: "/allied-department", component: AlliedDepartment },
  { path: "/gallery-album", component: GalleryAlbum },
  { path: "/gallery-photo", component: GalleryPhoto },
  { path: "/slider", component: Slider },
  { path: "/team", component: Team },
  { path: "/pab", component: Pab },
  { path: "/faq", component: Faq },
  { path: "/phase", component: Phase },
  { path: "/state", component: State },
  { path: "/sanction-order", component: SanctionOrder },
  { path: "/district", component: District },
  { path: "/cms-content", component: CMSContent },
  { path: "/ordertype", component: OrderType },
  { path: "/activitytype", component: ActivityType },
  { path: "/activity", component: Activity },
  { path: "/testimonial", component: Testimonials },
  { path: "/pab-cancelled", component: PABRejected },
  { path: "/gallery-video", component: GalleryVideo },
  { path: "/component-pab-education", component: ComponentPABEducation },
  { path: "/educational-institution", component: EducationalInstitution },
  { path: "/naac-accredation", component: NAACAccredation },
  { path: "/naac-accredation-financial", component: NAACAccredationFinancial },
  { path: "/review-meetings", component: Reviewmeeting },
  { path: "/inspection", component: Inspection },

  { path: "/componentmapped", component: ComponentsMapped },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/achievement" />,
  },
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
]

export { authProtectedRoutes, publicRoutes }
