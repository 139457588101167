import { gql } from "@apollo/client"

export const getAllTestimonialssQuery = gql`
  query getAllTestimonial(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllTestimonials(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        personName
        personCompany
        personDesignation
        isActive
        image
        testimonialContent
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const deleteTestimonialsMutation = gql`
  mutation deleteOneTestimonial($id: Int!) {
    deleteOneTestimonial(id: $id) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const getOneTestimonialsQuery = gql`
  query getTestimonials($id: Int!) {
    getOneTestimonials(id: $id) {
      id
      personName
      personCompany
      personDesignation
      isActive
      image
    }
  }
`
export const createTestimonialsMutation = gql`
  mutation createOneTestimonial($data: testimonialsInput) {
    createOneTestimonial(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updateTestimonialsMutation = gql`
  mutation updateOneTestimonial($id: Int!, $data: testimonialsInput) {
    updateOneTestimonial(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const UpdateManyMutation = gql`
  mutation updateManyTestimonials($data: [testimonialsInput]) {
    updateManyTestimonials(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
