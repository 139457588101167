export const MethodType = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
  FORMDATA: "FORMDATA",
}

export const StatusCodes = {
  Error: "500",
  InvalidData: "422",
  Success: "200",
  Unauthorized: "401",
  InvalidMediaType: "415",
  Forbidden: "403",
  NotFound: "404",
  Duplicate: "409",
  NotAllowed: "405",
}

export const instituteType = [
  { label: "University", value: "University" },
  { label: "College", value: "College" },
]

export const PeriodListFilter = [
  { label: "2017-18", value: "2017-18" },
  { label: "2018-19", value: "2018-19" },
  { label: "2019-20", value: "2019-20" },
  { label: "2020-21", value: "2020-21" },
  { label: "2021-22", value: "2021-22" },
  { label: "2022-23", value: "2022-23" },
]

export const dateFormat = "dd/MM/yyyy"

export const PasswordRegex = ""

export const masterListPageTableTheme = {
  rows: {
    height: "35px",
    cursor: "pointer",
  },
}
