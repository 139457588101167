import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
} from "reactstrap"
import ListPageHeader from "../../components/custom/ListPageHeader"
import { StatusCodes } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { ComponentValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import DropDown from "../../components/custom/DropDown"
import CustomSelectInput from "../../components/Common/CustomSelectInput"

import {
  createPhaseComponentMutation,
  updatePhaseComponentMutation,
  deletePhaseComponentMutation,
  getAllComponentsQuery,
  getAllPhasesQuery,
  getAllPhaseComponentsQuery
} from "./Queries"
import MetaTags from "react-meta-tags"
class Components extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 25, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      componentList: [{ id: "1" }, { id: "2" }],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 10,
      },
      component: {
        name: "",
        phaseId: null,
        componentId: null,
      },
      columns: [
        {
          name: "Name",
          selector: "name",
          sortable: true,
          cell: row => <span>{row.name ? row.name : ""}</span>,
        },
        {
          name: "Phase",
          selector: "phase",
          sortable: true,
          size: "5%",
          cell: row => <span>{row.phase ? row.phase.name : ""}</span>,
        },
        {
          name: "Component",
          selector: "component",
          sortable: true,
          cell: row => <span>{row.component ? row.component.name : ""}</span>,
        },
        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: row => (
            <Row>
              <ButtonGroup className="mb-2" style={{ top: "4px" }}>
                <Button
                  outline
                  color="primary"
                  className="mobileViewFont ml-2"
                  onClick={() => this.handleEdit(row)}
                >
                  Edit
                </Button>

                <Button
                  outline
                  color="danger"
                  className="mobileViewFonts pl-1 pr-1 ml-2"
                  onClick={() => this.toggleDeleteModal(row)}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Row>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    this.getAllComponents()
    this.getAllPhaseComponents()

    this.getAllPhases()
  }

  getAllPhaseComponents = async value => {
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllPhaseComponentsQuery, values)


    if (response?.data?.getAllPhaseComponents?.items) {
      this.setState({
        isLoading: false,
        totalPage:
          response.data.getAllPhaseComponents.pageLimit /
          response.data.getAllPhaseComponents.pageLimit,
        phaseComponentList: response.data.getAllPhaseComponents.items,
        totalPage:
          response?.data?.getAllPhaseComponents?.pagination?.totalCount /
          response?.data?.getAllPhaseComponents?.pagination?.pageLimit,
        totalCount: response?.data?.getAllPhaseComponents?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }










  getAllComponents = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllComponentsQuery, "")
    var componentsValue = response?.data?.getAllComponents?.items
    if (componentsValue) {
      this.setState({
        isLoading: false,
        componentList: componentsValue,
        componentoptions: componentsValue.map(function (a) {
          return { value: a.id, label: a.name }
        })
      })
    }
  }


  getAllPhases = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllPhasesQuery, values)
    var phasesValue = response?.data?.getAllPhases?.items
    if (phasesValue) {
      this.setState({
        phaseList: phasesValue.map(function (a) {
          return { value: a.id, label: a.name }
        }),
      })
    } else
      this.setState({
        isLoading: false,
      })
  }



  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllPhaseComponents()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllPhaseComponents()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllPhaseComponents()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllPhaseComponents()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        component: row,
        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })

    var EditVal = {
      id: values.id,
      data: {
        name: values.name,
        phaseId: values.phaseId,
        componentId: values.componentId
      },
    }

    const response = values.id
      ? await MutateRequest(updatePhaseComponentMutation, EditVal)
        .then(
          r =>
            this.setState({
              // manageEditModal: !this.state.manageEditModal,
              buttonAction: false,
            }),
          SuccessMessage(Message("Component.update"))
        )
        .catch(e => ErrorMessage(e))
      : await MutateRequest(createPhaseComponentMutation, { data: values })
        .then(r => {
          this.setState({
            buttonAction: false,
            // manageEditModal: !this.state.manageEditModal,
          })
          SuccessMessage(Message("Component.add"))
        })
        .catch(e => ErrorMessage(e))
    var componentValue = response?.data
    if (componentValue && values.id) {
      // SuccessMessage(Message("component.update")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllPhaseComponents()
    } else {
      // SuccessMessage(Message("component.add")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllPhaseComponents()
    }
  }

  addBtnClick = () => {
    this.setState({
      component: {
        name: "",
        phaseId: null,
        componentId: null,
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      component: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      component: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      component: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteComponent = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deletePhaseComponentMutation, values)
    var componentdeleteValue = response?.data
    if (componentdeleteValue.deleteOnePhaseComponent.status === "Success") {
      SuccessMessage(Message("component.delete"))
      this.getAllPhaseComponents()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageComponentResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.component.id
        ? SuccessMessage(Message("Component.update"))
        : SuccessMessage(Message("Component.add"))

      this.getAllPhaseComponents()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      componentDetail: url ? url : "",
    })
  }
  render() {
    const initialValues = this.state.component


    console.log("initialValues"+JSON.stringify(initialValues))
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Components</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"Components Mapping"}`}
                    buttonText={"Add Components Mapping"}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                <ListPage
                  conditionalRowStyles={conditionalRowStyles}
                  columns={this.state.columns}
                  data={this.state.phaseComponentList}
                  keyField={this.state.keyField}
                  totalCount={this.state.totalCount}
                  rowClicked={this.HandleRowClicked}
                  onSort={this.handleSort}
                  pageLimit={this.state.pageLimit}
                  pageSizeOptions={this.state.pageSizeOptions}
                  rowsPerPageOnChange={this.handlePerRowsChange}
                  pageChange={this.handlePageChange}
                  isDataLoading={this.state.isLoading}
                  overFlowXRemoval={true}
                />
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.component")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        Component " {""}
                        {this.state.component
                          ? this.state.component.name
                          : ""}{" "}
                        " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() =>
                      this.deleteComponent(this.state.component.id)
                    }
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="lg"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.Component")
                  : Message("add.Component")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={ComponentValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>

                          <Col lg="12" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">{"Name"}</Label>
                              <Field
                                className="form-control"
                                name="name"
                                type="text"
                              />
                              {/* {errors.name && (
                                <div className="invalid-feedback d-block">
                                  {errors.name}
                                </div>
                              )} */}
                            </FormGroup>
                          </Col>



                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <DropDown
                                label={"Phase"}
                                components={{
                                  Input: CustomSelectInput,
                                }}
                                labelClassName="requiredField"
                                className="react-select"
                                classNamePrefix="react-select"
                                isSearchable
                                isClearable={true}
                                value={
                                  values.phaseId &&
                                  (typeof values.phaseId === "string"
                                    ? this.state.phaseList.find(
                                      r => r.value === values.phaseId
                                    )
                                    : this.state.phaseList.find(
                                      r => r.value === values.phaseId
                                    ))
                                }
                                options={this.state.phaseList}
                                ClearAction={() => {
                                  setFieldValue(`phaseId`, ""),
                                    this.setState(
                                      {
                                        phaseComponent: null,
                                      },
                                      () => this.getAllPhaseComponents()
                                    )
                                }}
                                Action={entity => {
                                  if (entity) {
                                    this.setState(
                                      {
                                        phaseComponent: entity ? entity.value : "",
                                      })
                                    setFieldValue("phaseId", entity.value)
                                  } else {
                                    this.setState(
                                      {
                                        phaseComponent: "",
                                      },

                                    )
                                    setFieldValue("phaseId", "")
                                  }
                                }}
                              // errors={errors?.componentId}
                              />
                            </FormGroup>{" "}
                          </Col>


                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <DropDown
                                label={"Component"}
                                components={{
                                  Input: CustomSelectInput,
                                }}
                                labelClassName="requiredField"
                                className="react-select"
                                classNamePrefix="react-select"
                                isSearchable
                                isClearable={true}
                                value={
                                  values.componentId &&
                                  (typeof values.componentId === "string"
                                    ? this.state.componentoptions.find(
                                      r => r.value === values.componentId
                                    )
                                    : this.state.componentoptions.find(
                                      r => r.value === values.componentId
                                    ))
                                }
                                options={this.state.componentoptions}
                                ClearAction={() => {
                                  setFieldValue(`componentId`, ""),
                                    this.setState(
                                      {
                                        phaseComponent: null,
                                      },
                                      () => this.getAllPhaseComponents()
                                    )
                                }}
                                Action={entity => {
                                  if (entity) {
                                    this.setState(
                                      {
                                        phaseComponent: entity ? entity.value : "",
                                      })
                                    setFieldValue("componentId", entity.value)
                                  } else {
                                    this.setState(
                                      {
                                        phaseComponent: "",
                                      },

                                    )
                                    setFieldValue("componentId", "")
                                  }
                                }}
                              // errors={errors?.componentId}
                              />
                            </FormGroup>{" "}
                          </Col>




                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${this.state.buttonAction
                                  ? "show-spinner  disabled"
                                  : ""
                                  }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${this.state.buttonAction ? "show-spinner " : ""
                                  }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(Components)
