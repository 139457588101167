import React, { Component, Fragment } from "react"
import { Formik, Form, Field } from "formik"
import { withRouter } from "react-router-dom"
import {
  Row,
  ButtonGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  Col,
  Input,
} from "reactstrap"
import ListPageHeader from "../../components/custom/ListPageHeader"
import { StatusCodes } from "../../constants/defaultValues"
import ListPage from "../../components/custom/ListPage"
import { NAACAccredationValidation } from "../../helpers/validations"
import { Message } from "../../helpers/language_helper"
import DropDown from "../../components/custom/DropDown"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { unixdateconversion } from "../../helpers/utils"
import CustomSelectInput from "../../components/Common/CustomSelectInput"
import { SuccessMessage, ErrorMessage } from "../../helpers/notifications"
import { QueryRequest, MutateRequest } from "../../helpers/service_helper"
import {
  createNAACAccredationMutation,
  updateNAACAccredationMutation,
  deleteNAACAccredationMutation,
  getAllNAACAccredationsQuery,
  getAllEducationalInstitutesQuery,
} from "./Queries"
import MetaTags from "react-meta-tags"
class NAACAccredation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpage: 1,
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "ID",
      viewModal: false,
      NAACAccredationList: [],
      educationalInstituteList: [],

      listRequestModel: {
        searchString: "",
        sortCondition: null,
        pageNumber: 1,
        pageLimit: 10,
      },
      NAACAccredation: {
        educationalInstituteId: "",
        isBeneficiary: false,
        naacScore: "",
        grade: "",
        yearEstablished: "",
        validity: "",
      },
      columns: [
        {
          name: "Educational Institute",
          selector: "educationalInstitute",
          sortable: true,
          cell: row => (
            <span>
              {row.educationalInstitute?.name
                ? row.educationalInstitute?.name
                : ""}
            </span>
          ),
        },
        {
          name: "Beneficiary",
          selector: "isBeneficiary",
          sortable: true,
          cell: row => (
            <span>
              {row.isBeneficiary == true
                ? "RUSA Beneficiary"
                : row.isBeneficiary == false
                ? "RUSA Non Beneficiary"
                : ""}
            </span>
          ),
        },
        {
          name: "Naac Score",
          selector: "naacScore",
          sortable: true,
          cell: row => <span>{row.naacScore ? row.naacScore : ""}</span>,
        },
        {
          name: "Grade",
          selector: "grade",
          sortable: true,
          cell: row => <span>{row.grade ? row.grade : ""}</span>,
        },
        {
          name: "Year Eshtablished",
          selector: "yearEstablished",
          sortable: true,
          cell: row => (
            <span>{row.yearEstablished ? row.yearEstablished : ""}</span>
          ),
        },
        // {
        //   name: "Validity",
        //   selector: "validity",
        //   sortable: true,
        //   cell: row => <span>{row.validity? row.validity : ""}</span>,
        // },
        {
          name: "Validity",
          selector: "validity",
          sortable: true,

          cell: row => (
            <div>
              <span>
                {row.validity
                  ? unixdateconversion(row.validity, {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : "-"}
              </span>
            </div>
          ),
        },
        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: row => (
            <Row>
              <ButtonGroup className="mb-2" style={{ top: "4px" }}>
                <Button
                  outline
                  color="primary"
                  className="mobileViewFont ml-2"
                  onClick={() => this.handleEdit(row)}
                >
                  Edit
                </Button>

                <Button
                  outline
                  color="danger"
                  className="mobileViewFonts pl-1 pr-1 ml-2"
                  onClick={() => this.toggleDeleteModal(row)}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Row>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    this.getAllNAACAccredations()
    this.getAllEducationalInstitutes()
  }
  getAllNAACAccredations = async value => {
    this.setState({
      isLoading: true,
    })
    const { listRequestModel } = this.state
    var values = listRequestModel
    const response = await QueryRequest(getAllNAACAccredationsQuery, values)
    console.log(response)
    var NAACAccredationsValue = response?.data?.getAllNAACAccredations?.items

    if (NAACAccredationsValue) {
      this.setState({
        isLoading: false,
        NAACAccredationList: NAACAccredationsValue,
        totalPage:
          response?.data?.getAllNAACAccredations?.pagination?.totalCount /
          response?.data?.getAllNAACAccredations?.pagination?.pageLimit,
        totalCount:
          response?.data?.getAllNAACAccredations?.pagination?.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  getAllEducationalInstitutes = async value => {
    const response = await QueryRequest(getAllEducationalInstitutesQuery, "")
    if (response?.data?.getAllEducationalInstitutes?.items) {
      this.setState({
        //isLoading: false,
        //totalPage:
        //  response.data.getAllDistricts.pageLimit /
        //  response.data.getAllDistricts.pageLimit,
        educationalInstituteList:
          response.data.getAllEducationalInstitutes.items.map(function (a) {
            return {
              label: a.name,
              value: a.id,
              instituteType: a.instituteType,
            }
          }),
        //totalCount: response.data.getAllDistricts.totalCount,
      })
    } else
      this.setState({
        isLoading: false,
      })
  }

  /** handle list page  start  */
  handleSort = (column, sortDirection) => {
    var sortObj = {}
    sortObj[column.selector] = sortDirection === "asc" ? "asc" : "desc"
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.getAllNAACAccredations()
      }
    )
  }

  handlePerRowsChange = async perPage => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.getAllNAACAccredations()
      }
    )
  }

  handlePageChange = async page => {
    this.setState(
      {
        pageNumber: page,
        listRequestModel: {
          ...this.state.listRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.getAllNAACAccredations()
      }
    )
  }

  searchQueryChanged = search => {
    this.setState(
      {
        listRequestModel: {
          ...this.state.listRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.listRequestModel.searchString === "" ||
          this.state.listRequestModel.searchString.length > 2
        ) {
          this.getAllNAACAccredations()
        }
      }
    )
  }

  handleEdit = row => {
    if (row) {
      this.setState({
        NAACAccredation: row,
        validity: row.validity ? new Date(parseInt(row.validity)) : "",
        manageModal: !this.state.manageModal,
        IsEdited: true,
        Label: "Edit",
      })
    }
  }

  /** Submit Function Start */
  handleSubmit = async values => {
    this.setState({
      buttonAction: true,
    })
    var AddData = {
      educationalInstituteId: values.educationalInstituteId,
      isBeneficiary: values.isBeneficiary,
      naacScore: parseFloat(values.naacScore, 10),
      grade: values.grade,
      yearEstablished: values.yearEstablished,
      validity: values.validity,
    }
    var EditVal = {
      id: values.id,
      data: {
        educationalInstituteId: values.educationalInstituteId,
        isBeneficiary: values.isBeneficiary,
        naacScore: parseFloat(values.naacScore, 10),
        grade: values.grade,
        yearEstablished: values.yearEstablished,
        validity: values.validity,
      },
    }
    const response = values.id
      ? await MutateRequest(updateNAACAccredationMutation, EditVal)
          .then(
            r =>
              this.setState({
                // manageEditModal: !this.state.manageEditModal,
                buttonAction: false,
              }),
            SuccessMessage(Message("NAACAccredation.update"))
          )
          .catch(e => ErrorMessage(e))
      : await MutateRequest(createNAACAccredationMutation, {
          data: AddData,
        })
          .then(r => {
            this.setState({
              buttonAction: false,
              // manageEditModal: !this.state.manageEditModal,
            })
            SuccessMessage(Message("NAACAccredation.add"))
          })
          .catch(e => ErrorMessage(e))
    var NAACAccredationValue = response?.data
    if (NAACAccredationValue && values.id) {
      // SuccessMessage(Message("NAACAccredation.update")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllNAACAccredations()
    } else {
      // SuccessMessage(Message("NAACAccredation.add")),
      this.setState({
        manageModal: !this.state.manageModal,
      })
      this.getAllNAACAccredations()
    }
  }

  addBtnClick = () => {
    this.setState({
      NAACAccredation: {
        educationalInstituteId: "",
        isBeneficiary: false,
        naacScore: "",
        grade: "",
        yearEstablished: "",
        validity: "",
      },
      manageModal: !this.state.manageModal,
      Label: "Add",
    })
  }

  toggleDeleteModal = row => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      NAACAccredation: row,
    })
  }

  toggleManageModal = () => {
    this.setState({
      NAACAccredation: {},
      manageModal: !this.state.manageModal,
    })
  }

  toggleEditManageModal = () => {
    this.setState({
      NAACAccredation: {},
      manageEditModal: !this.state.manageEditModal,
    })
  }

  deleteNAACAccredation = async id => {
    var values = {
      id: id,
    }
    const response = await MutateRequest(deleteNAACAccredationMutation, values)
    var NAACAccredationdeleteValue = response?.data
    if (
      NAACAccredationdeleteValue.deleteOneNAACAccredation.status === "Success"
    ) {
      SuccessMessage(Message("NAACAccredation.delete"))
      this.getAllNAACAccredations()
      this.setState({
        buttonAction: false,
        deleteModal: !this.state.deleteModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  manageNAACAccredationResponse = data => {
    if (data.statusCode === StatusCodes.Success) {
      this.state.NAACAccredation.id
        ? SuccessMessage(Message("NAACAccredation.update"))
        : SuccessMessage(Message("NAACAccredation.add"))

      this.getAllNAACAccredations()
      this.setState({
        buttonAction: false,
        manageModal: !this.state.manageModal,
      })
    } else {
      ErrorMessage(Message("api.error"))
      this.setState({
        buttonAction: false,
      })
    }
  }

  toggleViewModal = url => {
    this.setState({
      viewModal: !this.state.viewModal,
      documentView: url,
      NAACAccredationDetail: url ? url : "",
    })
  }
  render() {
    const initialValues = this.state.NAACAccredation
    const conditionalRowStyles = [
      {
        when: row => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ]
    return (
      <Fragment>
        <div className="page-content">
          <MetaTags>
            <title>NAAC Accredation Institution</title>
          </MetaTags>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="PoliceView">
                  <ListPageHeader
                    heading={`${"Home"}.${"NAAC Accredation Institution"}`}
                    buttonText={Message("add.NAACAccredation")}
                    onTextChange={this.searchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.listRequestModel.searchString}
                  />
                </div>
                <Row>
                  <Col sm="12" md="6" lg="3">
                    {" "}
                    <DropDown
                      label={Message("EducationalInstitute")}
                      MobcolSplit={12}
                      isClearable={true}
                      isSearchable
                      options={this.state.educationalInstituteList}
                      ClearAction={() => {
                        this.setState(
                          {
                            listRequestModel: {
                              ...this.state.listRequestModel,
                              educationalInstituteId: undefined,
                            },
                          },
                          () => this.getAllNAACAccredations()
                        )
                      }}
                      Action={entity => {
                        if (entity) {
                          this.setState(
                            {
                              listRequestModel: {
                                ...this.state.listRequestModel,
                                educationalInstituteId: entity.value,
                              },
                            },
                            () => this.getAllNAACAccredations()
                          )
                        } else {
                          this.setState(
                            {
                              listRequestModel: {
                                ...this.state.listRequestModel,
                                educationalInstituteId: undefined,
                              },
                            },
                            () => this.getAllNAACAccredations()
                          )
                        }
                      }}
                    />
                  </Col>
                </Row>
                <div className="PoliceView">
                  <ListPage
                    conditionalRowStyles={conditionalRowStyles}
                    columns={this.state.columns}
                    data={this.state.NAACAccredationList}
                    keyField={this.state.keyField}
                    totalCount={this.state.totalCount}
                    rowClicked={this.HandleRowClicked}
                    onSort={this.handleSort}
                    rowsPerPageOnChange={this.handlePerRowsChange}
                    pageChange={this.handlePageChange}
                    isDataLoading={this.state.isLoading}
                    overFlowXRemoval={true}
                  />
                </div>
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {Message("delete.NAACAccredation")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5>
                        {Message("delete.confirmation")} {""}
                        NAACAccredation " {""}
                        {this.state.NAACAccredation
                          ? this.state.NAACAccredation.educationalInstitute
                              ?.name
                          : ""}{" "}
                        " ?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() =>
                      this.deleteNAACAccredation(this.state.NAACAccredation.id)
                    }
                  >
                    {Message("yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    {Message("no")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size="lg"
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.Label == "Edit"
                  ? Message("update.NAACAccredation")
                  : Message("add.NAACAccredation")}
              </ModalHeader>
              <br />

              <Formik
                initialValues={initialValues}
                validationSchema={NAACAccredationValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          <Col lg="6" sm="12" md="12" lg="6">
                            <FormGroup className="form-group has-float-label">
                              <DropDown
                                label={Message("EducationalInstitute")}
                                components={{
                                  Input: CustomSelectInput,
                                }}
                                labelClassName="requiredField"
                                className="react-select"
                                classNamePrefix="react-select"
                                isSearchable
                                isClearable={true}
                                value={
                                  values.educationalInstituteId &&
                                  (typeof values.educationalInstitutetId ===
                                  "string"
                                    ? this.state.educationalInstituteList.find(
                                        r =>
                                          r.value ===
                                          values.educationalInstituteId
                                      )
                                    : this.state.educationalInstituteList.find(
                                        r =>
                                          r.value ===
                                          values.educationalInstituteId
                                      ))
                                }
                                options={this.state.educationalInstituteList}
                                ClearAction={() => {
                                  setFieldValue(`educationalInstituteId`, ""),
                                    this.setState(
                                      {
                                        educationalInstitute: null,
                                      },
                                      () => this.getAllEducationalInstitutes()
                                    )
                                }}
                                Action={entity => {
                                  if (entity) {
                                    this.setState(
                                      {
                                        educationalInstitute: entity
                                          ? entity.value
                                          : "",
                                      },
                                      () => this.getAllEducationalInstitutes()
                                    )
                                    setFieldValue(
                                      "educationalInstituteId",
                                      entity.value
                                    )
                                  } else {
                                    this.setState(
                                      {
                                        educationalInstitute: "",
                                      },
                                      () => this.getAllEducationalInstitutes()
                                    )
                                    setFieldValue("educationalInstituteId", "")
                                  }
                                }}
                                errors={errors?.educationalInstituteId}
                              />
                            </FormGroup>{" "}
                          </Col>

                          <Col lg="6" sm="12" md="12" lg="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("naacScore")}</Label>
                              <Field
                                className="form-control"
                                name="naacScore"
                                type="text"
                              />
                              {errors.naacScore && (
                                <div className="invalid-feedback d-block">
                                  {errors.naacScore}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12" lg="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("grade")}</Label>
                              <Field
                                className="form-control"
                                name="grade"
                                type="text"
                              />
                              {errors.grade && (
                                <div className="invalid-feedback d-block">
                                  {errors.grade}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12" lg="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">
                                {Message("yearEstablished")}
                              </Label>
                              <Field
                                className="form-control"
                                name="yearEstablished"
                                type="text"
                              />
                              {errors.yearEstablished && (
                                <div className="invalid-feedback d-block">
                                  {errors.yearEstablished}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          {/* <Col lg="6" sm="12" md="12" lg="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">{Message("validity")}</Label>
                              <Field
                                className="form-control"
                                name="validity"
                                type="text"
                              />
                              {errors.validity && (
                                <div className="invalid-feedback d-block">
                                  {errors.validity}
                                </div>
                              )}
                            </FormGroup>
                          </Col> */}
                          <Col lg="6" sm="12" md="12" lg="6">
                            <FormGroup className="mt-2 form-group has-float-label">
                              <Label className="">Validity</Label>
                              <DatePicker
                                className="form-control box-border"
                                selected={this.state.validity}
                                onChange={date => {
                                  this.setState({
                                    validity: date,
                                  })
                                  if (date)
                                    setFieldValue(
                                      `validity`,
                                      new Date(date).valueOf().toString()
                                    )
                                  else setFieldValue(`validity`, "")
                                }}
                                dateFormat="dd/MM/yyyy"
                              />
                              {errors.validity && (
                                <div className="invalid-feedback d-block">
                                  {errors.validity}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12" md="12">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">
                                {Message("beneficiary")}
                              </Label>
                              <FormGroup className="custom-control custom-switch mt-1 ml-2">
                                <Input
                                  autoComplete="off"
                                  type="checkbox"
                                  className="custom-control-input ml-2 "
                                  id="IsBeneficiary"
                                  defaultChecked={values.isBeneficiary}
                                  onChange={e => {
                                    setFieldValue(
                                      `isBeneficiary`,
                                      e.target.checked
                                    )
                                  }}
                                  onClick={e => {
                                    setFieldValue(
                                      "isBeneficiary",
                                      !values.isBeneficiary
                                    )
                                  }}
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="IsBeneficiary"
                                ></Label>
                              </FormGroup>
                              {errors.isBeneficiary && (
                                <div className="invalid-feedback d-block">
                                  {errors.isBeneficiary}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          {/* <Col lg="6" sm="12" md="12" lg="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="">
                                {Message("InstituteType")}
                              </Label>
                              <Field
                                className="form-control"
                                name="instituteType"
                                type="text"
                              />
                              {errors.instituteType && (
                                <div className="invalid-feedback d-block">
                                  {errors.instituteType}
                                </div>
                              )}
                            </FormGroup>
                          </Col> */}
                        </Row>
                      </Fragment>
                    </ModalBody>
                    <ModalFooter>
                      <div className="container-fluid">
                        <Row>
                          <Col lg="">
                            <Label>
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *{" "}
                              </span>
                              {Message("mandatoryFields")}
                            </Label>
                          </Col>

                          <FormGroup className="float-right ">
                            {this.state.Label == "Edit" ? (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction
                                    ? "show-spinner  disabled"
                                    : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">
                                  {Message("update")}
                                </span>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary"
                                className={`btn-shadow btn-multiple-state ${
                                  this.state.buttonAction ? "show-spinner " : ""
                                }`}
                              >
                                {" "}
                                <span className="spinner d-inline-block">
                                  <span className="bounce1" />
                                  <span className="bounce2" />
                                  <span className="bounce3" />
                                </span>
                                <span className="label ">{Message("add")}</span>
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {Message("cancel")}
                            </Button>
                          </FormGroup>
                        </Row>
                      </div>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(NAACAccredation)
