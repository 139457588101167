import * as Yup from "yup"
import { Message } from "../helpers/language_helper"

export const userPageValidation = Yup.object().shape({
  name: Yup.string().trim().required(Message("Name.required")).nullable(),
  mobileNumber: Yup.string()
    .trim()
    .required(Message("mobileNumber.required"))
    .nullable(),
})

export const loginValidation = Yup.object().shape({
  userName: Yup.string().required(Message("username.required")),
  password: Yup.string().required(Message("password.required")),
  // .min(8, Message("password.lengthinvalid"))
  // .matches(
  //  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  //  Message("password.invalid")
  // ),
})

export const changePasswordValidation = Yup.object().shape({
  Password: Yup.string().required(Message("Password.required")),
  NewPassword: Yup.string().required(Message("NewPassword.required")),
  ConfirmPassword: Yup.string().required(Message("ConfirmPassword.required")),
})
export const forcePasswordChangeValidation = Yup.object().shape({
  Password: Yup.string().required(Message("Password.required")),
  NewPassword: Yup.string().required(Message("NewPassword.required")),
  ConfirmPassword: Yup.string().required(Message("ConfirmPassword.required")),
})
export const forgotPasswordValidation = Yup.object().shape({
  Username: Yup.string().required(Message("username.required")),
})

export const confirmPasswordValidation = Yup.object().shape({
  NewPassword: Yup.string().required(Message("NewPassword.required")),
  ConfirmPassword: Yup.string().required(Message("ConfirmPassword.required")),
})

export const homepageImageValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
  image: Yup.string().required(Message("Name.required")),
  isActive: Yup.string().required(Message("department.required")).nullable(),
})

export const AchievementValidation = Yup.object().shape({
  title: Yup.string().required(Message("title.required")),
})

export const AlliedDepartmentValidation = Yup.object().shape({
  name: Yup.string().required(Message("name.required")),
  departmentImage: Yup.string().required(Message("departmentImage.required")),
  link: Yup.string().required(Message("link.required")),
})

export const CMSContentValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
})

export const ComponentValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
})

export const DistrictValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
  stateId: Yup.string().required(Message("state.required")),
})

export const EducationalInstitutionValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
  instituteType: Yup.string().required(Message("instituteType.required")),
  districtId: Yup.string().required(Message("district.required")),
})

export const FaqValidation = Yup.object().shape({
  answer: Yup.string().required(Message("answer.required")),
  question: Yup.string().required(Message("question.required")),
})

export const GalleryAlbumValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
})
export const SanctionOrderValidation = Yup.object().shape({
  title: Yup.string().required(Message("title.required")),
  fileURL: Yup.string().required(Message("fileURL.required")),
  orderTypeId: Yup.string().required(Message("orderType.required")),
})

export const GalleryPhotoValidation = Yup.object().shape({
  image: Yup.string().required(Message("image.required")),
  title: Yup.string().required(Message("title.required")),
  albumId: Yup.string().required(Message("albumId.required")),
})

export const ActivityValidation = Yup.object().shape({
  activityTypeId: Yup.string().required(Message("activityTypeId.required")),
  title: Yup.string().required(Message("title.required")).nullable(),
})

export const GalleryVideoValidation = Yup.object().shape({
  title: Yup.string().required(Message("title.required")),
  video: Yup.string().required(Message("video.required")),
  albumId: Yup.string().required(Message("albumId.required")),
})

export const OrderTypeValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
})

export const ActivityTypeValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
})

export const PabValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
})

export const PhaseValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
})

export const SliderValidation = Yup.object().shape({
  title: Yup.string().required(Message("title.required")),
  image: Yup.string().required(Message("image.required")),
})

export const StateValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
})

export const taskMasterValidation = Yup.object().shape({
  taskName: Yup.string().required(Message("Name.required")),
})

export const TeamValidation = Yup.object().shape({
  name: Yup.string().required(Message("Name.required")),
  designation: Yup.string().required(Message("designation.required")),
  rank: Yup.string().required(Message("rank.required")),
})

export const TestimonialsValidation = Yup.object().shape({
  personCompany: Yup.string().required(Message("personCompany.required")),
  personDesignation: Yup.string().required(
    Message("personDesignation.required")
  ),
  personName: Yup.string().required(Message("personName.required")),
  testimonialContent: Yup.string().required(
    Message("testimonialContent.required")
  ),
})

export const ComponentEducationPABValidation = Yup.object().shape({
  educationalInstituteId: Yup.string().required(
    Message("educationalInstituteId.required")
  ),
  componentId: Yup.string().required(Message("componentId.required")),
  pabId: Yup.string().required(Message("pabId.required")),
})

export const NAACAccredationValidation = Yup.object().shape({
  educationalInstituteId: Yup.string().required(
    Message("educationalInstituteId.required")
  ),
})

export const NAACAccredationFinancialValidation = Yup.object().shape({
  educationalInstituteId: Yup.string().required(
    Message("educationalInstituteId.required")
  ),
})

export const PABRejectedValidation = Yup.object().shape({
  educationalInstituteId: Yup.string().required(
    Message("educationalInstituteId.required")
  ),
  pabApprovedId: Yup.string().required(Message("pabApprovedId.required")),
  componentId: Yup.string().required(Message("componentId.required")),
})
export const ReviewMeetingValidation = Yup.object().shape({
  conductedBy: Yup.string().required(Message("conductedBy.required")),
  heldDate: Yup.string().required(Message("heldDate.required")),
  description: Yup.string().required(Message("description.required")),
})
