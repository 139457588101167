import { gql } from "@apollo/client"

export const getAllPhasesQuery = gql`
  query getAllPhases(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllPhases(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        name
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`
export const getAllComponentsQuery = gql`
  query getAllComponents(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllComponents(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        name
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`

export const getAllPhaseComponentQuery = gql`
  query getAllPhaseComponents($phaseId: Int) {
    getAllPhaseComponents(phaseId: $phaseId) {
      items {
        id
        phaseId
        componentId
      }
    }
  }
`

export const deletePhaseMutation = gql`
  mutation deletePhase($id: Int!) {
    deleteOnePhase(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`
export const getOnePhaseQuery = gql`
  query getPhase($id: Int!) {
    getOnePhase(id: $id) {
      id
      name
    }
  }
`
export const createPhaseMutation = gql`
  mutation createOnePhase($data: phaseInput) {
    createOnePhase(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const createPhaseComponentMutation = gql`
  mutation createManyPhaseComponents($data: [phaseComponentInput]) {
    createManyPhaseComponents(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const deletePhaseComponentMutation = gql`
  mutation deleteManyPhaseComponents($ids: [Int]) {
    deleteManyPhaseComponents(ids: $ids) {
      id
      status
      error {
        message
      }
    }
  }
`

export const updatePhaseMutation = gql`
  mutation updatePhase($id: Int!, $data: phaseInput) {
    updateOnePhase(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`
