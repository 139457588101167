import { gql } from "@apollo/client"

export const getAllComponentsQuery = gql`
  query getAllComponents(
    $searchString: String
    $pageNumber: Float
    $pageLimit: Float
  ) {
    getAllComponents(
      searchString: $searchString
      pageNumber: $pageNumber
      pageLimit: $pageLimit
    ) {
      items {
        id
        name
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`

export const getAllPhaseComponentsQuery = gql`
  query getAllPhaseComponents(
    $searchString: String
    $pageNumber: Float
    $pageLimit: Float
  ) {
    getAllPhaseComponents(
      searchString: $searchString
      pageNumber: $pageNumber
      pageLimit: $pageLimit
    ) {
      items {
        id
        name
        phaseId
        componentId
        phase {
          id
          name
        }
        component {
          id
          name
        }
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`

export const getAllPhasesQuery = gql`
  query getAllPhases(
    $searchString: String
    $pageLimit: Float
    $pageNumber: Float
  ) {
    getAllPhases(
      searchString: $searchString
      pageLimit: $pageLimit
      pageNumber: $pageNumber
    ) {
      items {
        id
        name
      }
      pagination {
        pageNumber
        pageLimit
        totalCount
      }
    }
  }
`

export const createPhaseComponentMutation = gql`
  mutation createOnePhaseComponent($data: phaseComponentInput) {
    createOnePhaseComponent(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

export const updatePhaseComponentMutation = gql`
  mutation updateOnePhaseComponent($id: Int!, $data: phaseComponentInput) {
    updateOnePhaseComponent(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

export const deletePhaseComponentMutation = gql`
  mutation deleteOnePhaseComponent($id: Int!) {
    deleteOnePhaseComponent(id: $id) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const getOneComponentQuery = gql`
  query getComponent($id: Int!) {
    getOneComponent(id: $id) {
      id
      name
      isActive
    }
  }
`
export const createComponentMutation = gql`
  mutation createOneComponent($data: componentInput) {
    createOneComponent(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
export const updateComponentMutation = gql`
  mutation updateOneComponent($id: Int!, $data: componentInput) {
    updateOneComponent(id: $id, data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`

export const UpdateManyMutation = gql`
  mutation updateManyComponents($data: [componentInput]) {
    updateManyComponents(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`
